import { customFetch } from "../helpers/customFetch";
import { URL, getConfig } from "../helpers/helpers";

export const questionaryCreate = async (values) => {
    try {
        const configs = getConfig('POST', values);
        const response = await customFetch(`${URL}questionaries/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const questionnairesGet = async (name, active) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}questionaries/?name=${name}&active=${active}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const questionaryGet = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}questionaries/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const questionaryUpdate = async (id, values) => {
    try {
        const configs = getConfig('PUT', values);
        const response = await customFetch(`${URL}questionaries/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const questionaryDelete = async (id) => {
    try {
        const configs = getConfig('DELETE');
        const response = await customFetch(`${URL}questionaries/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const questionaryVersionsGet = async (code) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}questionaries/version/${code}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
