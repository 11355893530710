import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap'
import { isIntegrated } from '../../helpers/helpers';
import { DeleteModal } from '../DeleteModal/DeleteModal';
import { RevokeOrGrantModal } from '../RevokeOrGrantModal/RevokeOrGrantModal';
import { InducitonList } from './InducitonList';
import { InductionIntegratedList } from './InductionIntegratedList';

export const InductionTable = ({ inductions, getInductions }) => {

  const { t } = useTranslation();

  const [modalDelete, setModalDelete] = useState(false);

  const [modalRovokeOrGrant, setModalRovokeOrGrant] = useState(false)

  const [id, setId] = useState(null);

  const [active, setActive] = useState(null);

  const handleModal = (id, values) => {
    setId(id);
    if (values === undefined) {
      setModalDelete(true)
    } else {
      setModalRovokeOrGrant(true);
      setActive(values);
    }
  }

  return (
    <Fragment>

      {modalDelete ?
        <DeleteModal
          modal={modalDelete}
          setModal={setModalDelete}
          id={id}
          getCallback={getInductions}
        /> : null
      }

      {modalRovokeOrGrant ?
        <RevokeOrGrantModal
          modal={modalRovokeOrGrant}
          setModal={setModalRovokeOrGrant}
          id={id}
          values={active}
          getCallback={getInductions}
        />
        : null
      }
      <div style={{ overflowX: 'auto' }}>
        <Table hover>
          <thead style={{ background: '#e0e3e6' }}>
            <tr>
              {isIntegrated() ?
                <>
                  <th>
                    {t('inductions.induction_name')}
                  </th>
                  <th>
                    {t('questionary.questionary')}
                  </th>
                  <th>
                    {t('common.active')}
                  </th>
                  <th>
                  </th>
                </>
                :
                <>
                  <th colSpan={2}>
                    {t('inductions.since')}
                  </th>
                  <th>
                    {t('inductions.until')}
                  </th>
                  <th>
                    {t('common.name')}
                  </th>
                  <th>
                    {t('common.status')}
                  </th>
                  <th>
                  </th>
                </>
              }
            </tr>
          </thead>
          <tbody>
            {
              inductions.map((induction) => (
                isIntegrated() ?
                  <InductionIntegratedList key={induction._id} induction={induction} handleModal={handleModal} />
                  :
                  <InducitonList key={induction._id} induction={induction} handleModal={handleModal} />
              ))
            }
          </tbody>
        </Table>
      </div>
    </Fragment>

  )
}
