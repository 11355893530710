import { customFetch } from "../helpers/customFetch";
import { URL, getConfig } from "../helpers/helpers";

export const visitCreate = async (values) => {
    try {
        const configs = getConfig('POST', values);
        const response = await customFetch(`${URL}visits/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const visitsGet = async (page, first_name, last_name, document_number, status) => {

    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visits/?page=${page}&first_name=${first_name}&last_name=${last_name}&document_number=${document_number}&status=${status}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const visitDelete = async (id) => {
    try {
        const configs = getConfig('DELETE');
        const response = await customFetch(`${URL}visits/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const visitGet = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visits/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const visitUpdate = async (id, values) => {
    try {
        const configs = getConfig('PUT', values);
        const response = await customFetch(`${URL}visits/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const visitFromVisitUpdate = async (id, values) => {
    try {
        const configs = getConfig('PUT', values);
        const response = await customFetch(`${URL}visits/updatevisit/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const sendMail = async (id) => {
    try {
        const configs = getConfig('POST');
        const response = await customFetch(`${URL}visits/sendmail/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const confirmVisualized = async (visitId, inductionId) => {
    try {
        const values = { visit: visitId, induction: inductionId }
        const configs = getConfig('POST', values);
        const response = await customFetch(`${URL}visualized_inductions`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const validInductionsGet = async (visit_id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visualizations_visit/${visit_id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const inductionsSend = async (values) => {
    try {
        const configs = getConfig('POST', values);
        const response = await customFetch(`${URL}visualizations_visit/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionRevoke = async (visit) => {
    try {
        const configs = getConfig('POST', visit);
        const response = await customFetch(`${URL}visualizations_visit/revoke`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionGrant = async (visit) => {
    try {
        const configs = getConfig('POST', visit);
        const response = await customFetch(`${URL}visualizations_visit/grant`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}