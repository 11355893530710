import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Spinner } from 'reactstrap'
import { ContractorDetailFilter } from '../../components/Contractor/ContractorDetailFilter'
import { ContractorDetailTable } from '../../components/Contractor/ContractorDetailTable'
import { CustomAlert } from '../../components/CustomAlert/CustomAlert'
import { LocalPaginationCustom } from '../../components/LocalPaginationCustom/LocalPaginationCustom'
import { ContractorContext } from '../../context/Contractor/ContractorContext';
import { EmployeeContext } from '../../context/Employee/EmployeeContext'
import { getCurrentItems } from '../../helpers/helpers'

export const ContractorDetail = () => {

    const { contractorState, getSummary, summaryExport, getTotal } = useContext(ContractorContext);

    const { contractors, error, isFetching, message, visualizationId } = contractorState;

    const { cleanEmployees } = useContext(EmployeeContext);

    const { t } = useTranslation();

    const { id } = useParams();

    const initialStateFilter = {
        contractor_name: '',
        contractor_code: ''
    }

    const initialStateHeaders = [
        { key: 'contractor_name', label: t('contractor.contractor_name'), order: 'asc', active: true },
        { key: 'questionary', label: t('questionary.questionary'), order: 'asc', active: false },
        { key: 'assigned_employees', label: t('contractor.assigned_employees'), order: 'asc', active: false },
        { key: 'employees_viewed', label: t('contractor.assigned_employees_view'), order: 'asc', active: false },
        { key: 'employees_approved', label: t('contractor.assigned_employees_passed'), order: 'asc', active: false },
    ]

    const [stateContractors, setStateContractors] = useState([]);

    const [filter, setFilter] = useState(initialStateFilter);

    const [page, setPage] = useState(1)

    const [errorFilter, setErrorFilter] = useState(false);

    const [isDownloading, setisDownloading] = useState(false);

    const [headers, setHeaders] = useState(initialStateHeaders)

    const navigate = useNavigate();

    const currentContrators = getCurrentItems(stateContractors, page)

    const { contractor_name, contractor_code } = filter;

    const search = () => {
        const filterQuery = contractors.filter((contractor) => contractor.contractor_name.toLocaleLowerCase().includes(contractor_name.toLocaleLowerCase()) && contractor.contractor_code.includes(contractor_code))
        setPage(1)
        setStateContractors(filterQuery)
        setErrorFilter(true);
    }

    const clean = () => {
        setPage(1)
        setFilter(initialStateFilter);
        setHeaders(initialStateHeaders);
        setStateContractors(contractors);
        setErrorFilter(false);
    }

    const orderBy = (order) => {
        const filterQuery = [...stateContractors].sort(order)
        setStateContractors(filterQuery);
    }

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilter({
            ...filter,
            [name]: value
        })
    }

    const handleExport = () => {
        setisDownloading(true);
        summaryExport(id, setisDownloading);
    }

    useEffect(() => {
        getSummary(id)
        getTotal(id);
        cleanEmployees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        setStateContractors(contractors.sort((a, b) => a.contractor_name.localeCompare(b.contractor_name)));
    }, [contractors])

    return (
        <div>
            <div>
                <h4>{t('contractor.contractor_detail')}</h4>
                <ContractorDetailFilter
                    handleChange={handleChangeFilter}
                    search={search}
                    clean={clean}
                    filter={filter}
                />
            </div>
            <hr className='bg-dark' />
            {
                isFetching ?
                    <div className='d-flex justify-content-center'>
                        <Spinner size={'xxl'} />
                    </div>
                    :
                    stateContractors.length > 0 ?
                        <>
                            <ContractorDetailTable
                                contractors={currentContrators}
                                visualizationId={visualizationId}
                                orderBy={orderBy}
                                headers={headers}
                                setHeaders={setHeaders}
                            />
                            <LocalPaginationCustom page={page} totalItems={stateContractors.length} setPage={setPage} />
                        </>
                        :
                        stateContractors.length === 0 && errorFilter && !error ?
                            <div className='d-flex justify-content-center'>{t('common.error_filter')}</div>
                            :
                            stateContractors.length === 0 && !errorFilter && !error && !isFetching ?
                                <div className='d-flex justify-content-center'>{t('common.not_loaded')}</div>
                                :
                                null
            }

            {error ? <CustomAlert text={message} color={'danger'} /> : null}

            <div className='d-flex justify-content-center gap-4 mt-3'>
                <Button
                    color='primary'
                    onClick={handleExport}
                    disabled={isDownloading}
                >
                    {t('contractor.print_detail')}
                </Button>
                <Button
                    color="secondary"
                    style={{ minWidth: '139px' }}
                    onClick={() => navigate('/induction')}
                >
                    {t('common.came_back')}
                </Button>
            </div>
        </div>
    )
}
