import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap';
import BusinessTypeOpeningsContext from '../../context/BusinessTypeOpenings/BusinessTypeOpeningsContext';
import ContractsContext from '../../context/Contracts/ContractsContext';
import EmployeeClassificationContext from '../../context/EmployeeClassification/EmployeeClassificationContext';
import { getCustomNameByKey, getTenantByKey } from '../../helpers/helpers';

export const EmployeeFilter = ({ handleChangeFilter, search, clean, filter }) => {

    const { businessTypeOpeningsState, getBusinessTypeOpeningsByInduction } = useContext(BusinessTypeOpeningsContext);
    const { businessTypeOpenings } = businessTypeOpeningsState;

    const { employeeClassificationsState, getEmployeeClassificationsByInduction } = useContext(EmployeeClassificationContext)
    const { employeeClassifications } = employeeClassificationsState;

    const { contractsState, getContracts } = useContext(ContractsContext)
    const { contracts } = contractsState

    const { employee_name, employee_code, employee_classification_id, business_type_opening_code, contract_id } = filter;

    const { t } = useTranslation();

    const { id } = useParams();

    const onKeyPress = (e) => {
        if ((e.key === 'Enter')) {
            search();
        }
    };


    useEffect(() => {
        getBusinessTypeOpeningsByInduction(id);
        getEmployeeClassificationsByInduction(id);
        getContracts();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='row'>
            <div className='col'>
                <Label
                    htmlFor='employee_name'
                >
                    {t('common.firstName')}/{t('common.lastName')}
                </Label>
                <Input
                    type='text'
                    name='employee_name'
                    value={employee_name}
                    onChange={handleChangeFilter}
                    onKeyPress={onKeyPress}
                />
            </div>
            <div className='col'>
                <Label
                    htmlFor='employee_code'
                >
                    {getTenantByKey('contractor_doc')}
                </Label>
                <Input
                    type='text'
                    name='employee_code'
                    value={employee_code}
                    onChange={handleChangeFilter}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='col'>
                <Label
                    htmlFor='employee_classification_id'
                    className='text-nowrap'
                >
                    {getCustomNameByKey('employeeClassificationNames')}
                </Label>
                <select
                    className='form-select'
                    name='employee_classification_id'
                    value={employee_classification_id}
                    onChange={handleChangeFilter}
                >
                    <option value="">{t('common.all')}</option>
                    {employeeClassifications.map((employeeClassification) => (
                        <option
                            key={employeeClassification.id}
                            value={employeeClassification.id}
                        >
                            {employeeClassification.description}
                        </option>
                    ))}
                </select>
            </div>

            <div className='col'>
                <Label
                    htmlFor='business_type_opening_code'
                >
                    {getCustomNameByKey('businessTypeNames')}
                </Label>
                <select
                    className='form-select'
                    name='business_type_opening_code'
                    value={business_type_opening_code}
                    onChange={handleChangeFilter}

                >
                    <option value="">{t('common.all')}</option>
                    {businessTypeOpenings.map((businessTypeOpening) => (
                        <option
                            key={businessTypeOpening.code}
                            value={businessTypeOpening.code}
                        >
                            {businessTypeOpening.description}
                        </option>
                    ))}
                </select>
            </div>


            <div className='col'>
                <Label htmlFor='contract_id'>{t('employee.contract_name')}</Label>
                <select
                    name="contract_id"
                    onChange={handleChangeFilter}
                    className='form-select'
                    value={contract_id}

                >
                    <option value="">{t('common.all')}</option>
                    {
                        contracts.map((contract) => (
                            <option key={contract.id} value={contract.id}>{contract.description}</option>
                        ))
                    }
                </select>
            </div>

            <div className='col' style={{ marginTop: '32px' }}>
                <div className='d-flex align-items-end gap-3'>
                    <Button
                        onClick={clean}
                        className="btn-secondary"
                    >
                        <i className="fa-solid fa-eraser"></i>
                    </Button>
                    <Button
                        onClick={search}
                        className="btn-secondary"
                    >
                        <i className="fa-solid fa-magnifying-glass" ></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}
