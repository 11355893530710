import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Table } from 'reactstrap'
import InductionContext from '../../context/Induction/InductionContext';
import { InductionPreviewModal } from './InductionPreviewModal';
import { InductionsDownloadList } from './InductionsDownloadList';

export const InductionsDownloadTable = () => {

    const { getValidInducctions, inductionState, downloadInduction, visualized } = useContext(InductionContext);

    const { validInductions } = inductionState

    const [modal, setModal] = useState(false)

    const [previewInduction, setPreviewInduction] = useState();

    const { t } = useTranslation();

    const { id } = useParams();

    const handleCheck = (visitIinductionId) => {
        visualized(id, visitIinductionId)
    }

    const handleVisualized = (induction) => {
        setModal(true);
        setPreviewInduction(induction);
        if (induction.visualized === false) visualized(id, induction._id);
    }

    const handleDownloadAndVisualized = (induction, setIsDownloading) => {
        setIsDownloading(true);
        downloadInduction(induction.files[0].name, setIsDownloading);
        if (induction.visualized === false) visualized(id, induction._id);
    }

    useEffect(() => {
        getValidInducctions(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {modal ? <InductionPreviewModal modal={modal} setModal={setModal} induction={previewInduction} /> : null}
            <div className='table-inductions-container'>
                <Table hover>
                    <thead style={{ background: '#e0e3e6' }}>
                        <tr>
                            <th>
                                {t('common.name')}
                            </th>
                            <th>
                                {t('inductions.seen')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {validInductions.map((validInduction) => (
                            <InductionsDownloadList
                                key={validInduction._id}
                                validInduction={validInduction}
                                visualized={handleCheck}
                                handleVisualized={handleVisualized}
                                handleDownloadAndVisualized={handleDownloadAndVisualized}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}
