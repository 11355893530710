import { customFetch } from "../helpers/customFetch";
import { URL, getConfig } from "../helpers/helpers";

export const businessTypeOpeningsGet = async () => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}business_type_openings/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const businessTypeOpeningsGetByInduction = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}business_type_openings/induction/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}