import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, Input, Label, Spinner } from 'reactstrap'
import { getTenantByKey, getUserDataByKey } from '../../helpers/helpers';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import InductionContext from '../../context/Induction/InductionContext';
import ClassificationsContext from "../../context/Classifications/ClassificationsContext";
export const InductionForm = () => {

    const { createInduction, inductionState, getInduction, updateInduction } = useContext(InductionContext);

    const { getClassifications, classificationsState } = useContext(ClassificationsContext);

    const { alert, error, sending, message, inductionSelected } = inductionState

    const { classifications } = classificationsState;

    const { id } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate()

    const userId = getUserDataByKey('_id');

    const isEditForm = !!id;

    const maxSizeFile = getTenantByKey('max_file_size');

    const maxSizeFileLabel = maxSizeFile / 1048576;

    const classificationsSingularName = getTenantByKey('singular_classification_name');

    const initialValuesInductions = {
        user_created: '',
        name: '',
        description: '',
        date_from: '',
        date_to: '',
        classification: '',
        files: [],
        active: true,
    }

    const [induction, setInduction] = useState(initialValuesInductions)

    const sizeError = induction.files ? induction.files.size >= maxSizeFile : false;

    const currentDate = new Date().toJSON().slice(0, 10);

    const fromError = induction.date_from ? induction.date_from < currentDate : false;

    const toError = induction.date_to ? induction.date_to <= induction.date_from : false;

    const toEditError = induction.date_to ? induction.date_to < currentDate : false;

    const datesError = induction.status === 'F' ? fromError || toError : toEditError

    const fileOrdescriptionError = induction.files.length === 0 ? !!!induction.description.trim() : false;

    const handleInduction = (e) => {
        const value = e.target.type === 'file' ? e.target.value === '' ? e.target.value = [] : e.target.files[0] : e.target.value;
        setInduction({
            ...induction,
            [e.target.name]: value,
        });
    };

    const disabled = () => {
        if (isEditForm) {
            return fileOrdescriptionError || !!!induction.name.trim() || datesError;
        } else {
            return (induction.files.length === 0 && !!!induction.description.trim()) || !!!induction.date_from || !!!induction.name.trim() || !!sizeError || fromError || toError;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEditForm) {
            updateInduction(id, induction);
        } else {
            induction.user_created = userId;
            createInduction(induction);
        }
    }

    useEffect(() => {
        if (isEditForm) getInduction(id);
        getClassifications(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (inductionSelected) setInduction(inductionSelected)
    }, [inductionSelected])


    return (
        <Container>
            <Form className='d-flex mt-5 gap-5 bg-white pb-5 pt-5 rounded-3 c-wrap' encType="multipart/form-data" onSubmit={handleSubmit}>
                <Container>
                    <h3 className='text-center'>{isEditForm ? t('inductions.edit') : t('inductions.register')}</h3>
                    {alert ? <CustomAlert text={message} color={'success'} /> : null}
                    {error ? <CustomAlert text={message} color={'danger'} /> : null}
                    {classificationsState.error && !error ? <CustomAlert text={classificationsState.message} color={'danger'} /> : null}
                    {sending && !!!alert && !!!error ?
                        <div className='d-flex flex-column align-items-center'>
                            <Spinner size={'xxl'} />
                            <p>{t('inductions.sending')}</p>
                        </div>
                        : null
                    }
                    <hr />

                    <div className="row g-3">
                        <div className="col">
                            <Label htmlFor='date_from' className='fw-bold'>* {t('inductions.since')}</Label>
                            {
                                induction.status !== 'F' && isEditForm ?
                                    <Input type='date' readOnly={true} value={induction.date_from.slice(0, 10)} name='date_from' onChange={handleInduction} />
                                    :
                                    <>
                                        <Input type='date' value={induction.date_from.slice(0, 10)} name='date_from' onChange={handleInduction} />
                                        {fromError ? <div className='alert-danger mt-2'>{t('inductions.date_error.from_error')}</div> : null}
                                    </>

                            }
                        </div>
                        <div className="col">
                            <Label htmlFor='date_to' >{t('inductions.until')}</Label>
                            <Input type='date' value={induction.date_to ? induction.date_to.slice(0, 10) : ''} name='date_to' onChange={handleInduction} />
                            {toError ? <div className='alert-danger mt-2'>{t('inductions.date_error.to_error')}</div> : null}
                            {toEditError && isEditForm && induction.status !== 'F' && !toError ? <div className='alert-danger mt-2'>{t('inductions.date_error.from_error')}</div> : null}
                        </div>
                    </div>
                    <Label htmlFor='name' className='fw-bold'>* {t('common.name')}</Label>
                    <Input type='text' value={induction.name} name='name' onChange={handleInduction} />

                    {
                        !isEditForm ?
                            <>
                                {
                                    !!!induction.description ?
                                        <Label htmlFor='files' className='fw-bold'>* {t('inductions.video')}</Label>
                                        :
                                        <Label htmlFor='files'>{t('inductions.video')}</Label>

                                }
                                <Input type='file' name='files' onChange={handleInduction} />
                                {sizeError ? <div className='alert-danger'>{t('inductions.sizeError')} {maxSizeFileLabel}MB</div> : null}
                            </>
                            : null
                    }
                    {
                        !!!induction.files ?
                            <Label htmlFor='description' className='fw-bold'>* {t('common.description')}</Label>
                            :
                            <Label htmlFor='description'>{t('common.description')}</Label>
                    }
                    <textarea name="description" value={induction.description} className="form-control" onChange={handleInduction}></textarea>

                    <Label htmlFor='classification'>{classificationsSingularName}</Label>
                    <select className="form-select" name='classification' value={induction.classification && induction.classification._id ? induction.classification._id : induction.classification ? induction.classification : ''} onChange={handleInduction}>
                        <option value={''}>{t('common.choose')}</option>
                        {classifications.map((classification) => (
                            <option key={classification._id} value={classification._id}>{classification.description}</option>
                        ))}
                    </select>
                    <div className='mt-4'>
                        <p className='fw-bold'>* {t('common.requiredFields')}</p>
                    </div>
                    <div className='d-flex justify-content-center gap-5 flex-wrap'>
                        <Button className="btn-secondary" disabled={sending} onClick={() => navigate('/induction')}>{t('common.cancel')}</Button>
                        <Button className="btn-primary" disabled={sending ? sending : disabled()}>{t('common.save')}</Button>
                    </div>
                </Container>
            </Form >
        </Container >
    )
}
