import { Route, Routes } from "react-router-dom"
import { ClassificationsForm } from "../components/Classifications"
import { Classifications } from "../containers/Classifications/Classifications"

export const ClassificationsRoutes = () => {
    return (
        <Routes>

            <Route
                path="/"
                element={<Classifications />}
            />

            <Route
                path="new"
                element={<ClassificationsForm />}
            />

            <Route
                path="edit/:id"
                element={<ClassificationsForm />}
            />

        </Routes>
    )
}
