import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Input, Label } from 'reactstrap'

export const QuestionaryFilter = ({ clean, search, handleChange, filterValues }) => {
    const { name, active } = filterValues;
    const { t } = useTranslation();

    const onKeyPress = (e) => {
        if ((e.key === 'Enter')) {
            search();
        }
    };

    return (
        <div className='d-flex gap-5 flex-wrap'>
            <div style={{ width: '400px' }}>
                <Label htmlFor='name'>{t('common.name')}</Label>
                <Input
                    type='text'
                    value={name}
                    name='name'
                    onChange={handleChange}
                    onKeyPress={onKeyPress}
                />
            </div>
            <div>
                <Label htmlFor='active'>{t('common.active')}</Label>
                <select
                    className='form-select'
                    name="active"
                    value={active}
                    onChange={handleChange}
                >
                    <option
                        value={''}
                    >
                        {t('common.all')}
                    </option>
                    <option
                        value={true}
                    >
                        {t('common.yes')}
                    </option>
                    <option
                        value={false}
                    >
                        {t('common.no')}
                    </option>
                </select>
            </div>

            <div className='d-flex align-items-end gap-3 pt-4 flex-wrap'>
                <Button
                    onClick={clean}
                    className="btn-secondary"
                >
                    <i className="fa-solid fa-eraser"></i>
                </Button>
                <Button
                    onClick={search}
                    className="btn-secondary"
                >
                    <i className="fa-solid fa-magnifying-glass" ></i>
                </Button>
            </div>
        </div>
    )
}
