import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip'

export const ContractorDetailList = ({ contractor }) => {
    const { t } = useTranslation();
    const { id } = useParams();

    return (
        <tr className='text-center'>
            <td>{contractor.contractor_name} <small>({contractor.contractor_code})</small></td>
            <td>{contractor.questionary ? <span>{contractor.questionary.name} <small>({`v${contractor.questionary.version}.0`})</small></span> : '-'}</td>
            <td>{contractor.assigned_employees}</td>
            <td>{contractor.employees_viewed}</td>
            <td>{contractor.employees_approved}</td>
            <td>
                <CustomTooltip
                    placement={"top"}
                    target={"detail"}
                    text={t('common.view_detail')}
                />
                <Link
                    style={{ color: 'black', width: '14px' }}
                    to={`/induction/details/employee/${id}/${contractor.contractor_id}`}
                >
                    <i
                        className="fa-solid fa-circle-info"
                        id='detail'
                    >
                    </i>
                </Link>
            </td>
        </tr>
    )
}
