import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Badge, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ArrowPagination } from '../ArrowPagination/ArrowPagination';

export const ExamModal = ({ setModal, modal, questionary }) => {

    const [page, setPage] = useState(0);

    const { t } = useTranslation();

    const handleClose = () => {
        setModal(!modal)
    }

    return (
        <Modal isOpen={modal} toggle={handleClose} size={'xl'}>
            <ModalHeader toggle={handleClose}>
                {questionary.name} <small>(<small>v</small>{questionary.version}.0)</small>
            </ModalHeader>
            <ModalBody>
                <ArrowPagination pageNumber={page} totalPages={(questionary.questions.length - 1)} setPageNumber={setPage} limit={0} >
                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <div>
                                    <Label className='fw-bold'>{questionary.questions[page].question}</Label>
                                </div>
                                <div className='fs-4'>
                                    <Badge color={'success'}>{questionary.questions[page].score}</Badge>
                                </div>
                            </div>
                        </div>
                        <ul className="list-group list-group-flush">
                            {
                                questionary.questions[page].answers.map((answer) => (
                                    <li
                                        key={answer._id}
                                        className=
                                        {
                                            answer.correct_answer && answer.user_answer ? 'list-group-item d-flex justify-content-between bg-success text-light' :
                                                !answer.correct_answer && answer.user_answer ? 'list-group-item d-flex justify-content-between bg-danger text-light' : 'list-group-item d-flex justify-content-between'
                                        }
                                    >
                                        {answer.answer}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </ArrowPagination>
                <div className='mt-3'>
                    {questionary.employee ? <p>{questionary.employee.employee_name} <small>({questionary.employee.employee_code})</small></p> : null}
                    {questionary.visit ? <p>{questionary.visit.first_name} {questionary.visit.last_name}</p> : null}
                    <p>{t('questionary.exam_pass_note')}: {questionary.note}/{questionary.max_score}</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleClose}>{t('common.close')}</Button>
            </ModalFooter>
        </Modal >
    )
}
