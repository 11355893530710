import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Table } from 'reactstrap';
import { getCustomNameByKey, getUserDataByKey } from '../../helpers/helpers';
import { RevokeOrGrantModal } from '../RevokeOrGrantModal/RevokeOrGrantModal';
import { EmployeeList } from './EmployeeList';

export const EmployeeTable = ({ employees, setSelectedEmployees, selectedEmployees, getCallback, visualizationId }) => {
    const { t } = useTranslation();

    const currentDate = new Date();

    const [modal, setModal] = useState(false);

    const [values, setValues] = useState(null);

    const [employeeId, setEmployeeId] = useState(null);

    const handleModal = (values, employeeId) => {
        setModal(true);
        setEmployeeId(employeeId)
        setValues(values)
    }

    const handleEmployeeSelected = (e, employee) => {
        const { checked } = e.target;
        if (checked) {
            setSelectedEmployees([...selectedEmployees, employee])
        } else {
            setSelectedEmployees(selectedEmployees.filter((selectedEmployee) => selectedEmployee.employee_id !== employee.employee_id))
        }
    }

    const isAdded = (id) => {
        const isAlReadyAdded = selectedEmployees.some((selectedEmployee) => selectedEmployee.employee_id === id);
        return isAlReadyAdded;
    }

    const canAdd = (employee) => {

        if (employee.date_to) {

            return +currentDate > +new Date(employee.date_to)
        }

        if (!employee.date_to && employee.assignment_date) {
            return employee.approved === false && employee.active === true
        }

        return employee.approved === false

    };

    const selectAll = (e) => {
        const { checked } = e.target;
        let newData = [...selectedEmployees];
        if (checked) {
            employees.forEach(employee => {
                const wasAdded = selectedEmployees.some((selectedEmployee) => selectedEmployee.employee_id === employee.employee_id);
                const canAddAgain = canAdd(employee);
                if (wasAdded === false && canAddAgain === true) {
                    newData.push(employee);
                }
            });
        } else {
            newData = selectedEmployees.filter((selectedEmployee) => !employees.some((employee) => employee.employee_id === selectedEmployee.employee_id));
        }
        setSelectedEmployees(newData);
    }

    const isAllAdded = () => {
        const checkEmployees = employees.filter((employee) => canAdd(employee))
        const filter = selectedEmployees.filter((selectedEmployee) => employees.some((employee) => employee.employee_id === selectedEmployee.employee_id))
        return checkEmployees.length === filter.length
    }

    const allAsigned = employees.every((employee) => canAdd(employee))

    return (
        <div>
            {
                modal ?
                    <RevokeOrGrantModal
                        setModal={setModal}
                        modal={modal}
                        id={visualizationId}
                        values={values}
                        getCallback={getCallback}
                        contractorId={getUserDataByKey('_id')}
                        employeeId={employeeId}
                    />
                    :
                    null
            }
            <div style={{ overflowX: 'auto' }}>
                <Table hover>
                    <thead style={{ background: '#e0e3e6' }}>
                        <tr>
                            <th>
                                {
                                    allAsigned ?
                                        null :
                                        <Input
                                            type='checkbox'
                                            onChange={selectAll}
                                            checked={isAllAdded()}
                                        />
                                }
                            </th>
                            <th>
                                {t('employee.employee_name')}
                            </th>
                            <th>
                                {getCustomNameByKey('employeeClassificationNames')}
                            </th>
                            <th>
                                {getCustomNameByKey('businessTypeNames')}
                            </th>
                            <th>
                                {t('employee.contract_name')}
                            </th>
                            <th>
                                {t('employee.send')}
                            </th>
                            <th>
                                {t('inductions.seen')}
                            </th>
                            <th>
                                {t('employee.exam_passed')}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map((employee) => (
                            <EmployeeList
                                key={employee.employee_id}
                                employee={employee}
                                handleEmployeeSelected={handleEmployeeSelected}
                                isAdded={isAdded}
                                handleModal={handleModal}
                                canAdd={canAdd(employee)}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}