import React, { useReducer } from 'react'
import { documentsGet } from '../../services/documentsService'
import DocumentContext from './DocumentContext'
import DocumentReducer from './DocumentReducer'
import { logout } from '../../services/loginService';

export const DocumentState = ({ children }) => {
    const initialState = {
        documents: [],
        isFetching: true,
        error: false,
        message: '',
        tokenError:false
    }

    const [state, dispatch] = useReducer(DocumentReducer, initialState);

    const getDocuments = async () => {
        try {
            const response = await documentsGet();
            if (response.ok) {
                dispatch({
                    type: 'GET_DOCUMENTS',
                    payload: response.data.response,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_DOCUMENTS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_DOCUMENTS',
            })
            console.log(e)
        }
    }

    return (
        <DocumentContext.Provider
            value={{
                documentState: state,
                getDocuments
            }}
        >
            {children}
        </DocumentContext.Provider>
    )
}
