import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import InductionContext from '../../context/Induction/InductionContext';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import { CustomPDFViewer } from '../CustomPDFViewer/CustomPDFViewer';
import { CustomPlayer } from '../CustomPlayer/CustomPlayer'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { DownloadInduction } from '../DownloadInduction/DownloadInduction';
import { FullScreenPDFPreview } from '../FullScreenPDFPreview/FullScreenPDFPreview';

export const File = ({ induction, haveQuestionary, videoPercentage, setWasSeen }) => {

    const { previewInduction, /*previewInductionDeleteURL,*/ inductionState } = useContext(InductionContext);
    const { url, error, message } = inductionState;

    const [modal, setModal] = useState(false);
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1);

    const { t } = useTranslation();

    const optionsRender = {
        'application/pdf': (
            <div>
                {
                    modal ?
                        <FullScreenPDFPreview
                            url={url}
                            modal={modal}
                            setModal={setModal}
                            viewer={<CustomPDFViewer url={url} numPages={numPages} setNumPages={setNumPages} pageNumber={pageNumber} setPageNumber={setPageNumber} />}
                        />
                        :
                        null
                }
                <div className='w-75 mx-auto' style={haveQuestionary ? { height: '528.47px' } : null}>
                    <CustomPDFViewer
                        url={url}
                        numPages={numPages}
                        setNumPages={setNumPages}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                </div>
                <div className='d-flex justify-content-between gap-3 align-items-baseline mt-5'>
                    <p>{t('inductions.n_page')} {pageNumber} {t('inductions.n_of')} {numPages}</p>
                    <div className=' d-flex justify-content-center gap-3 align-items-center'>
                        <div className='fs-4'>
                            {induction.allow_download ? <DownloadInduction inductionName={induction.files[0].name} /> : null}
                        </div>
                        <CustomTooltip
                            placement={"top"}
                            target={'full_screen'}
                            text={t('questionary.full_screen')}
                        />
                        <i
                            id='full_screen'
                            className="fa-solid fa-expand fs-4"
                            type='button'
                            onClick={() => setModal(true)}
                        >
                        </i>
                    </div>
                </div>
            </div>
        ),
        'video/mp4': (
            <div>
                <CustomPlayer
                    url={url}
                    videoPercentage={videoPercentage}
                    setWasSeen={setWasSeen}
                />
                {
                    induction.allow_download ?
                        <div>
                            <DownloadInduction inductionName={induction.files[0].name} />
                        </div>
                        :
                        null
                }
            </div>
        )
    }

    useEffect(() => {
        previewInduction(induction.files[0].name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pageNumber === numPages) {
            setWasSeen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, numPages])


    return (
        <>
            <div>
                <h4>{induction.name}</h4>
                <p>{induction.description ? induction.description : '-'}</p>
            </div>
            {error ? <CustomAlert color={'danger'} text={message} /> : null}
            {optionsRender[induction.files[0].type]}
        </>
    )
}
