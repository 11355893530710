import React, { Fragment, useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate,  useParams } from 'react-router-dom'
import { Button, Container, Spinner } from 'reactstrap'
import { CustomAlert } from '../../components/CustomAlert/CustomAlert'
import { CustomTooltip } from '../../components/CustomTooltip/CustomTooltip'
import { File } from '../../components/Exam/File'
import { Questionary } from '../../components/Exam/Questionary'
import ExamContext from '../../context/Exam/ExamContext'
import TenantContext from '../../context/Tenant/TenantContext'
import { getTenantByKey } from '../../helpers/helpers'

export const Exam = () => {

    const { t } = useTranslation();

    const { id, employeeId, visitId } = useParams();

    const { getTenant, tenant } = useContext(TenantContext);

    const { examState, getExam, getExamForVisit, seenInduction, seenInductionForVisit } = useContext(ExamContext);
    const { exam } = examState;

    const [questionaryAnswers, setQuestionaryAnswers] = useState([]);

    const [videoPercentage, setVideoPercentage] = useState(0);

    const [wasSeen, setWasSeen] = useState(false);

    const inductionValues = employeeId ?
        {
            contractor: exam ? exam.contractor_id : null,
            employee: employeeId,
        } :
        {
            visit: visitId,
            induction: id
        }

    const questionaryValues = employeeId ?
        {
            contractor: exam ? exam.contractor_id : null,
            employee: employeeId,
            questionary: questionaryAnswers
        } :
        {
            visit: visitId,
            induction: id,
            questionary: questionaryAnswers
        }

    const tooltipOptions = {
        'application/pdf': t('questionary.exam_disabled_pdf'),
        'video/mp4': `${t('questionary.exam_disabled_video_part_one')} ${videoPercentage}% ${t('questionary.exam_disabled_video_part_two')}`
    }

    const setCorrect = (questionId, answerId) => {
        const newData = questionaryAnswers.map((question) => (question.question === questionId) ? { ...question, answer: answerId } : question);
        setQuestionaryAnswers(newData);
    }

    const disabled = () => {
        const allAnswering = questionaryAnswers.every((questionaryAnswer) => questionaryAnswer.answer);
        if (exam && exam.questionary) {
            return !(wasSeen && allAnswering)
        } else {
            return !(wasSeen)
        }
    }

    const sendExam = () => {
        if (exam.questionary) {
            employeeId ? seenInduction(inductionValues, exam.visualization_id, questionaryValues) : seenInductionForVisit(inductionValues, questionaryValues)
        } else {
            employeeId ? seenInduction(inductionValues, exam.visualization_id) : seenInductionForVisit(inductionValues)
        }
    }

    useEffect(() => {
        getTenant();
        if (employeeId) {
            getExam(id, employeeId);
        }
        if (visitId) {
            getExamForVisit(id, visitId)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (exam && exam.questionary) {
            setQuestionaryAnswers(exam.questionary.questions.map((question) => ({ question: question._id, answer: '' })))
        }
        if (exam && exam.induction.files[0].type === 'video/mp4') {
            setVideoPercentage(exam.induction.video_percentage)
        }
    }, [exam])

    if (tenant.error) {
        return <Navigate to={'/login'} replace />
    }

    if (tenant.isFetching || examState.isFetching) {
        return (
            <div className='position-absolute top-50 start-50'>
                <Spinner size={'xl'} />
            </div>
        )
    }

    if (exam && (exam.viewed === true && exam.approved === true)) {
        return <Navigate to={employeeId ? `/exam/result/${id}/employee/${employeeId}` : `/exam/result/${id}/visit/${visitId}`} replace />
    }

    return (
        <Fragment>
            <Container className="bg-white pb-5 pt-5 mt-5 rounded-3" fluid="md">
                {
                    exam ?
                        <>
                            <div className='row'>
                                <div className='d-flex aling-items-center justify-content-between flex-wrap mb-3'>
                                    {
                                        employeeId ?
                                            <h4>{exam.contractor_name} <small>({exam.contractor_code})</small></h4>
                                            :
                                            <h4>{exam.visit.first_name}, {exam.visit.last_name}</h4>
                                    }
                                    <img src={getTenantByKey('logo')} alt="" />
                                </div>
                                {/* <h4>{exam.employee_name} <small>({exam.employee_code})</small></h4> */}
                                <div className='col-lg'>
                                    <File induction={exam.induction} haveQuestionary={exam.questionary} videoPercentage={videoPercentage} setWasSeen={setWasSeen} />
                                </div>

                                {
                                    exam.questionary ?
                                        <div className='col-lg'>
                                            <Questionary
                                                questionary={exam.questionary}
                                                setCorrect={setCorrect}
                                                questionaryAnswers={questionaryAnswers}
                                                finishButton={
                                                    <>
                                                        {
                                                            disabled() ?
                                                                <CustomTooltip
                                                                    placement={"top"}
                                                                    target={'send'}
                                                                    text={`${tooltipOptions[exam.induction.files[0].type]}${t('questionary.exam_disabled_questionary')}`}
                                                                />
                                                                :
                                                                null
                                                        }
                                                        <div id='send'>
                                                            <Button disabled={disabled()} onClick={sendExam} color='primary'>{t('common.end')}</Button>
                                                        </div>
                                                    </>
                                                }
                                                cancelButton={
                                                    <Button
                                                        onClick={() => setQuestionaryAnswers(exam.questionary.questions.map((question) => ({ question: question._id, answer: '' })))}
                                                    >
                                                        {t('common.cancel')}
                                                    </Button>
                                                }
                                            />
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            {
                                !exam.questionary ?
                                    <div className='mt-5'>
                                        {
                                            disabled() ?
                                                <CustomTooltip
                                                    placement={"top"}
                                                    target={'send'}
                                                    text={`${tooltipOptions[exam.induction.files[0].type]}`}
                                                />
                                                :
                                                null
                                        }
                                        <div className={'d-flex gap-4 justify-content-center flex-wrap'}>
                                            <div id='send'>
                                                <Button disabled={disabled()} onClick={sendExam} color='primary'>{t('common.end')}</Button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </>
                        :
                        null
                }
                {examState.error ? <CustomAlert color={'danger'} text={examState.message} /> : null}
            </Container>
        </Fragment>
    )
}

