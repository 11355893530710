import React, { useReducer } from 'react'
import { businessTypesGet, businessTypesGetByInduction } from '../../services/businessTypesService';
import { logout } from '../../services/loginService';
import BusinessTypesContext from './BusinessTypesContext';
import BusinessTypesReducer from './BusinessTypesReducer';

export const BusinessTypesState = ({ children }) => {
    const initialState = {
        businessTypes: [],
        isFetching: true,
        error: false,
        message: '',
        tokenError: false
    }

    const [state, dispatch] = useReducer(BusinessTypesReducer, initialState);

    const getBusinessTypes = async () => {
        try {
            const response = await businessTypesGet()
            if (response.ok) {
                dispatch({
                    type: 'GET_BUSINESS_TYPES',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_BUSINESS_TYPES',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_BUSINESS_TYPES',
            })
            console.log(e)
        }
    }

    const getBusinessTypesByIndcution = async (id) => {
        try {
            const response = await businessTypesGetByInduction(id)
            if (response.ok) {
                dispatch({
                    type: 'GET_BUSINESS_TYPES',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_BUSINESS_TYPES',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_BUSINESS_TYPES',
            })
            console.log(e)
        }
    }

    return (
        <BusinessTypesContext.Provider
            value={{
                businessTypesState: state,
                getBusinessTypes,
                getBusinessTypesByIndcution
            }}
        >
            {children}
        </BusinessTypesContext.Provider>
    )
}
