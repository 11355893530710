import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getUserDataByKey } from '../../helpers/helpers'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip'

export const InductionIntegratedList = ({ induction, handleModal }) => {

    const profile = getUserDataByKey('profile');

    const { t } = useTranslation();

    const profileRoutes = {
        '01': {
            send: `send/contractor/${induction._id}`,
            detail: `details/contractor/${induction._id}`
        },
        '02': {
            send: `send/contractor/${induction._id}`,
            detail: `details/contractor/${induction._id}`
        },
        '03': {
            send: `send/contractor/${induction._id}`,
            detail: `details/contractor/${induction._id}`
        },
        '04': {
            send: `send/employee/${induction._id}`,
            detail: `details/employee/${induction._id}/${getUserDataByKey('_id')}`
        }
    }

    return (
        <tr>
            <td>{induction.name}</td>
            <td>{induction.questionary ? <>{induction.questionary.name} (v{induction.questionary.version}.0)</> : '-'}</td>
            <td>{induction.active ? t('common.yes') : t('common.no')}</td>
            <td className={'d-flex gap-3 align-items-center flex-wrap'} style={{ minHeight: '42px' }}>
                {induction.active ?
                    <>
                        {
                            profile !== '03' ?
                                <>
                                    <CustomTooltip
                                        placement={"top"}
                                        target={`send-${induction._id}`}
                                        text={t('common.send_induction')}
                                    />

                                    <Link
                                        style={{ color: 'black', width: '14px' }}
                                        to={profileRoutes[profile].send}
                                    >
                                        <i
                                            className="fa-solid fa-paper-plane"
                                            type='button'
                                            id={`send-${induction._id}`}
                                        >
                                        </i>
                                    </Link>
                                </>
                                :
                                null
                        }
                        {profile === '01' ?
                            <>
                                <CustomTooltip
                                    placement={"top"}
                                    target={`status-${induction._id}`}
                                    text={t('common.expire_induction')}
                                />
                                <i
                                    className={"fa-solid fa-circle-xmark link-danger"}
                                    type='button'
                                    id={`status-${induction._id}`}
                                    onClick={() => handleModal(induction._id, !induction.active)}
                                >
                                </i>

                                <CustomTooltip
                                    placement={"top"}
                                    target={`edit-${induction._id}`}
                                    text={t('common.action_edit')}
                                />
                                <Link
                                    id={`edit-${induction._id}`}
                                    style={{ color: 'black', width: '14px' }}
                                    to={`edit/${induction._id}`}
                                >
                                    <i
                                        className="fa-solid fa-file-pen"
                                        id='edit'
                                    >
                                    </i>
                                </Link>
                            </>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
                <CustomTooltip
                    placement={"top"}
                    target={`detail-${induction._id}`}
                    text={t('common.view_detail')}
                />
                <Link
                    id={`detail-${induction._id}`}
                    style={{ color: 'black', width: '14px' }}
                    to={profileRoutes[profile].detail}
                >
                    <i
                        className="fa-solid fa-circle-info"
                        id='detail'
                    >
                    </i>
                </Link>
                {profile === '01' ?
                    <>
                        <CustomTooltip
                            placement={"top"}
                            target={`delete-${induction._id}`}
                            text={t('common.action_delete')}
                        />
                        <i
                            className="fa-solid fa-trash"
                            onClick={() => handleModal(induction._id)}
                            type='button'
                            id={`delete-${induction._id}`}
                        >
                        </i>
                    </>
                    :
                    null}
            </td>
        </tr>
    )
}

