import { Navigate, Route, Routes } from "react-router-dom"
import { VisitFormDownload } from "../components/Visits"

export const UniqueExamRoutes = () => {
    return (
        <Routes>

            <Route
                path=":id"
                element={<VisitFormDownload />}
            />

            <Route
                path="*"
                element={<Navigate to={'/login'} replace />}
            />

        </Routes>
    )
}
