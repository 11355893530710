import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap'
import VisitContext from '../../context/Visit/VisitContext';
import { isIntegrated } from '../../helpers/helpers';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import { DeleteModal } from '../DeleteModal/DeleteModal';
import { VisitIntegratedList } from './VisitIntegratedList';
import { VisitList } from './VisitList';

export const VisitTable = ({ visits, getVisists }) => {
    const { reSendMail, visitState } = useContext(VisitContext);
    const { alert } = visitState;
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(null);
    const handleModal = (id) => {
        setModal(true);
        setId(id);
    }
    return (
        <Fragment>
            <DeleteModal modal={modal} setModal={setModal} id={id} getCallback={getVisists} />
            {alert ? <CustomAlert text={t('visits.mail_send')} /> : null}
            <div style={{ overflowX: 'auto' }}>
                <Table hover>
                    <thead style={{ background: '#e0e3e6' }}>
                        <tr>
                            <th colSpan={isIntegrated() ? 1 : 2}>
                                {t('common.lastName')}, {t('common.name')}
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                {t('common.dni')}
                            </th>
                            <th>
                                {t('common.created')}
                            </th>
                            <th>
                                {t('common.status')}
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {visits.map((visit) => (
                            isIntegrated() ?
                                <VisitIntegratedList key={visit._id} visit={visit} handleModal={handleModal} />
                                :
                                <VisitList key={visit._id} visit={visit} handleModal={handleModal} reSendMail={reSendMail} />
                        ))}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    )
}
