import { customFetch } from "../helpers/customFetch";
import { URL, getConfig } from "../helpers/helpers";

export const classificationsCreate = async (values) => {
    try {
        const configs = getConfig('POST', values);
        const response = await customFetch(`${URL}classifications/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const classificationsGet = async (active) => {
    try {
        const classificationsURL = active ? `${URL}classifications/?active=${active}` : `${URL}classifications/`;
        const configs = getConfig('GET');
        const response = await customFetch(classificationsURL, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const classificationsDelete = async (id) => {
    try {
        const configs = getConfig('DELETE');
        const response = await customFetch(`${URL}classifications/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const classificationsUpdate = async (id, values) => {
    try {
        const configs = getConfig('PUT', values);
        const response = await customFetch(`${URL}classifications/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}
export const classificationGet = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}classifications/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}