const tenant = window.location.host.split('.')[0]

// export const URL = `https://api.exactian.solutions/api/${tenant}/`; //prod
// export const URL = `https://api.exactian.info/api/${tenant}/`; //test
export const URL = process.env.REACT_APP_API_URL.replace('tenant', tenant); //desa

export const URL_EXACTIAN = process.env.REACT_APP_API_EXACTIAN_URL.replace('tenant', tenant);

export const itemsPerPage = process.env.REACT_APP_LOCAL_ITEMS_PER_PAGE;

export const getCurrentItems = (itmes, page) => {

    const indexOfLastItem = page * itemsPerPage;

    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = itmes.slice(indexOfFirstItem, indexOfLastItem);

    return currentItems
}

export const setUserData = (user) => {
    localStorage.setItem('userData', JSON.stringify(user));
};

export const existsInLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    if (item !== null) {
        try {
            const parsedItem = JSON.parse(item);
            if (parsedItem !== undefined) {
                return true;
            }
        } catch (error) {
            console.log(error)
        }
    }
    return false;
}

export const getCustomNameByKey = (key) => {
    const customNamesExists = existsInLocalStorage("customNames");
    if (customNamesExists) {
        const customNames = JSON.parse(localStorage.getItem('customNames'));
        return customNames[key] ? customNames[key] : null
    }
    return null
}

export const getUserDataByKey = (key) => {
    const user = JSON.parse(localStorage.getItem('userData'));
    switch (key) {
        case '_id':
            return !!user ? user._id : null;
        case 'user_name':
            return !!user ? user.user_name : null;
        case 'first_name':
            return !!user ? user.first_name : null;
        case 'last_name':
            return !!user ? user.last_name : null;
        case 'email':
            return !!user ? user.email : null;
        case 'is_generic':
            return !!user ? user.is_generic : null;
        case 'profile':
            return !!user ? user.profile.code : null;
        case 'area':
            return !!user ? user.area : null;
        case 'rol':
            return !!user ? user.rol : null;
        case 'active':
            return !!user ? user.active : null;
        case 'token':
            return !!user ? user.token : null;
        default:
            return null;
    }
};

export const setTenant = (info) => {
    localStorage.setItem('tenant', JSON.stringify(info));
};

export const getTenantByKey = (key) => {
    const tenant = JSON.parse(localStorage.getItem('tenant'));
    return !!tenant ? tenant[key] : null;
};

export const isIntegrated = () => {
    return getTenantByKey('modality') === 'I';
};

export const isUnique = () => {
    return getTenantByKey('modality') === 'U';
};

export const setColor = (tenantTheme) => {
    const initialTheme = document.documentElement.style;
    tenantTheme.forEach(e => initialTheme.setProperty(Object.keys(e), Object.values(e)));
}

export const isAuthenticated = () => !!localStorage.getItem('userData');

export const cancel = (path) => {
    window.location.pathname = `/${path}`;
}

const getHeader = () => {
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'auth-token': `${getUserDataByKey('token')}`,
        'Authorization': `Basic ${btoa(`${process.env.REACT_APP_AUTH_USER}:${process.env.REACT_APP_AUTH_PASS}`)}`,
    };
};

const getHeaderExactian = () => {
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getUserDataByKey('token')}`,
    };
};

export const getConfig = (method, body, signal) => {
    return {
        headers: getHeader(),
        method: method,
        body: JSON.stringify(body),
        signal,
    };
};

export const getConfigExactian = (method, body, signal) => {
    return {
        headers: getHeaderExactian(),
        method: method,
        body: JSON.stringify(body),
        signal,
    };
};

export const reOrderArray = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};