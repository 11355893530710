import React from 'react'
import { Navigate } from 'react-router-dom';
import { getUserDataByKey, isUnique } from '../../helpers/helpers'

export const ProfileRoutes = ({ validRoles, children }) => {
    const profile = getUserDataByKey('profile');
    const checkValidity = validRoles.some((validRole) => validRole === profile);
    return (
        checkValidity ? children : <Navigate to={isUnique() ? "/visits" : "/induction"} replace />
    )
}
