import { Route, Routes } from "react-router-dom"
import { Users } from "../containers/Users/Users"
import { UsersForm } from "../components/Users"

export const UsersRoutes = () => {
    return (
        <Routes>

            <Route
                path="/"
                element={<Users />}
            />

            <Route
                path="new"
                element={<UsersForm />}
            />

            <Route
                path="edit/:id"
                element={<UsersForm />}
            />

        </Routes>
    )
}
