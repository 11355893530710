import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Label } from 'reactstrap'
import { getTenantByKey } from '../../helpers/helpers';


export const EmployeeDetailFilter = ({ handleChangeFilter, search, clean, filter }) => {
    const { t } = useTranslation();
    const { employee_name, employee_code, employee_viewed, employee_approved } = filter;
    const onKeyPress = (e) => {
        if ((e.key === 'Enter')) {
            search();
        }
    };
    return (
        <div className='d-flex gap-5 flex-wrap'>

            <div>
                <Label htmlFor='name'>{t('employee.employee_name')}</Label>
                <Input
                    type='text'
                    name='employee_name'
                    value={employee_name}
                    onChange={handleChangeFilter}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div>
                <Label htmlFor='employee_code'>{getTenantByKey('employee_doc')}</Label>
                <Input
                    type='text'
                    name='employee_code'
                    value={employee_code}
                    onChange={handleChangeFilter}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div>
                <Label htmlFor='viewed'>{t('inductions.seen')}</Label>
                <select
                    className='form-select'
                    name="employee_viewed"
                    value={employee_viewed}
                    onChange={handleChangeFilter}
                    onKeyPress={onKeyPress}
                >
                    <option value="">{t('common.all')}</option>
                    <option value="true">{t('common.yes')}</option>
                    <option value="false">{t('common.no')}</option>
                </select>
            </div>

            <div>
                <Label htmlFor='employee_approved'>{t('employee.exam_passed')}</Label>
                <select
                    className='form-select'
                    name="employee_approved"
                    value={employee_approved}
                    onChange={handleChangeFilter}
                    onKeyPress={onKeyPress}
                >
                    <option value="">{t('common.all')}</option>
                    <option value="true">{t('common.yes')}</option>
                    <option value="false">{t('common.no')}</option>
                </select>
            </div>


            <div className='d-flex align-items-end gap-3 pt-4 flex-wrap'>
                <Button
                    onClick={clean}
                    className="btn-secondary"
                >
                    <i className="fa-solid fa-eraser"></i>
                </Button>
                <Button
                    onClick={search}
                    className="btn-secondary"
                >
                    <i className="fa-solid fa-magnifying-glass" ></i>
                </Button>
            </div>
        </div>)
}
