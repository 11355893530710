import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap'
import InductionContext from '../../context/Induction/InductionContext';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

export const DownloadInduction = ({ inductionName }) => {

  const { downloadInduction } = useContext(InductionContext);

  const [isDownloading, setIsDownloading] = useState(false);

  const { t } = useTranslation();

  const handleDownload = () => {
    setIsDownloading(true);
    downloadInduction(inductionName, setIsDownloading)
  }

  return (
    <>
      <CustomTooltip
        placement={"top"}
        target={'download'}
        text={t('inductions.download_file')}
      />
      <i
        className="fa-solid fa-download"
        type='button'
        id='download'
        onClick={() => handleDownload()}
      >
      </i>
      {isDownloading ? <Spinner className='ms-1' size={'sm'} /> : null}
    </>
  )
}
