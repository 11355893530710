export const GET_USERS = 'GET_USERS';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_USER = 'GET_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const UNATORIZED = 'UNATORIZED'
export const ERROR_USERS = 'ERROR_USERS';
export const ERROR_PROFILES = 'ERROR_PROFILES';
export const ERROR_USER_NAME = 'ERROR_USER_NAME'
export const CLEAR_ALERTS = 'CLEAR_ALERTS'
export const CHANGE_PAGE = 'CHANGE_PAGE'