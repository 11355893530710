import React, { useReducer } from 'react'
import { getBusinessTypeName, getEmployeeClassificationName, login, loginByExactian, logout } from '../../services/loginService';
import LoginContext from "./LoginContext";
import LoginReducer from "./LoginReducer";
import { useLocation, useNavigate } from 'react-router-dom';
import { changePassword, forgotPassword } from '../../services/usersService';
import { isIntegrated, setUserData } from '../../helpers/helpers';
export const LoginState = ({ children }) => {
    const InitialState = {
        user: null,
        isFetching: true,
        error: false,
        message: '',
        passwordError: false,
        alert: false
    }

    const [state, dispatch] = useReducer(LoginReducer, InitialState)

    const navigate = useNavigate();

    const { state: stateLocation } = useLocation()

    const loginSession = async (user) => {
        try {

            const response = await login(user);

            if (response.ok) {

                setUserData(response.data);

                dispatch({
                    type: 'LOGIN',
                    payload: response.data
                })

                if (isIntegrated()) {

                    const namesBusinessTypeName = await getBusinessTypeName();

                    const namesEmployeeClassification = await getEmployeeClassificationName();

                    if (namesBusinessTypeName.ok && namesEmployeeClassification.ok) {

                        const customNames = {
                            businessTypeNames: namesBusinessTypeName.data.response.value,
                            employeeClassificationNames: namesEmployeeClassification.data.response.value,
                        };

                        localStorage.setItem('customNames', JSON.stringify(customNames));

                        navigate(stateLocation?.from || '/induction', { replace: true });

                        return

                    }

                    localStorage.removeItem('userData');

                    dispatch({
                        type: 'ERROR_SERVER',
                    })

                    return

                }

                navigate(stateLocation?.from || '/visits', { replace: true });

                return
            }

            switch (response.data.status) {
                case 401:
                    dispatch({
                        type: 'LOGIN_ERROR',
                    })
                    break;
                default:
                    dispatch({
                        type: 'ERROR_SERVER',
                    })
                    break;
            }

        } catch (e) {
            dispatch({
                type: 'ERROR_SERVER',
            })
            console.log(e);
        }
    }

    const passwordForgot = async (userName, toggle) => {
        try {
            const response = await forgotPassword(userName);
            if (response.ok) {
                dispatch({
                    type: 'FORGOT_PASSWORD',
                    payload: response.data
                })
                setTimeout(() => {
                    dispatch({
                        type: 'CLEAR_ALERTS'
                    })
                    toggle();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 404:
                        dispatch({
                            type: 'FORGOT_PASSWORD_ERROR',
                        })
                        break;
                    case 500:
                        dispatch({
                            type: 'ERROR_SERVER',
                        })
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_SERVER',
                        })
                        break
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_SERVER',
            })
            console.log(e);
        }
    }

    const passwordChange = async (id, password, toggle) => {
        try {
            const response = await changePassword(id, password);
            if (response.ok) {
                dispatch({
                    type: 'CHANGE_PASSWORD',
                    payload: response.data
                })
                setTimeout(() => {
                    dispatch({
                        type: 'CLEAR_ALERTS'
                    })
                    toggle();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED'
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_SERVER',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_SERVER',
            })
            console.log(e);
        }
    }

    const loginSessionExactian = async (token) => {
        try {

            const response = await loginByExactian(token);

            if (response.ok) {

                setUserData(response.data);

                dispatch({
                    type: 'LOGIN',
                    payload: response.data
                })

                if (isIntegrated()) {

                    const namesBusinessTypeName = await getBusinessTypeName();

                    const namesEmployeeClassification = await getEmployeeClassificationName();

                    if (namesBusinessTypeName.ok && namesEmployeeClassification.ok) {

                        const customNames = {
                            businessTypeNames: namesBusinessTypeName.data.response.value,
                            employeeClassificationNames: namesEmployeeClassification.data.response.value,
                        };

                        localStorage.setItem('customNames', JSON.stringify(customNames));

                        navigate('/induction');

                        return

                    }

                    localStorage.removeItem('userData');

                    navigate('/login')

                    return

                }

                navigate('/visits');

                return

            }

            navigate('/login');

            return

        } catch (e) {
            navigate('/login')
            console.log(e);
        }
    }
    return (
        <LoginContext.Provider
            value={{
                loginSession,
                loginState: state,
                passwordForgot,
                passwordChange,
                loginSessionExactian
            }}
        >
            {children}
        </LoginContext.Provider>
    )
}
