import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, UncontrolledDropdown } from 'reactstrap'
import QuestionaryContext from '../../context/Questionary/QuestionaryContext';
import { reOrderArray } from '../../helpers/helpers';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { QuestionCard } from './QuestionCard';

export const QuestionaryForm = () => {

    const { createQuestionary, getQuestionary, updateQuestionary, questionaryState } = useContext(QuestionaryContext);

    const { error, tokenError, message, alert, sending, questionarySelected } = questionaryState;

    const { id } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const isEditForm = !!id;

    const initlaValueQuestionary = {
        name: '',
        max_score: '',
        min_score: '',
        active: '',
        questions: [
            {
                question: '',
                score: '',
                answers: [
                    { answer: '', correct_answer: false },
                    { answer: '', correct_answer: false },
                    { answer: '', correct_answer: false }
                ]
            },
        ]
    }

    const [questionary, setQuestionary] = useState(initlaValueQuestionary);

    const [totalScoreError, setTotalScoreError] = useState(false);

    const totalScore = () => {
        var sum = 0;
        questionary.questions.forEach(question => {
            sum += parseInt(question.score)
        });
        return sum === questionary.max_score
    }

    const handleChange = (e, i, j) => {
        const value = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;

        const name = e.target.name;

        const newData = { ...questionary };

        if (i !== undefined && j === undefined) newData.questions[i][name] = value

        if (j !== undefined) newData.questions[i].answers[j][name] = value

        if (i === undefined && j === undefined) newData[name] = value

        setQuestionary(newData);
    }

    const addNewQuestion = (times) => {
        const copyData = { ...questionary }
        for (let i = 0; i < times; i++) {
            copyData.questions.push(
                {
                    question: '',
                    score: '',
                    answers: [
                        { answer: '', correct_answer: false },
                        { answer: '', correct_answer: false },
                        { answer: '', correct_answer: false }
                    ]
                },
            )
        }
        setQuestionary(copyData)
    }

    const deleteQuestion = (i) => {
        const newData = { ...questionary };
        newData.questions = newData.questions.filter((questions, index) => index !== i)
        setQuestionary(newData);
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (
            source.index === destination.index &&
            source.droppableId === destination.droppableId
        ) {
            return;
        }

        let newData = { ...questionary };
        const questionsNewOrder = reOrderArray(newData.questions, source.index, destination.index);
        newData.questions = questionsNewOrder
        setQuestionary(newData)
    }

    const addNewAnswer = (i) => {
        const newData = { ...questionary }
        newData.questions[i].answers.push({ answer: '', correct_answer: false })
        setQuestionary(newData);
    }

    const deleteAnswer = (i, j) => {
        const newData = { ...questionary };
        newData.questions[i].answers = newData.questions[i].answers.filter((answer, index) => index !== j)
        setQuestionary(newData);
    }

    const setCorrect = (i, j) => {
        const newData = { ...questionary };
        newData.questions[i].answers = newData.questions[i].answers.map((answer, index) => (
            index === j ? { ...answer, correct_answer: true } : { ...answer, correct_answer: false }
        ))
        setQuestionary(newData)
    }

    const disabled = () => {
        const questionsValues = questionary.questions.some((question) => !!!question.question.trim() || !!!question.score)
        const answersValues = questionary.questions.some((question) => question.answers.some((answer) => !!!answer.answer.trim()))
        const answersCorrects = questionary.questions.every((question) => question.answers.some((answer) => answer.correct_answer === true))
        const checkMinMax = questionary.min_score > questionary.max_score

        return !!!questionary.name.trim() || !!!questionary.max_score || !!!questionary.min_score.toString().trim() || !!!questionary.active || checkMinMax || answersValues || questionsValues || !!!answersCorrects;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const totalScoreCheck = totalScore()
        window.scroll(0, 0);
        if (totalScoreCheck) {
            setTotalScoreError(false);
            if (isEditForm) {
                updateQuestionary(id, questionary)
            } else {
                createQuestionary(questionary);
            }
        } else {
            setTotalScoreError(true);
        }
    }

    useEffect(() => {
        if (isEditForm) getQuestionary(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (questionarySelected) setQuestionary(questionarySelected)
    }, [questionarySelected])

    return (
        <Container className='bg-white mb-5 rounded-3'>
            <Form className='mt-5 pb-5 pt-5 mb-5 w-75 mx-auto' onSubmit={handleSubmit}>
                <h3 className='text-center'>{isEditForm ? t('questionary.edit') : t('questionary.register')} <small>(<small>v</small>{isEditForm ? `${questionary.version}.0` : '1.0'})</small></h3>

                {error || tokenError ? <CustomAlert text={message} color={'danger'} /> : null}
                {alert ? <CustomAlert text={message} color={'success'} /> : null}

                <hr />
                <Label className='fw-bold' htmlFor='name'>* {t('questionary.name_questionary')}</Label>
                <Input type='text' value={questionary.name} name='name' onChange={handleChange} />

                <div className="row g-3">
                    <div className="col">
                        <Label className='fw-bold' htmlFor='max_score'>* {t('questionary.max_score')}</Label>
                        <Input type='number' value={questionary.max_score} min={0} name='max_score' onChange={handleChange} />
                    </div>
                    <div className="col">
                        <Label className='fw-bold' htmlFor='min_score'>* {t('questionary.min_score')}</Label>
                        <Input type='number' value={questionary.min_score} min={0} max={questionary.max_score} name='min_score' onChange={handleChange} />
                    </div>
                </div>

                <div className='mt-3 d-flex justify-content-end'>
                    <UncontrolledDropdown>
                        <DropdownToggle caret color='primary'>{t('questionary.new_question')}</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => addNewQuestion(1)}>
                                {t('questionary.add_one_question')}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => addNewQuestion(2)}>
                                {t('questionary.add_two_question')}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => addNewQuestion(3)}>
                                {t('questionary.add_three_question')}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => addNewQuestion(5)}>
                                {t('questionary.add_five_question')}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => addNewQuestion(10)}>
                                {t('questionary.add_ten_question')}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>

                {
                    totalScoreError ?
                        <div className='mt-3'>
                            <CustomAlert color={'danger'} text={t('questionary.total_score_error')} />
                        </div>
                        :
                        null
                }

                <div className='mt-3'>
                    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                        <Droppable droppableId='questions'>
                            {(droppableProvided) => (
                                <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                                    {questionary.questions.map((question, i) => (
                                        <Draggable key={`questions-${i + 1}`} draggableId={`questions-${i + 1}`} index={i}>
                                            {(draggableIProvider) => (
                                                <div  {...draggableIProvider.draggableProps} ref={draggableIProvider.innerRef} {...draggableIProvider.dragHandleProps}>
                                                    <QuestionCard
                                                        key={i}
                                                        i={i}
                                                        question={question}
                                                        handleChange={handleChange}
                                                        addNewAnswer={() => addNewAnswer(i)}
                                                        deleteQuestion={() => deleteQuestion(i)}
                                                        deleteAnswer={deleteAnswer}
                                                        NumberOfQuestions={questionary.questions.length}
                                                        setCorrect={setCorrect}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                {
                    questionary.questions.length > 1 ?
                        <div className='d-flex justify-content-end' style={{ marginTop: '-25px' }}>
                            <CustomTooltip
                                target={"add_new_question"}
                                text={t('questionary.new_question')}
                                placement={"right"}
                            />
                            <i
                                type='button'
                                id='add_new_question'
                                onClick={() => addNewQuestion(1)}
                                className="fa-solid fa-circle-plus"
                            >
                            </i>
                        </div>
                        :
                        null
                }

                <div>
                    <Label htmlFor='active' className='fw-bold'>* {t('common.active')}</Label>
                    <select style={{ maxWidth: '227px' }} value={questionary.active} className="form-select" name='active' onChange={handleChange}>
                        {isEditForm ? null : <option value={''}>{t('common.choose')}</option>}
                        <option value={'true'}>{t('common.yes')}</option>
                        <option value={'false'}>{t('common.no')}</option>
                    </select>
                </div>

                <div className='mt-3'>
                    <p className='fw-bold'>* {t('common.requiredFields')}</p>
                    <div className='d-flex justify-content-center gap-5 flex-wrap'>
                        <Button className="btn-secondary" disabled={sending} onClick={() => navigate('/questionary')}>{t('common.cancel')}</Button>
                        <Button className="btn-primary" disabled={sending ? sending : disabled()}>{t('common.save')}</Button>
                    </div>
                </div>

            </Form>
        </Container>
    )
}
