import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

export const RedirectVisitModal = ({ alert, visit }) => {

  const { t } = useTranslation();

  const navigate = useNavigate()

  const handleClose = () => {
    navigate('/visits')
  }


  return (
    <Modal isOpen={alert} toggle={handleClose}>
      <ModalBody>
        {t('visits.ask_assing_inductions')} {visit.first_name} {visit.last_name}?
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={() => navigate(`/send/visit/induction/${visit._id}`)}>{t('common.yes')}</Button>
        <Button color='secondary' onClick={handleClose}>{t('common.no')}</Button>
      </ModalFooter>
    </Modal>
  )
}
