import i18n from "i18next";
import { CLEAR_ALERTS, CLEAR_VERSIONS, CREATE_QUESTIONARY, DELETE_QUESTIONARY, DELETE_QUESTIONARY_ERROR, ERROR_QUESTIONARY_VERSIONS, ERROR_QUESTIONNAIRES, GET_QUESTIONARY, GET_QUESTIONARY_VERSIONS, GET_QUESTIONNAIRES, UNATORIZED, UPDATE_QUESTIONARY } from "./types";

const QuestionaryReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case UNATORIZED:
            return {
                ...state,
                questionnaires: [],
                questionarySelected: null,
                isFetching: false,
                sending: false,
                error: false,
                message: i18n.t('common.error_token'),
                inputError: false,
                inputErrorMessage: '',
                alert: false,
                deleteError: false,
                alertDelete: false,
                tokenError: true,
            }

        case ERROR_QUESTIONNAIRES:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: true,
                message: i18n.t('common.error_server'),
                inputError: false,
                inputErrorMessage: '',
                alert: false,
                deleteError: false,
                alertDelete: false,
                tokenError: false
            }

        case GET_QUESTIONNAIRES:
            return {
                ...state,
                questionnaires: payload,
                questionarySelected: null,
                isFetching: false,
                sending: false,
                error: false,
                message: '',
                inputError: false,
                inputErrorMessage: '',
                alert: false,
                deleteError: false,
                alertDelete: false,
                tokenError: false
            }

        case CREATE_QUESTIONARY:
            return {
                ...state,
                isFetching: false,
                sending: true,
                error: false,
                message: i18n.t('common.alert_1'),
                inputError: false,
                alert: true,
                tokenError: false
            }

        case GET_QUESTIONARY:
            return {
                ...state,
                questionarySelected: payload,
                isFetching: false,
                sending: false,
                error: false,
                message: '',
                inputError: false,
                inputErrorMessage: '',
                alert: false,
                deleteError: false,
                alertDelete: false,
                tokenError: false
            }

        case UPDATE_QUESTIONARY:
            return {
                ...state,
                isFetching: false,
                sending: true,
                error: false,
                message: i18n.t('common.alert_2'),
                inputError: false,
                alert: true,
                tokenError: false
            }

        case DELETE_QUESTIONARY:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                deleteError: false,
                alertDelete: true,
                tokenError: false
            }

        case DELETE_QUESTIONARY_ERROR:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                message: payload,
                deleteError: true,
                alertDelete: false,
                tokenError: false
            }

        case GET_QUESTIONARY_VERSIONS:
            return {
                ...state,
                questionnairesVersions: payload,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                versionsError: false,
                tokenError: false
            }

        case ERROR_QUESTIONARY_VERSIONS:
            return {
                ...state,
                questionnairesVersions: [],
                isFetching: false,
                sending: false,
                error: false,
                alert: false,
                message: i18n.t('common.error_server'),
                deleteError: false,
                alertDelete: false,
                versionsError: true,
                tokenError: false
            }

        case CLEAR_ALERTS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                tokenError: false
            }

        case CLEAR_VERSIONS:
            return {
                ...state,
                questionnairesVersions: []
            }
        default:
            return state
    }
}

export default QuestionaryReducer
