import React from 'react'
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { ContractorDetailList } from './ContractorDetailList';

export const ContractorDetailTable = ({ contractors, visualizationId, orderBy, headers, setHeaders }) => {

    const { t } = useTranslation();

    const orderOpitons = {
        'contractor_name': {
            asc: (a, b) => a.contractor_name.localeCompare(b.contractor_name),
            desc: (a, b) => b.contractor_name.localeCompare(a.contractor_name)
        },
        'questionary': {
            asc: (a, b) => {
                if (a.questionary && b.questionary) {
                    return a.questionary.name.localeCompare(b.questionary.name) || a.questionary.version - b.questionary.version
                } else if (a.questionary && !b.questionary) {
                    return 1
                } else if (!a.questionary && b.questionary) {
                    return -1
                } else {
                    return 0
                }
            },
            desc: (a, b) => {
                if (a.questionary && b.questionary) {
                    return b.questionary.name.localeCompare(a.questionary.name) || b.questionary.version - a.questionary.version
                } else if (a.questionary && !b.questionary) {
                    return -1
                } else if (!a.questionary && b.questionary) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        'assigned_employees': {
            asc: (a, b) => a.assigned_employees - b.assigned_employees,
            desc: (a, b) => b.assigned_employees - a.assigned_employees
        },
        'employees_viewed': {
            asc: (a, b) => a.employees_viewed - b.employees_viewed,
            desc: (a, b) => b.employees_viewed - a.employees_viewed
        },
        'employees_approved': {
            asc: (a, b) => a.employees_approved - b.employees_approved,
            desc: (a, b) => b.employees_approved - a.employees_approved
        },
    }

    const handleSort = (key, order) => {
        const newData = headers.map((header) => (
            header.key === key
                ? { ...header, active: true, order: header.order === 'asc' ? 'desc' : 'asc' }
                : { ...header, active: false, order: 'asc' }
        ));
        orderBy(orderOpitons[key][order])
        setHeaders(newData);
    }

    return (
        <div>
            <div style={{ overflowX: 'auto' }}>
                <Table hover>
                    <thead style={{ background: '#e0e3e6' }}>
                        <tr>
                            <th></th>
                            <th></th>
                            <th
                                className='text-center'
                                colSpan={3}
                            >
                                <span className='pe-1'>{t('contractor.employees_plural')}</span>
                                <CustomTooltip
                                    placement={"top"}
                                    target={"info"}
                                    text={t('employee.employee_info')}
                                />
                                <i
                                    className="fa-solid fa-circle-info"
                                    id='info'
                                >
                                </i>
                            </th>
                            <th></th>

                        </tr>
                        <tr>
                            {
                                headers.map((header, idx) => (
                                    <th key={idx}>
                                        <div
                                            className={`d-flex flex-row justify-content-center align-items-${header.order === 'asc' ? 'end' : 'center'} gap-2`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleSort(header.key, header.order === 'asc' ? 'desc' : 'asc')}
                                        >
                                            <span>{header.label}</span>
                                            <i
                                                className={`fas fa-sort-${header.order === 'asc' ? 'up' : 'down'} ${header.active ? 'text-primary' : ''}`}
                                            >
                                            </i>
                                        </div>
                                    </th>
                                ))
                            }
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            contractors.map((contractor, idx) => (
                                <ContractorDetailList contractor={contractor} key={idx} />
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div >
    )
}
