import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

export const CustomTooltip = ({ placement, target, text, color }) => {
    return (
        <UncontrolledTooltip
            placement={placement}
            target={target}
            color={color}
        >
            {text}
        </UncontrolledTooltip>
    )
}
