import React from 'react'
import { useReducer } from 'react'
import QuestionaryContext from './QuestionaryContext'
import QuestionaryReducer from './QuestionaryReducer'
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/loginService';
import { questionaryCreate, questionaryDelete, questionaryGet, questionaryUpdate, questionaryVersionsGet, questionnairesGet } from '../../services/questionaryService';
import i18n from "i18next";

export const QuestionaryState = ({ children }) => {
    const initialState = {
        questionnaires: [],
        questionnairesVersions: [],
        questionarySelected: null,
        isFetching: true,
        sending: false,
        error: false,
        message: '',
        inputError: false,
        inputErrorMessage: '',
        alert: false,
        deleteError: false,
        alertDelete: false,
        versionsError: false,
        tokenError: false
    }

    const [state, dispatch] = useReducer(QuestionaryReducer, initialState);

    const navigate = useNavigate();

    const createQuestionary = async (questionary) => {
        try {
            const response = await questionaryCreate(questionary);
            if (response.ok) {
                dispatch({
                    type: 'CREATE_QUESTIONARY',
                    payload: response.data,
                })
                setTimeout(() => {
                    navigate('/questionary')
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 500:
                        dispatch({
                            type: 'ERROR_QUESTIONNAIRES',
                        })
                        break;
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_QUESTIONNAIRES',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_QUESTIONNAIRES',
            })
            console.log(e)
        }
    }

    const getQuestionnaires = async (name, active) => {
        try {
            const response = await questionnairesGet(name, active);
            if (response.ok) {
                dispatch({
                    type: 'GET_QUESTIONNAIRES',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 500:
                        dispatch({
                            type: 'ERROR_QUESTIONNAIRES',
                        })
                        break;
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_QUESTIONNAIRES',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_QUESTIONNAIRES',
            })
            console.log(e)
        }
    }

    const getQuestionary = async (id) => {
        try {
            const response = await questionaryGet(id);
            if (response.ok) {
                dispatch({
                    type: 'GET_QUESTIONARY',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 500:
                        dispatch({
                            type: 'ERROR_QUESTIONNAIRES',
                        })
                        break;
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_QUESTIONNAIRES',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_QUESTIONNAIRES',
            })
            console.log(e)
        }
    }

    const updateQuestionary = async (id, questionary) => {
        try {
            const response = await questionaryUpdate(id, questionary);
            if (response.ok) {
                dispatch({
                    type: 'UPDATE_QUESTIONARY',
                    payload: response.data,
                })
                setTimeout(() => {
                    navigate('/questionary')
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 500:
                        dispatch({
                            type: 'ERROR_QUESTIONNAIRES',
                        })
                        break;
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_QUESTIONNAIRES',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_QUESTIONNAIRES',
            })
            console.log(e)
        }
    }

    const deleteQuestionary = async (id, toggle, getCallBack) => {
        try {
            const response = await questionaryDelete(id);
            if (response.ok) {
                dispatch({
                    type: 'DELETE_QUESTIONARY',
                    payload: id
                })
                setTimeout(() => {
                    dispatch({
                        type: 'CLEAR_ALERTS',
                    })
                    toggle();
                    getCallBack();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 406:
                        dispatch({
                            type: 'DELETE_QUESTIONARY_ERROR',
                            payload: i18n.t('questionary.delete_questionary_error')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                    default:
                        dispatch({
                            type: 'DELETE_QUESTIONARY_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'DELETE_QUESTIONARY_ERROR',
                payload: i18n.t('common.error_server')
            })
            console.log(e);
        }
    }

    const getQuestionaryVersions = async (code) => {
        try {
            const response = await questionaryVersionsGet(code);
            if (response.ok) {
                dispatch({
                    type: 'GET_QUESTIONARY_VERSIONS',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 500:
                        dispatch({
                            type: 'ERROR_QUESTIONARY_VERSIONS',
                        })
                        break;
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_QUESTIONARY_VERSIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_QUESTIONNAIRES',
            })
            console.log(e)
        }
    }

    const clear = () => {
        dispatch({
            type: 'CLEAR_ALERTS',
        })
    }

    const clearQuestionaryVersions = () => {
        dispatch({
            type: 'CLEAR_VERSIONS',
        })
    }

    return (
        <QuestionaryContext.Provider
            value={{
                questionaryState: state,
                getQuestionnaires,
                createQuestionary,
                getQuestionary,
                updateQuestionary,
                deleteQuestionary,
                getQuestionaryVersions,
                clear,
                clearQuestionaryVersions
            }}
        >
            {children}
        </QuestionaryContext.Provider>
    )
}
