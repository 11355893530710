import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

export const SendInducctionOptions = ({ setModal, disabled, cameBack, tooltipMessage }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className='d-flex justify-content-center align-items-end gap-3 pt-4 flex-wrap mb-3'>
            {
                tooltipMessage && disabled() ?
                    <CustomTooltip
                        placement={"top"}
                        target={"send"}
                        text={tooltipMessage}
                    />
                    :
                    null
            }
            <div id='send'>
                <Button
                    color='primary'
                    style={{ minWidth: '86px' }}
                    disabled={disabled()}
                    onClick={() => setModal(true)}
                >
                    {t('common.send_induction')}
                </Button>
            </div>
            <Button
                color="secondary"
                style={{ minWidth: '86px' }}
                onClick={() => navigate(cameBack)}
            >
                {t('common.came_back')}
            </Button>
        </div>
    )
}
