import React from 'react'
import { useReducer } from 'react'
import ContractsContext from './ContractsContext'
import { ContractsReducer } from './ContractsReducer'
import { logout } from '../../services/loginService'
import { contractssGet } from '../../services/contractsService'

export const ContractsState = ({ children }) => {
    const initialState = {
        contracts: [],
        isFetching: true,
        error: false,
        message: '',
        tokenError: false
    }

    const [state, dispatch] = useReducer(ContractsReducer, initialState);

    const getContracts = async () => {
        try {
            const response = await contractssGet();
            if (response.ok) {
                dispatch({
                    type: 'GET_CONTRACTS',
                    payload: response.data.response,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CONTRACTS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_CONTRACTS',
            })
            console.log(e)
        }
    }
    
    return (
        <ContractsContext.Provider value={{
            contractsState: state,
            getContracts
        }}>
            {children}
        </ContractsContext.Provider>
    )
}
