import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import InductionContext from '../../context/Induction/InductionContext';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import { CustomPlayer } from '../CustomPlayer/CustomPlayer';
import { CustomPDFViewer } from '../CustomPDFViewer/CustomPDFViewer';
import { isIntegrated } from '../../helpers/helpers';
import { DownloadInduction } from '../DownloadInduction/DownloadInduction';
export const InductionPreviewModal = ({ setModal, modal, induction }) => {

    const { t } = useTranslation();

    const { id } = useParams();

    const { previewInduction, previewInductionDeleteURL, inductionState } = useContext(InductionContext);

    const { url, error } = inductionState;

    const [numPages, setNumPages] = useState(null)

    const [pageNumber, setPageNumber] = useState(1);

    const handleClose = () => {
        setModal(!modal)
        previewInductionDeleteURL(induction.files[0].name, id)
    }

    useEffect(() => {
        previewInduction(induction.files[0].name, id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal isOpen={modal} toggle={handleClose} size={'xl'}>
            <ModalHeader>
                {induction.name}
            </ModalHeader>
            <ModalBody>
                {
                    url ?
                        <>
                            {
                                induction.files[0].type.split('/')[0] === 'video' ?
                                    <CustomPlayer url={url} />
                                    :
                                    induction.files[0].type === 'application/pdf' ?
                                        <>
                                            <CustomPDFViewer
                                                url={url}
                                                numPages={numPages}
                                                setNumPages={setNumPages}
                                                pageNumber={pageNumber}
                                                setPageNumber={setPageNumber}
                                            />
                                            <p>{t('inductions.n_page')} {pageNumber} {t('inductions.n_of')} {numPages}</p>
                                        </>
                                        :
                                        <img className='w-100' src={url} alt={''} />
                            }
                            {
                                induction.allow_download === true && isIntegrated() ?
                                    <DownloadInduction inductionName={induction.files[0].name} />
                                    :
                                    null
                            }
                        </>
                        :
                        !error ?
                            <Spinner className='mx-auto d-flex' />
                            :
                            null
                }
                {error ? <CustomAlert text={t('common.error_server')} color={'danger'} /> : null}
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleClose}>{t('common.close')}</Button>
            </ModalFooter>
        </Modal>
    )
}
