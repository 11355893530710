import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
export const FullScreenPDFPreview = ({ setModal, modal, viewer }) => {

    const { t } = useTranslation();

    const handleClose = () => {
        setModal(!modal)
    }

    return (
        <Modal isOpen={modal} toggle={handleClose} fullscreen={true}>
            <ModalBody>
                {viewer}
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleClose}>{t('common.close')}</Button>
            </ModalFooter>
        </Modal>
    )
}
