import React from 'react'
import { Link } from 'react-router-dom'

export const Resource = ({ title, newResource, resource, typeRol }) => {
    return (
        <div className="d-flex align-items-center justify-content-between flex-wrap">
            <h3>{title}</h3>
            {
                typeRol === true && typeRol !== undefined ?
                    null
                    :
                    <Link className="btn btn-primary" to={`new`}><span><i className="fa-solid fa-plus"></i></span>  {resource}</Link>
            }
        </div>
    )
}
