import React from 'react'
import { useTranslation } from 'react-i18next';
import { Badge, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export const QuestionaryModal = ({ questionary, setModal, modal }) => {

    const { t } = useTranslation();

    const toggle = () => {
        setModal(!modal);
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} size={'xl'}>
                <ModalHeader toggle={toggle}>
                    {questionary.name} <small>(<small>v</small>{questionary.version}.0)</small>
                </ModalHeader>
                <ModalBody>

                    <div className="d-flex justify-content-around pb-3" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <div>
                            <Label htmlFor='max_score'>{t('questionary.max_score')}: </Label> <span>{questionary.max_score}</span>
                        </div>
                        <div>
                            <Label htmlFor='min_score'>{t('questionary.min_score')}: </Label> <span>{questionary.min_score}</span>
                        </div>
                    </div>

                    {questionary.questions.map((question) => (
                        <div className="card mb-5" key={question._id}>
                            <div className="card-header">
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <div>
                                        <Label className='fw-bold'>{question.question}</Label>
                                    </div>
                                    <div className='fs-4'>
                                        <Badge color={'success'}>{question.score}</Badge>
                                    </div>
                                </div>
                            </div>
                            <ul className="list-group list-group-flush">
                                {
                                    question.answers.map((answer) => (
                                        <li
                                            key={answer._id}
                                            className={answer.correct_answer ? 'list-group-item d-flex justify-content-between bg-success text-light' : 'list-group-item d-flex justify-content-between'}
                                        >
                                            {answer.answer}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))}
                    <div>
                        <span className='fw-bold'>{t('common.name_date')}: </span>{questionary.created_at.slice(0, 10).split('-').reverse().join('-')}
                    </div>
                    <div>
                        <span className='fw-bold'>{t('common.active')}: </span><span>{questionary.active ? t('common.yes') : t('common.no')}</span>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggle}>{t('common.close')}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
