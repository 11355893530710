import React, { useReducer } from 'react'
import { tenant } from '../../services/tenantService';
import TenantContext from './TenantContext';
import TenantReducer from "./TenantReducer";
import { setColor, setTenant } from "../../helpers/helpers";
import i18n from "i18next";

export const TenantState = ({ children }) => {

    const InitialState = {
        tenantInfo: null,
        themeInfo: null,
        isFetching: true,
        error: false,
        message: null
    }

    const [state, dispatch] = useReducer(TenantReducer, InitialState)

    const getTenant = async () => {
        try {
            const response = await tenant();
            if (response.ok) {
                dispatch({
                    type: 'GET_TENANT',
                    payload: response.data
                })
                setTenant(response.data);
                setColor(response.data.theme);
                document.title = response.data.app_name;
                i18n.changeLanguage(response.data.language);
            } else {
                dispatch({
                    type: 'TENANT_ERROR',
                })
            }
        } catch (e) {
            dispatch({
                type: 'TENANT_ERROR',
            })
            console.log(e);
        }
    }

    return (
        <TenantContext.Provider
            value={{
                getTenant,
                tenant: state,
            }}
        >
            {children}
        </TenantContext.Provider>
    )
}
