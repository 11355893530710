import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Spinner } from 'reactstrap'
import { CustomAlert } from '../../components/CustomAlert/CustomAlert'
import { PaginationCustom } from '../../components/PaginationCustom/PaginationCustom'
import { Resource } from '../../components/Resource/Resource'
import { VisitsFilter, VisitTable } from '../../components/Visits'
import VisitContext from '../../context/Visit/VisitContext'

export const Visits = () => {

    const { getVisists, visitState, changePageVisit } = useContext(VisitContext);

    const { isFetching, error, totalPages, message, visits, changingPage } = visitState;

    const { t } = useTranslation();

    const initialState = {
        first_name: '',
        last_name: '',
        document_number: '',
        status: '',
    }
    const [filter, setFilter] = useState(initialState);
    const [page, setPage] = useState(1);
    const [errorFilter, setErrorFilter] = useState(false);

    const { first_name, last_name, document_number, status } = filter

    const handleChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value,
        });
    };

    const search = () => {
        changePageVisit()
        setPage(1);
        getVisists(1, first_name, last_name, document_number, status);
        setErrorFilter(true);
    }

    const clean = () => {
        changePageVisit()
        setPage(1);
        setFilter(initialState);
        getVisists(1, '', '', '', '');
    }

    useEffect(() => {
        getVisists(page, first_name, last_name, document_number, status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <Container
            className='bg-white mt-5 pb-5 ps-5 pe-5 pt-5 rounded-3'>
            <Resource title={t('visits.title')} resource={t('visits.new')} newResource={'visit'} />
            <hr className='bg-dark' />
            <VisitsFilter handleChange={handleChange} search={search} clean={clean} filterValues={filter} />
            <hr className='bg-dark' />
            {
                isFetching ?
                    <div className='d-flex justify-content-center'>
                        <Spinner size={'xxl'} />
                    </div>
                    :
                    visits.length > 0 ?
                        <>
                            {
                                changingPage ?
                                    <div className='d-flex justify-content-center mt-5 mb-5 pt-5 pb-5'>
                                        <Spinner size={'xxl'} />
                                    </div> :
                                    <VisitTable visits={visits} getVisists={() => getVisists(page, first_name, last_name, document_number, status)} />
                            }
                            <>
                                <PaginationCustom totalPages={totalPages} paginate={setPage} currentPage={page} changePage={changePageVisit}/>
                            </>
                        </>
                        :
                        visits.length === 0 && errorFilter && !error && !!!isFetching ?
                            <div className='d-flex justify-content-center'>{t('common.error_filter')}</div>
                            :
                            visits.length === 0 && !errorFilter && !error && !!!isFetching ?
                                <div className='d-flex justify-content-center'>{t('common.not_loaded')}</div> :
                                null

            }
            {error ? <CustomAlert text={message} color={'danger'} /> : null}
        </Container>
    )
}
