import React from 'react'
import { Alert } from 'reactstrap';

export const CustomAlert = ({ color, text }) => {
    return (
        <div className='text-center'>
            <Alert color={color}>{text}</Alert>
        </div>
    )
}
