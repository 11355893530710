import i18n from "i18next";
import { GET_BUSINESS_TYPES_OPENINGS, ERROR_BUSINESS_TYPES_OPENINGS, UNATORIZED } from "./types";

const BusinessTypeOpeningsReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_BUSINESS_TYPES_OPENINGS:
            return {
                ...state,
                businessTypeOpenings: payload,
                isFetching: false,
                error: false,
                message: '',
                tokenError: false
            }
        case ERROR_BUSINESS_TYPES_OPENINGS:
            return {
                ...state,
                isFetching: false,
                error: true,
                message: i18n.t('common.error_server'),
            }
        case UNATORIZED:
            return {
                ...state,
                isFetching: false,
                error: true,
                message: i18n.t('common.error_token'),
                tokenError: true
            }
        default:
            return state
    }
}

export default BusinessTypeOpeningsReducer