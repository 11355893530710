import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

export const QuestionaryList = ({ questionary, handleModal }) => {
    const { t } = useTranslation();

    const isLastVersion = questionary.version > 1

    return (
        <tr>
            <td>
                {questionary.name}
            </td>
            <td>
                {questionary.version}.0
            </td>
            <td>
                {questionary.active === true ? t('common.yes') : t('common.no')}
            </td>
            <td className='d-flex gap-3 align-items-center flex-wrap'>
                <CustomTooltip
                    placement={"top"}
                    target={`edit-${questionary._id}`}
                    text={t('common.action_edit')}
                />
                <Link
                    style={{ color: 'black', width: '14px' }}
                    to={`edit/${questionary._id}`}
                    id={`edit-${questionary._id}`}
                >
                    <i className="fa-solid fa-file-pen me-2"></i>
                </Link>

                <CustomTooltip
                    placement={"top"}
                    target={`delete-${questionary._id}`}
                    text={t('common.action_delete')}
                />
                <i
                    type='button'
                    className="fa-solid fa-trash"
                    id={`delete-${questionary._id}`}
                    onClick={() => handleModal(questionary._id, false, isLastVersion)}
                >
                </i>
                {
                    questionary.version > 1 ?
                        <>
                            <CustomTooltip
                                placement={"top"}
                                target={`versions-${questionary._id}`}
                                text={t('common.view_versions')}
                            />

                            <i
                                type='button'
                                id={`versions-${questionary._id}`}
                                className="fa-solid fa-folder-tree"
                                onClick={() => handleModal(questionary.code, true)}
                            >
                            </i>
                        </>
                        :
                        null
                }
            </td>
        </tr>
    )
}
