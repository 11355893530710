import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getTenantByKey } from '../../helpers/helpers'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip'
import { DownloadInduction } from '../DownloadInduction/DownloadInduction'

export const InducitonList = ({ induction, handleModal }) => {

  const { t } = useTranslation();

  const classificationsSingularName = getTenantByKey('singular_classification_name');

  const [showOtherData, setShowOtherData] = useState(false);

  const noFile = induction.files ? induction.files.length > 0 : true;

  const dateFrom = induction.date_from.slice(0, 10).split('-');

  const dateTo = induction.date_to ? induction.date_to.slice(0, 10).split('-') : null

  const status = () => {
    switch (induction.status) {
      case 'C':
        return t('inductions.expired')
      case 'V':
        return t('inductions.current')
      case 'F':
        return t('inductions.futura')
      case 'E':
        return t('inductions.finished')
      default:
        return t('inductions.expired')
    }
  }

  return (
    <>
      <tr>
        <td >
          <i
            className={showOtherData ? "fa-solid fa-angles-up" : "fas fa-angle-double-down"}
            style={{ marginRight: '-12px' }}
            type='button'
            onClick={() => setShowOtherData(!showOtherData)}
          >
          </i>
        </td>
        <td>
          {`${dateFrom[2]}/${dateFrom[1]}/${dateFrom[0]}`}
        </td>
        <td>{induction.date_to ? `${dateTo[2]}/${dateTo[1]}/${dateTo[0]}` : '-'}</td>
        <td>{induction.name}</td>
        <td>{status()}</td>
        <td className='d-flex gap-3 align-items-center flex-wrap'>
          <CustomTooltip
            placement={"top"}
            target={induction.active === true ? 'expire' : 'restore'}
            text={induction.active === true ? t('common.expire_induction') : t('common.reset_induction')}
          />
          <i
            className={induction.active === true ? "fa-solid fa-circle-xmark link-danger" : "fa-solid fa-recycle link-success"}
            style={{ width: '16px' }}
            type='button'
            id={induction.active === true ? 'expire' : 'restore'}
            onClick={() => handleModal(induction._id, !induction.active)}
          >
          </i>

          <CustomTooltip
            placement={"top"}
            target={"edit"}
            text={t('common.action_edit')}
          />
          <Link
            style={{ color: 'black' }}
            to={`edit/${induction._id}`}
            id='edit'
          >
            <i className="fa-solid fa-file-pen"></i>
          </Link>

          <CustomTooltip
            placement={"top"}
            target={"delete"}
            text={t('common.action_delete')}
          />
          <i
            className="fa-solid fa-trash"
            onClick={() => handleModal(induction._id)}
            type='button'
            id='delete'
          >
          </i>
        </td>
      </tr>
      {
        showOtherData ?
          <tr>
            <td colSpan={6}>
              <i>{induction.description}</i>
              <p
                style={{ marginBottom: '0' }}
                className='fw-bold'>
                {classificationsSingularName}:
                <span
                  className='fw-normal ms-1'
                >
                  {induction.classification ? induction.classification.description : '-'}
                </span>
              </p>
              {
                noFile ?
                  <DownloadInduction inductionName={induction.files[0].name} />
                  :
                  null
              }
            </td>
          </tr>
          :
          null
      }
    </>
  )
}

