import React from 'react'
import { useReducer } from 'react'
import { contractorsGet, contractorsGrant, contractorsRevoke, contractorsSendInduction, exportSummary, summaryGet, totalGet } from '../../services/contactorService'
import { ContractorContext } from './ContractorContext'
import { ContractorReducer } from './ContractorReducer'
import { logout } from '../../services/loginService';
import i18n from "i18next";

export const ContractorState = ({ children }) => {
    const initialState = {
        contractors: [],
        visualizationId: null,
        contractorelected: null,
        totalPages: null,
        totalDetail: null,
        isFetching: true,
        changingPage: false,
        sending: false,
        error: false,
        message: '',
        alert: false,
        errorSend: false,
        alertStatus: false,
        errorStatus: false,
    }


    const [state, dispatch] = useReducer(ContractorReducer, initialState);

    const sending = () => {
        dispatch({
            type: 'SENDING'
        })
    }

    const getContrators = async (id, page, contractor_name, contractor_code, contractor_classification_id, business_type_code, business_type_opening_code) => {
        try {
            const response = await contractorsGet(id, page, contractor_name, contractor_code, contractor_classification_id, business_type_code, business_type_opening_code);
            if (response.ok) {
                dispatch({
                    type: 'GET_CONTRACTORS',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CONTRACTORS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_CONTRACTORS',
            })
            console.log(e)
        }
    }

    const sendInductionToContractor = async (values, toggle, setSelectedContractors, getCallback) => {
        try {
            sending();
            const response = await contractorsSendInduction(values);
            if (response.ok) {
                dispatch({
                    type: 'SEND_INDUCTION',
                    payload: response.data,
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                    setSelectedContractors([])
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'SEND_INDUCTION_ERROR',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'SEND_INDUCTION_ERROR',
            })
            console.log(e)
        }
    }

    const revokeToContractor = async (id, contractorID, toggle, getCallback) => {
        try {
            const response = await contractorsRevoke(id, { contractor: contractorID });
            if (response.ok) {
                dispatch({
                    type: 'REVOKE_INDUCTION',
                    payload: response.data,
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'STATUS_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'STATUS_ERROR',
                payload: i18n.t('common.error_server')
            })
            setTimeout(() => {
                dispatch({
                    type: 'CLEAR_ALERTS',
                })
            }, 3000);
            console.log(e)
        }
    }

    const grantToContractor = async (id, contractorID, toggle, getCallback) => {
        try {
            const response = await contractorsGrant(id, { contractor: contractorID });
            if (response.ok) {
                dispatch({
                    type: 'GRANT_INDUCTION',
                    payload: response.data,
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'STATUS_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'STATUS_ERROR',
                payload: i18n.t('common.error_server')
            })
            setTimeout(() => {
                dispatch({
                    type: 'CLEAR_ALERTS',
                })
            }, 3000);
            console.log(e)
        }
    }

    const changePageContractor = () => {
        dispatch({
            type: 'CHANGE_PAGE'
        })
    }

    const cleanContractors = () => {
        dispatch({
            type: 'CLEAN_CONTRACTORS'
        })
    }

    const getSummary = async (id) => {
        try {
            const response = await summaryGet(id);
            if (response.ok) {
                dispatch({
                    type: 'GET_CONTRACTORS',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CONTRACTORS',
                        })
                        break;
                }
            }

        } catch (e) {
            console.log(e);
            dispatch({
                type: 'ERROR_CONTRACTORS',
            })
        }
    }

    const summaryExport = async (inductionId, setIsDownloading) => {
        try {
            const response = await exportSummary(inductionId);
            if (response.ok) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Detail.xlsx");
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CONTRACTORS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_CONTRACTORS',
            })
            console.log(e);
        }
    }

    const getTotal = async (id) => {
        try {
            const response = await totalGet(id);
            if (response.ok) {
                dispatch({
                    type: 'GET_TOTAL',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CONTRACTORS',
                        })
                        break;
                }
            }

        } catch (e) {
            console.log(e);
            dispatch({
                type: 'ERROR_CONTRACTORS',
            })
        }
    }

    return (
        <ContractorContext.Provider value={{
            contractorState: state,
            getContrators,
            sendInductionToContractor,
            revokeToContractor,
            grantToContractor,
            changePageContractor,
            cleanContractors,
            getSummary,
            summaryExport,
            getTotal
        }}>
            {children}
        </ContractorContext.Provider>
    )
}
