import React, { Fragment, useContext, useEffect, useState } from 'react';
import image_bg from '../../assets/images/pexels-thirdman-7994325.jpg';
import logo from '../../assets/images/exactian-logo.png';
import {
  Button,
  Container,
  Card,
  CardImg,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { getTenantByKey, isUnique } from '../../helpers/helpers';
import { ForgotPassword } from '../../components/ForgotPassword/ForgotPassword';
import { useTranslation } from 'react-i18next';
import TenantContext from '../../context/Tenant/TenantContext';
import LoginContext from '../../context/Login/LoginContext';

export const Login = () => {

  const { loginSession, loginState } = useContext(LoginContext)

  const { getTenant, tenant } = useContext(TenantContext)

  const initialState = {
    user_name: '',
    password: '',
  }

  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState(initialState);

  const [modal, setModal] = useState(false);

  const disabled = !!!userInfo.user_name.trim() || !!!userInfo.password.trim();

  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };
  const handleLogin = () => {
    loginSession(userInfo);
  }

  const minHeight = {
    minHeight: '100vh',
  };

  const onKeyPress = (e) => {
    if ((e.key === 'Enter') & !disabled) {
      handleLogin();
    }
  };

  const clientLogo = getTenantByKey('logo');

  const appName = getTenantByKey('app_name');

  const version = getTenantByKey('version');

  useEffect(() => {
    getTenant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Container
        className="themed-container align-content-center h-100"
        fluid="md"
      >
        <ForgotPassword modal={modal} setModal={setModal} />
        <Row
          className="justify-content-center align-content-center h-100"
          style={minHeight}
        >
          <Col md="6" className="m-1 p-0 align-content-center">
            <Card body className="m-0 p-1">
              <div
                className="p-0 m-0 card bg-dark text-white"
                style={{ border: 'none' }}
              >
                <CardImg top width="100%" src={image_bg} alt="Card image cap" />
                <div className="card-img-overlay">
                  <div className="h-100 d-flex flex-column justify-content-between">
                    <img
                      src={logo}
                      style={{ width: '140px' }}
                      alt="ford-logo"
                    />
                    {
                      tenant.isFetching && !tenant.error ?
                        <Spinner className='mx-auto d-flex' size={'XXL'} />
                        :
                        tenant.error ?
                          <h3 className='card-title text-end'>
                            Exactian - Inducciones
                          </h3>
                          :
                          <h3 className="card-title text-end">
                            {appName}
                          </h3>
                    }
                  </div>
                </div>
              </div>
              <CardBody>
                {
                  tenant.isFetching && !tenant.error ?
                    <Spinner className='mx-auto d-flex' size={'XXL'} />
                    :
                    tenant.error ?
                      <CardText className="text-center" style={{ height: '210px' }}>{t('common.error_tenant')}</CardText>
                      :
                      <>
                        <CardTitle tag="h5" className=" text-center">
                          <img
                            src={clientLogo}
                            className="img-fluid"
                            alt="exactian-logo"
                            style={{ width: '20%' }}
                          />
                        </CardTitle>
                        <Form>
                          <FormGroup>
                            <Label for="userName">{t('login.user_name')}</Label>
                            <Input
                              type="text"
                              name="user_name"
                              autoComplete="off"
                              value={userInfo.user_name}
                              onChange={(e) => handleChange(e)}
                              onKeyPress={(e) => onKeyPress(e)}

                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="exampleEmail">{t('login.password')}</Label>
                            <Input
                              type="password"
                              name="password"
                              autoComplete="off"
                              value={userInfo.password}
                              onChange={(e) => handleChange(e)}
                              onKeyPress={(e) => onKeyPress(e)}

                            />
                          </FormGroup>
                        </Form>
                        {loginState.error ? <div className='alert alert-danger text-center'>{loginState.message}</div> : null}
                        <Button
                          className="btn-primary w-100"
                          disabled={disabled}
                          onClick={handleLogin}
                        >
                          <span>{t('login.login')}</span>
                        </Button>
                        {
                          isUnique() ?
                            <div
                              className='mt-3 d-flex justify-content-center'
                              style={{ cursor: 'pointer' }}
                              onClick={() => setModal(true)}
                            >
                              {t('login.forgotPassword')}
                            </div>
                            :
                            null
                        }
                      </>
                }

              </CardBody>
              <CardFooter className="bg-transparent align-items-center">
                {
                  tenant.isFetching && !tenant.error ?
                    <Spinner size={'XXL'} className='d-flex mx-auto' />
                    :
                    <CardText className="text-muted text-center">
                      <small>
                        V. {tenant.error ? null : version} - © - Exactian Consulting S.A. -{' '}
                        <a
                          href="https://www.exactian.com"
                          className="card-link text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.exactian.com
                        </a>
                      </small>
                    </CardText>
                }
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
