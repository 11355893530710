import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { CustomAlert } from '../CustomAlert/CustomAlert';
import InductionContext from '../../context/Induction/InductionContext';
import { isIntegrated } from '../../helpers/helpers';
import { ContractorContext } from '../../context/Contractor/ContractorContext';
import { EmployeeContext } from '../../context/Employee/EmployeeContext';
import VisitContext from '../../context/Visit/VisitContext';
import { useLocation, useParams } from 'react-router-dom';

export const RevokeOrGrantModal = ({ setModal, modal, id, contractorId, employeeId, values, visit, getCallback }) => {

    const { updateInductionActive, expireInduction, inductionState } = useContext(InductionContext);
    const { contractorState, revokeToContractor, grantToContractor } = useContext(ContractorContext);
    const { employeeState, revokeToEmployee, grantToEmployee } = useContext(EmployeeContext);
    const { revokeInduction, grantToInduction, visitState } = useContext(VisitContext);
    const { t } = useTranslation();

    const { pathname: location } = useLocation();

    const { id: inductionId } = useParams()

    const statusOptions = {
        '/induction': {
            revokeOrGrant: isIntegrated() ? function () { expireInduction(id, toggle, getCallback) } : function () { updateInductionActive(id, values, toggle, getCallback) },
            alert: inductionState.alertStatus,
            error: inductionState.deleteError,
            message: inductionState.message
        },
        [`/induction/send/contractor/${inductionId}`]: {
            revokeOrGrant: values ? function () { grantToContractor(id, contractorId, toggle, getCallback) } : function () { revokeToContractor(id, contractorId, toggle, getCallback) },
            alert: contractorState.alertStatus,
            error: contractorState.errorStatus,
            message: contractorState.message
        },
        [`/induction/send/employee/${inductionId}/${contractorId}`]: {
            revokeOrGrant: values ? function () { grantToEmployee(id, employeeId, contractorId, toggle, getCallback) } : function () { revokeToEmployee(id, employeeId, contractorId, toggle, getCallback) },
            alert: employeeState.alertStatus,
            error: employeeState.errorStatus,
            message: employeeState.message
        },
        [`/visits/send/${visit?.visit}`]: {
            revokeOrGrant: values ? function () { grantToInduction(visit, toggle, getCallback) } : function () { revokeInduction(visit, toggle, getCallback) },
            alert: visitState.alertStatus,
            error: visitState.errorStatus,
            message: visitState.message
        }
    }

    const toggle = () => {
        setModal(!modal)
    };

    const handleConfirm = () => {
        statusOptions[location].revokeOrGrant();
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader>
                    {t('common.confirm')}
                </ModalHeader>
                <ModalBody>
                    {values === true ? t('inductions.confirmRecycle') : t('inductions.confirmExpired')}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleConfirm} color="primary">{values === true ? t('inductions.restore') : t('inductions.expire')}</Button>
                    <Button onClick={toggle}>{t('common.cancel')}</Button>
                </ModalFooter>

                {statusOptions[location].alert ? <CustomAlert text={t('inductions.confirmState')} color={'success'} /> : null}

                {statusOptions[location].error ? <CustomAlert text={statusOptions[location].message} color={'danger'} /> : null}

            </Modal>
        </div>
    )
}
