import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import LoginContext from '../../context/Login/LoginContext';
import TenantContext from '../../context/Tenant/TenantContext';

export const LoginExactian = () => {
   
    const { token } = useParams();
    
    const { getTenant, tenant } = useContext(TenantContext)
    
    const { loginSessionExactian } = useContext(LoginContext)
    
    const navigate = useNavigate();
   
    useEffect(() => {
        getTenant();
        loginSessionExactian(token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (tenant.error) {
        navigate('/login')
    }

    if (!tenant.error) {
        return (
            <div className='position-absolute top-50 start-50'>
                <Spinner size={'xl'} />
            </div>
        )
    }

}
