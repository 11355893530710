
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap'
import { getTenantByKey } from '../../helpers/helpers';
import { Link } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const ExamCertificate = ({
    exam = null,
    questionary = {},
    induction = {},
    approved = false,
    date_from = new Date().toISOString(),
    date_to = new Date().toISOString(),
    isEmployee = false,
    isVisit = false,
    first_name = '',
    last_name = '',
    employee_name = '',
    employee_last_name = '',
    tryAgainLink = ''
}) => {

    const { t } = useTranslation();

    const clientLogo = getTenantByKey('logo');

    return (
        <>

            <div className='d-flex justify-content-between'>

                <h6>
                    {approved && (date_from.slice(0, 10).split('-').reverse().join('-'))}
                </h6>

                <img
                    src={clientLogo}
                    alt='clientLogo'
                />

            </div>

            {exam && (
                <div className='d-flex custom-exam-flex gap-5 align-items-center justify-content-around ps-5 pb-5 pe-5 pt-5 border rounded-3 mt-5 mb-5'>

                    <div className='d-flex flex-column gap-3 justify-content-center align-items-center'>

                        <div style={{ width: '234.5px' }} >
                            <CircularProgressbar
                                value={exam.note}
                                maxValue={questionary.max_score}
                                text={`${(exam.note * 100) / questionary.max_score}%`}
                                styles={
                                    buildStyles({
                                        pathColor: approved ? 'green' : 'red',
                                        textColor: approved ? 'green' : 'red',
                                    })
                                }
                            />
                        </div>

                        <h4>
                            {t('questionary.exam_pass_note')}: {exam.note}/{questionary.max_score}
                        </h4>

                    </div>

                    <div className='d-flex flex-column gap-2 mb-2'>

                        <span className='fs-3'>

                            {approved ? t('questionary.congratulations') : t('questionary.we_sorry')}

                            <span className='ps-2 fw-bold'>

                                {isEmployee && (
                                    `${employee_name} ${employee_last_name}`
                                )}

                                {isVisit && (
                                    `${first_name} ${last_name}`
                                )}

                            </span>
                        </span>

                        <span>

                            {approved ? t('questionary.exam_pass_confirm') : t('questionary.exam_reject_confirmation')}

                            <span className='pe-1 fw-bold'>

                                {questionary.name}

                                <small className='ps-1'>
                                    (<small>v</small>{`${questionary.version}.0`})
                                </small>

                            </span>

                            {approved ? t('questionary.exam_pass_confirm_two') : t('questionary.exam_pass_confirm_two')}

                            <span className='fw-bold'>
                                {induction.name}.
                            </span>

                        </span>

                        <p>

                            <span className='pe-1'>
                                {approved ? t('questionary.enabled_to_date') : t('questionary.try_again_question')}
                            </span>

                            {approved && (date_to.slice(0, 10).split('-').reverse().join('-'))}

                        </p>

                    </div>

                </div>
            )}

            {!exam && (
                <div className='d-flex align-items-center justify-content-center gap-5 ps-5 pb-5 pe-5 pt-5 flex-wrap border rounded-3 mt-5 mb-5'>

                    <div>
                        <i
                            className="fa-regular fa-thumbs-up"
                            style={{ fontSize: '150px' }}
                        >
                        </i>
                    </div>

                    <div className='d-flex flex-column gap-2 mb-2'>

                        <div>
                            <span className='fs-3 fw-bold'>

                                {isEmployee && (
                                    `${employee_name} ${employee_last_name}`
                                )}

                                {isVisit && (
                                    `${first_name} ${last_name}`
                                )}

                            </span>
                        </div>

                        <span>

                            {t('questionary.exam_pass_confirm_three')}

                            <span className='ps-1 fw-bold'>
                                {induction.name}.
                            </span>

                        </span>

                        <p>

                            <span className='pe-1'>
                                {t('questionary.enabled_to_date')}
                            </span>

                            {date_to.slice(0, 10).split('-').reverse().join('-')}

                        </p>

                    </div>

                </div>
            )}

            <div className='d-flex justify-content-end'>

                {approved && (
                    <Button
                        color='primary'
                        onClick={() => window.print()}
                    >
                        {t('questionary.print_exam')}
                    </Button>
                )}

                {!approved && (
                    <Link
                        to={tryAgainLink}
                        replace
                    >
                        {t('questionary.try_again_action')}
                    </Link>
                )}

            </div>

        </>
    )

}
