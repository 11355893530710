import React, { memo } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import { Spinner } from 'reactstrap';
import { ArrowPagination } from '../ArrowPagination/ArrowPagination';

export const CustomPDFViewer = memo(({ url, numPages, setNumPages, pageNumber, setPageNumber }) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    return (
        <div>
            <ArrowPagination pageNumber={pageNumber} totalPages={numPages} setPageNumber={setPageNumber} limit={1}>
                <Document
                    file={{ url: url }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onSourceError={(error) => console.log(error)}
                    loading={
                        <div className='d-flex justify-content-center align-items-center align-content-center' >
                            <Spinner size={'xl'} />
                        </div>
                    }
                    renderMode={'svg'}
                    renderTextLayer={true}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </ArrowPagination>
        </div>
    )
})
