import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { DeleteModal } from '../DeleteModal/DeleteModal';
export const ClassificationsTable = ({ classifications }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const handleModal = (id) => {
    setModal(true);
    setId(id);
  }
  return (
    <Fragment>
      <DeleteModal modal={modal} setModal={setModal} id={id} />
      <div style={{ overflowX: 'auto' }}>
        <Table hover>
          <thead style={{ background: '#e0e3e6' }}>
            <tr>
              <th>
                {t('common.code')}
              </th>
              <th>
                {t('common.description')}
              </th>
              <th>
                {t('common.enabled')}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {classifications.map((classification) => (
              <tr key={classification._id}>
                <td>
                  {classification.code}
                </td>
                <td>
                  {classification.description}
                </td>
                <td>
                  {classification.active === true ? t('common.yes') : t('common.no')}
                </td>
                <td>

                  <CustomTooltip
                    placement={"top"}
                    target={`edit-${classification._id}`}
                    text={t('common.action_edit')}
                  />
                  <Link
                    style={{ color: 'black' }}
                    to={`edit/${classification._id}`}
                    id={`edit-${classification._id}`}
                  >
                    <i className="fa-solid fa-file-pen"></i>
                  </Link>

                  <CustomTooltip
                    placement={"top"}
                    target={"delete"}
                    text={t('common.action_delete')}
                  />
                  <i
                    className="fa-solid fa-trash ms-2"
                    onClick={() => handleModal(classification._id)}
                    id='delete'
                    type='button'
                  >
                  </i>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Fragment>
  )
}
