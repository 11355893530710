import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { CustomAlert } from '../../components/CustomAlert/CustomAlert';
import { LocalPaginationCustom } from '../../components/LocalPaginationCustom/LocalPaginationCustom';
import { ValidInductionsTable } from '../../components/Visits/ValidInductionsTable';
import { ValidInductionsFilter } from '../../components/Visits/ValidInductionsFilter'
import VisitContext from '../../context/Visit/VisitContext';
import { getCurrentItems } from '../../helpers/helpers';
import { SendInducctionOptions } from '../../components/SendInduction/SendInducctionOptions';
import { SendInductionModal } from '../../components/SendInduction/SendInductionModal';

export const VisitIntegrated = () => {

  const { getValidInductions, visitState } = useContext(VisitContext);

  const { isFetching, error, message, validInductions } = visitState;

  const { t } = useTranslation();

  const { id } = useParams();

  const initialStateHeaders = [
    { key: 'induction', label: t('inductions.induction_name'), order: 'asc', active: true },
    { key: 'questionary', label: t('questionary.questionary'), order: 'asc', active: false },
    { key: 'sended', label: t('employee.send'), order: 'asc', active: false },
    { key: 'viewed', label: t('inductions.seen'), order: 'asc', active: false },
    { key: 'approved', label: t('employee.exam_passed'), order: 'asc', active: false },
    { key: 'note', label: t('questionary.exam_pass_note'), order: 'asc', active: false },
    { key: 'attemps', label: t('employee.attemps_exam'), order: 'asc', active: false },
    { key: 'date_from', label: t('inductions.since_only'), order: 'asc', active: false },
    { key: 'date_to', label: t('inductions.until_only'), order: 'asc', active: false },
  ]

  const initialStateFilter = {
    name: '',
    passed: '',
    send: '',
  }

  const [filter, setFilter] = useState(initialStateFilter);

  const [headers, setHeaders] = useState(initialStateHeaders)

  const [stateInductions, setStateInductions] = useState([]);

  const [page, setPage] = useState(1)

  const [errorFilter, setErrorFilter] = useState(false);

  const [selectedInductions, setSelectedInductions] = useState([]);

  const [modal, setModal] = useState(false);

  const currentInductions = getCurrentItems(stateInductions, page)

  const { name, passed, send } = filter

  const search = () => {
    const filterQuery = validInductions.filter((validInduction) =>
      validInduction.induction.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()) &&
      String(validInduction.sended).includes(send) &&
      String(validInduction.approved).includes(passed)
    )
    setPage(1)
    setStateInductions(filterQuery);
    setErrorFilter(true);
  }

  const clean = () => {
    setPage(1)
    setFilter(initialStateFilter);
    setHeaders(initialStateHeaders);
    setStateInductions(validInductions);
    setErrorFilter(false);
  }

  const orderBy = (order) => {
    const filterQuery = [...stateInductions].sort(order)
    setStateInductions(filterQuery);
  }

  const disabled = () => {
    return selectedInductions.length === 0
  }

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value
    })
  }

  useEffect(() => {
    getValidInductions(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setStateInductions(validInductions.sort((a, b) => a.induction.name.localeCompare(b.induction.name)));
  }, [validInductions])

  return (
    <div>
      {
        modal ?
          <SendInductionModal
            modal={modal}
            setModal={setModal}
            setSelectedItems={setSelectedInductions}
            selectedItems={selectedInductions}
            getCallback={() => getValidInductions(id)}
          />
          :
          null
      }
      <div className='mb-3'>
        <h4>{t('visits.validInductions')}</h4>
      </div>
      <ValidInductionsFilter filter={filter} handleChangeFilter={handleChangeFilter} search={search} clean={clean} />
      <hr className='bg-dark' />
      {
        isFetching ?
          <div className='d-flex justify-content-center'>
            <Spinner size={'xxl'} />
          </div>
          :
          stateInductions.length > 0 ?
            <>
              <ValidInductionsTable
                inductions={currentInductions}
                selectedInductions={selectedInductions}
                setSelectedInductions={setSelectedInductions}
                orderBy={orderBy}
                headers={headers}
                setHeaders={setHeaders}
                getCallback={() => getValidInductions(id)}
              />
              <div className='d-flex align-items-baseline'>
                {selectedInductions.length > 0 ? <Button onClick={() => setSelectedInductions([])} style={{ marginRight: '-150px' }}>{t('common.clean_selection')}</Button> : null}
                <div className="mx-auto">
                  <LocalPaginationCustom page={page} totalItems={stateInductions.length} setPage={setPage} />
                </div>
              </div>
            </>
            :
            stateInductions.length === 0 && errorFilter && !error ?
              <div className='d-flex justify-content-center'>{t('common.error_filter')}</div>
              :
              stateInductions.length === 0 && !errorFilter && !error && !isFetching ?
                <div className='d-flex justify-content-center'>{t('common.not_loaded')}</div>
                :
                null
      }
      <SendInducctionOptions disabled={() => disabled()} setModal={setModal} cameBack={'/visits'} />
      {error ? <CustomAlert text={message} color={'danger'} /> : null}
    </div>
  )
}
