import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Label } from 'reactstrap'
import { getTenantByKey } from '../../helpers/helpers';

export const ContractorDetailFilter = ({ clean, search, handleChange, filter }) => {
    const { t } = useTranslation();

    const { contractor_name, contractor_code } = filter;

    const onKeyPress = (e) => {
        if ((e.key === 'Enter')) {
            search();
        }
    };

    return (
        <div className='d-flex gap-5 flex-wrap'>

            <div>
                <Label htmlFor='name'>{t('contractor.contractor_name')}</Label>
                <Input
                    type='text'
                    name='contractor_name'
                    onChange={handleChange}
                    value={contractor_name}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div>
                <Label htmlFor='contractor_code'>{getTenantByKey('contractor_doc')}</Label>
                <Input
                    type='text'
                    name='contractor_code'
                    onChange={handleChange}
                    value={contractor_code}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='d-flex align-items-end gap-3 flex-wrap'>
                <Button
                    onClick={clean}
                    className="btn-secondary"
                >
                    <i className="fa-solid fa-eraser"></i>
                </Button>
                <Button
                    onClick={search}
                    className="btn-secondary"
                >
                    <i className="fa-solid fa-magnifying-glass" ></i>
                </Button>
            </div>
        </div>
    )
}
