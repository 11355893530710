import React, { Fragment } from 'react'
import { isUnique } from '../../helpers/helpers'
import { Navigate } from 'react-router-dom';

export const UniqueRoutes = ({ children }) => {
    return (
        isUnique() ?
            <Fragment>
                {children}
            </Fragment>
            :
            <Navigate to="/visits" replace />
    )
}
