import React from 'react'
import { Button } from 'reactstrap'

export const ArrowPagination = ({ pageNumber, totalPages, setPageNumber, limit, children }) => {
    return (
        <div className='d-flex gap-3 align-items-center'>
            {
                pageNumber === limit ?
                    <div style={{ width: '40px' }}></div>
                    :
                    <Button onClick={() => setPageNumber(pageNumber - 1)}>
                        <i type='button' className="fa-solid fa-arrow-left"></i>
                    </Button>
            }
            <div className='w-100'>
                {children}
            </div>
            {
                pageNumber === totalPages ?
                    <div style={{ width: '40px' }}></div>
                    :
                    <Button onClick={() => setPageNumber(pageNumber + 1)}>
                        <i type='button' className="fa-solid fa-arrow-right"></i>
                    </Button>
            }
        </div>
    )
}
