import React, { useContext } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Label, Spinner, Badge } from 'reactstrap'
import QuestionaryContext from '../../context/Questionary/QuestionaryContext';
import { ArrowPagination } from '../ArrowPagination/ArrowPagination';
import { CustomAlert } from '../CustomAlert/CustomAlert';

export const QuestionaryVersionModal = ({ setModal, modal, code }) => {

    const { t } = useTranslation();

    const { getQuestionaryVersions, clearQuestionaryVersions, questionaryState } = useContext(QuestionaryContext);

    const { error, message, versionsError, questionnairesVersions } = questionaryState;

    const [page, setPage] = useState(0)

    const toggle = () => {
        setModal(!modal);
        setPage(0)
        clearQuestionaryVersions();
    }

    useEffect(() => {
        if (code && modal) getQuestionaryVersions(code)
        if (questionnairesVersions.length > 0 && modal) setPage((questionnairesVersions.length - 1))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, questionnairesVersions.length, modal])

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} size={'xl'}>
                <ModalHeader toggle={toggle}>
                    {
                        questionnairesVersions.length > 0 ?
                            <div className='d-flex align-items-center gap-2'>
                                <ArrowPagination pageNumber={page} totalPages={(questionnairesVersions.length - 1)} setPageNumber={setPage} limit={0} >
                                    {questionnairesVersions[page].name} <small>(<small>v</small>{questionnairesVersions[page].version}.0)</small>
                                </ArrowPagination>
                            </div >
                            :
                            <Spinner size={'xxl'} />
                    }
                </ModalHeader>
                {
                    questionnairesVersions.length > 0 ?
                        <>
                            <ModalBody>

                                <div className="d-flex justify-content-around pb-3" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
                                    <div>
                                        <Label htmlFor='max_score'>{t('questionary.max_score')}: </Label> <span>{questionnairesVersions[page].max_score}</span>
                                    </div>
                                    <div>
                                        <Label htmlFor='min_score'>{t('questionary.min_score')}: </Label> <span>{questionnairesVersions[page].min_score}</span>
                                    </div>
                                </div>

                                {questionnairesVersions[page].questions.map((question) => (
                                    <div className="card mb-5" key={question._id}>
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between align-items-baseline">
                                                <div>
                                                    <Label className='fw-bold'>{question.question}</Label>
                                                </div>
                                                <div className='fs-4'>
                                                    <Badge color={'success'}>{question.score}</Badge>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="list-group list-group-flush">
                                            {
                                                question.answers.map((answer) => (
                                                    <li
                                                        key={answer._id}
                                                        className={answer.correct_answer ? 'list-group-item d-flex justify-content-between bg-success text-light' : 'list-group-item d-flex justify-content-between'}
                                                    >
                                                        {answer.answer}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                ))}
                                <div>
                                    <span className='fw-bold'>{t('common.name_date')}: </span>{questionnairesVersions[page].created_at.slice(0, 10).split('-').reverse().join('-')}
                                </div>
                                <div>
                                    <span className='fw-bold'>{t('common.active')}: </span><span>{questionnairesVersions[page].active ? t('common.yes') : t('common.no')}</span>
                                </div>
                            </ModalBody>
                        </>
                        :
                        <div className='d-flex justify-content-center'>
                            <Spinner size={'xxl'} />
                        </div>
                }
                <ModalFooter>
                    <Button onClick={toggle}>{t('common.close')}</Button>
                </ModalFooter>
                {versionsError || error ? <CustomAlert text={message} color={'danger'} /> : null}
            </Modal>
        </div>
    )
}
