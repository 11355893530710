import React, { Fragment } from 'react'
import { isIntegrated } from '../../helpers/helpers';
import { Navigate } from 'react-router-dom';

export const IntegratedRoutes = ({ children }) => {
    return (
        isIntegrated() ?
            <Fragment>
                {children}
            </Fragment>
            :
            <Navigate to="/visits" replace />
    )
}
