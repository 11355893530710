import { Navigate, Route, Routes } from "react-router-dom"
import { Exam } from "../containers/Exam/Exam"
import { ExamResult } from "../components/Exam/ExamResult"
import { PublicRoute } from "./PublicRoute"

export const ExamRoutes = () => {
    return (
        <Routes>

            <Route
                path=":id/employee/:employeeId"
                element={
                    <PublicRoute>
                        <Exam />
                    </PublicRoute>
                }
            />

            <Route
                path=":id/visit/:visitId"
                element={
                    <PublicRoute>
                        <Exam />
                    </PublicRoute>
                }
            />

            <Route
                path="result/:id/visit/:visitId"
                element={<ExamResult />}
            />

            <Route
                path="result/:id/employee/:employeeId"
                element={<ExamResult />}
            />

            <Route
                path="*"
                element={<Navigate to={'/login'} replace />}
            />

        </Routes>
    )
}
