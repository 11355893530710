import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap'
import { DeleteModal } from '../DeleteModal/DeleteModal';
import { QuestionaryList } from './QuestionaryList'
import { QuestionaryVersionModal } from './QuestionaryVersionModal';

export const QuestionaryTable = ({ questionnaires, getCallback }) => {
    const { t } = useTranslation();
    const [modalDelete, setModalDelete] = useState(false);

    const [modalVersion, setModalVersion] = useState(false);

    const [isLastVersion, setIsLastVersion] = useState(false)

    const [id, setId] = useState(null);

    const handleModal = (id, isVersion, isLastVersion) => {
        isVersion ? setModalVersion(true) : setModalDelete(true);
        setIsLastVersion(isLastVersion)
        setId(id);
    }


    return (
        <Fragment>
            <DeleteModal modal={modalDelete} setModal={setModalDelete} id={id} getCallback={getCallback} isLastVersion={isLastVersion} />
            <QuestionaryVersionModal modal={modalVersion} setModal={setModalVersion} code={id} />
            <div style={{ overflowX: 'auto' }}>
                <Table hover>
                    <thead style={{ background: '#e0e3e6' }}>
                        <tr>
                            <th>
                                {t('questionary.questionary')}
                            </th>
                            <th>
                                {t('questionary.version')}
                            </th>
                            <th>
                                {t('common.active')}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {questionnaires.map((questionary) => (
                            <QuestionaryList
                                key={questionary._id}
                                questionary={questionary}
                                handleModal={handleModal}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    )
}
