import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { ContractorFilter } from '../../components/Contractor/ContractorFilter';
import { ContractorTable } from '../../components/Contractor/ContractorTable';
import { CustomAlert } from '../../components/CustomAlert/CustomAlert';
import { CustomEditor } from '../../components/CustomEditor/CustomEditor';
import { PaginationCustom } from '../../components/PaginationCustom/PaginationCustom';
import { SendInducctionOptions } from '../../components/SendInduction/SendInducctionOptions';
import { SendInductionModal } from '../../components/SendInduction/SendInductionModal';
import { ContractorContext } from '../../context/Contractor/ContractorContext';

export const Contractor = () => {

    const { contractorState, getContrators, changePageContractor } = useContext(ContractorContext);
    const { contractors, error, isFetching, message, totalPages, visualizationId, changingPage } = contractorState;

    const initialStateFilter = {
        contractor_name: '',
        contractor_code: '',
        contractor_classification_id: '',
        business_type_code: '',
        business_type_opening_code: ''
    }

    const [selectedContractors, setSelectedContractors] = useState([]);

    const [filter, setFilter] = useState(initialStateFilter);

    const [messageToSend, setMessageToSend] = useState('');

    const [errorFilter, setErrorFilter] = useState(false);

    const [page, setPage] = useState(1);

    const [modal, setModal] = useState(false);

    const { id } = useParams();

    const { t } = useTranslation();

    const { contractor_name, contractor_code, contractor_classification_id, business_type_code, business_type_opening_code } = filter;

    const search = () => {
        changePageContractor();
        setPage(1)
        getContrators(id, 1, contractor_name, contractor_code, contractor_classification_id, business_type_code, business_type_opening_code);
        setErrorFilter(true);
    }

    const clean = () => {
        changePageContractor();
        setPage(1)
        setFilter(initialStateFilter);
        getContrators(id, 1, '', '', '', '', '');
        setErrorFilter(false);
    }

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilter({
            ...filter,
            [name]: value
        })
    }

    const handleChangeMessage = (e) => {
        const { value } = e.target;
        setMessageToSend(value)
    }

    const disabled = () => {
        return selectedContractors.length === 0 || !!!messageToSend.trim()
    }

    useEffect(() => {
        getContrators(id, page, contractor_name, contractor_code, contractor_classification_id, business_type_code, business_type_opening_code);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div>
            {
                modal ?
                    <SendInductionModal
                        modal={modal}
                        setModal={setModal}
                        setSelectedItems={setSelectedContractors}
                        selectedItems={selectedContractors}
                        getCallback={clean}
                        messageToSend={messageToSend}
                    />
                    :
                    null
            }
            <div>
                <div className='mb-3' style={{ marginTop: '-30px' }}>
                    <CustomEditor
                        name={'messageToSend'}
                        value={messageToSend}
                        handleChange={handleChangeMessage}
                        placeholder={t('contractor.message_to_contractors')}
                    />
                </div>
                <h4>{t('contractor.list_of_contractors')}</h4>
            </div>
            <ContractorFilter
                handleChangeFilter={handleChangeFilter}
                search={search}
                clean={clean}
                filter={filter}
            />
            <hr className='bg-dark' />
            {
                isFetching ?
                    <div className='d-flex justify-content-center'>
                        <Spinner size={'xxl'} />
                    </div>
                    :
                    contractors.length > 0 ?
                        <>
                            {
                                changingPage ?
                                    <div className='d-flex justify-content-center mt-5 mb-5 pt-5 pb-5'>
                                        <Spinner size={'xxl'} />
                                    </div>
                                    :
                                    <ContractorTable
                                        contractors={contractors}
                                        selectedContractors={selectedContractors}
                                        setSelectedContractors={setSelectedContractors}
                                        getCallback={clean}
                                        visualizationId={visualizationId}
                                    />
                            }
                            <div className='d-flex align-items-baseline'>
                                {selectedContractors.length > 0 ? <Button onClick={() => setSelectedContractors([])} style={{ marginRight: '-150px' }}>{t('common.clean_selection')}</Button> : null}
                                <div className="mx-auto">
                                    <PaginationCustom totalPages={totalPages} paginate={setPage} currentPage={page} changePage={changePageContractor} />
                                </div>
                            </div>
                        </>
                        :
                        contractors.length === 0 && errorFilter && !error ?
                            <div className='d-flex justify-content-center'>{t('common.error_filter')}</div>
                            :
                            null
            }
            <SendInducctionOptions setModal={setModal} disabled={() => disabled()} cameBack={'/induction'} tooltipMessage={t('contractor.message_to_contractors')} />
            {error ? <CustomAlert text={message} color={'danger'} /> : null}
        </div>
    )
}
