import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { examGet, examGetForVisit, inductionSeen, inductionSeenForVisit, questionaryPost, questionaryPostForVisit } from '../../services/examService'
import ExamContext from './ExamContext'
import { ExamReducer } from './ExamReducer'

export const ExamState = ({ children }) => {
    const initialState = {
        exam: null,
        isFetching: true,
        changingPage: false,
        sending: false,
        error: false,
        message: '',
        alert: false,
    }

    const { t } = useTranslation();

    const [state, dispatch] = useReducer(ExamReducer, initialState);

    const navigate = useNavigate();

    const getExam = async (inductionId, employeeId) => {
        try {
            const response = await examGet(inductionId, employeeId);
            if (response.ok) {
                dispatch({
                    type: 'GET_EXAM',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ERROR_EXAM',
                    payload: response.data.status === 404 ? t('questionary.not_found') : t('common.error_server')
                })
            }
        } catch (e) {
            console.log(e)
            dispatch({
                type: 'ERROR_EXAM',
            })
        }
    }

    const postQuestionary = async (questionaryValues, visualizationId) => {
        try {
            const response = await questionaryPost(questionaryValues, visualizationId);
            if (response.ok) {
                dispatch({
                    type: 'SEEN_INDUCTION',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ERROR_EXAM',
                    payload: t('common.error_server')
                })
            }
        } catch (e) {
            console.log(e)
            dispatch({
                type: 'ERROR_EXAM',
            })
        }
    }

    const seenInduction = async (inductionValues, visualizationId, questionaryValues) => {
        try {
            dispatch({
                type: 'SENDING',
            })
            const response = await inductionSeen(inductionValues, visualizationId);
            if (response.ok) {
                if (questionaryValues !== undefined) {
                    postQuestionary(questionaryValues, visualizationId)
                } else {
                    getExam(state.exam.induction._id, inductionValues.employee)
                }
                navigate(`/exam/result/${state.exam.induction._id}/employee/${inductionValues.employee}`);
            } else {
                dispatch({
                    type: 'ERROR_EXAM',
                    payload: t('common.error_server')
                })
            }
        } catch (e) {
            console.log(e)
            dispatch({
                type: 'ERROR_EXAM',
            })
        }
    }

    const getExamForVisit = async (inductionId, visitId) => {
        try {
            const response = await examGetForVisit(inductionId, visitId);
            if (response.ok) {
                dispatch({
                    type: 'GET_EXAM',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ERROR_EXAM',
                    payload: response.data.status === 404 ? t('questionary.not_found') : t('common.error_server')
                })
            }
        } catch (e) {
            console.log(e)
            dispatch({
                type: 'ERROR_EXAM',
            })
        }
    }

    const postQuestionaryForVisit = async (questionaryValues) => {
        try {
            const response = await questionaryPostForVisit(questionaryValues);
            if (response.ok) {
                dispatch({
                    type: 'SEEN_INDUCTION',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ERROR_EXAM',
                    payload: t('common.error_server')
                })
            }
        } catch (e) {
            console.log(e)
            dispatch({
                type: 'ERROR_EXAM',
            })
        }
    }

    const seenInductionForVisit = async (inductionValues, questionaryValues) => {
        try {
            dispatch({
                type: 'SENDING',
            })
            const response = await inductionSeenForVisit(inductionValues);
            if (response.ok) {
                if (questionaryValues !== undefined) {
                    postQuestionaryForVisit(questionaryValues)
                } else {
                    getExamForVisit(inductionValues.induction, inductionValues.visit)
                }
                navigate(`/exam/result/${inductionValues.induction}/visit/${inductionValues.visit}`);
            } else {
                dispatch({
                    type: 'ERROR_EXAM',
                    payload: t('common.error_server')
                })
            }
        } catch (e) {
            console.log(e)
            dispatch({
                type: 'ERROR_EXAM',
            })
        }
    }

    return (
        <ExamContext.Provider value={{
            examState: state,
            getExam,
            seenInduction,
            postQuestionary,
            getExamForVisit,
            seenInductionForVisit
        }}>
            {children}
        </ExamContext.Provider>
    )
}
