export const GET_INDUCTIONS = 'GET_INDUCTIONS';
export const GET_INDUCTION = 'GET_INDUCTION';
export const CREATE_INDUCTION = 'CREATE_INDUCTION';
export const UPDATE_INDUCTION = 'UPDATE_INDUCTION';
export const DELETE_INDUCTION = 'DELETE_INDUCTION';
export const DELETE_INDUCTION_ERROR = 'DELETE_INDUCTION_ERROR';
export const UPDATE_INDUCTION_STATUS = 'UPDATE_INDUCTION_STATUS';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const UNATORIZED = 'UNATORIZED'
export const ERROR_INDUCTIONS = 'ERROR_INDUCTIONS';
export const GET_VALID_INDUCTIONS = 'GET_VALID_INDUCTIONS';
export const GET_URL = 'GET_URL';
export const DELETE_URL = 'DELETE_URL';
export const SENDING = 'SENDING'
export const UPDATE_INDUCTION_STATUS_ERROR = 'UPDATE_INDUCTION_STATUS_ERROR'
export const GET_STATUS = 'GET_STATUS'
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const INPUT_ERROR = 'INPUT_ERROR';