import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

export const EmployeeList = ({
    employee,
    handleEmployeeSelected,
    isAdded,
    handleModal,
    canAdd,
}) => {

    const { t } = useTranslation();

    const {
        active,
        approved,
        assignment_date,
        business_type_openings,
        employee_classifications,
        employee_code,
        employee_contracts,
        employee_id,
        employee_full_name,
        exam,
        viewed,
    } = employee;

    const currentDate = new Date();

    const added = isAdded(employee_id)

    return (
        <tr>
            <td>
                {
                    canAdd ?
                        <Input
                            type='checkbox'
                            onChange={(e) => handleEmployeeSelected(e, employee)}
                            checked={added}
                        />
                        :
                        null
                }
            </td>
            <td><span>{employee_full_name}</span> <small>({employee_code})</small></td>
            <td>
                {
                    employee_classifications.length > 0 ?
                        employee_classifications.map((employee_classification) => (
                            <Fragment key={employee_classification.code}>
                                <span>{employee_classification.description}</span>
                                <br />
                            </Fragment>
                        ))
                        :
                        '-'
                }
            </td>
            <td>
                {
                    business_type_openings.length > 0 ?
                        business_type_openings.map((businessTypeOpenings) => (
                            <Fragment key={businessTypeOpenings.business_type_opening_code}>
                                <span>{businessTypeOpenings.business_type_opening_description}</span>
                                <br />
                            </Fragment>
                        ))
                        :
                        '-'
                }
            </td>
            <td>
                {
                    employee_contracts.length > 0 ?
                        employee_contracts.map((employee_contract) => (
                            <Fragment key={employee_contract.code}>
                                <span>{employee_contract.description}</span>
                                <br />
                            </Fragment>
                        ))
                        :
                        '-'
                }
            </td>
            <td>{assignment_date ? assignment_date.slice(0, 10).split('-').reverse().join('-') : t('common.no')}</td>
            <td>
                {viewed ? t('common.yes') : t('common.no')}
                {
                    employee.date_to && (+currentDate > +new Date(employee.date_to)) ?
                        <>
                            <CustomTooltip
                                placement={"top"}
                                target={'isExpired'}
                                text={t('inductions.induction_expired')}
                            />
                            <i class="fa-solid fa-circle-exclamation ps-1 text-danger" id='isExpired'></i>
                        </>
                        :
                        null
                }
            </td>
            <td>
                {(exam && approved) ? t('common.yes') : (exam && !approved) ? t('common.no') : '-'}
            </td>
            <td>
                {
                    (exam && approved) || (!exam && viewed) || !assignment_date ?
                        null
                        :
                        <>
                            <CustomTooltip
                                placement={"top"}
                                target={active ? "revoke" : "grant"}
                                text={active ? t('common.expire_induction') : t('common.reset_induction')}
                            />
                            <i
                                className={active ? "fa-solid fa-circle-xmark me-3 link-danger" : "fa-solid fa-recycle me-3 link-success"}
                                type='button'
                                id={active ? 'revoke' : "grant"}
                                onClick={() => handleModal(!active, employee_id)}
                            >
                            </i>
                        </>
                }
            </td>
        </tr>
    )
}
