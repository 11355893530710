import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

export const QuestionCard = ({ question, deleteQuestion, addNewAnswer, deleteAnswer, handleChange, i, NumberOfQuestions, setCorrect }) => {
    const { t } = useTranslation();

    return (
        <div className='d-flex align-items-baseline gap-3' >
            <div className="card mb-5 w-100">
                <div className="card-header d-flex gap-3 align-items-center">
                    <Input type='text' style={{ width: '70%' }} value={question.question} placeholder={`${t('questionary.question_placeholder')} ${i + 1}`} name='question' onChange={(e) => handleChange(e, i)} />
                    <Input type='number' style={{ width: '30%' }} min={0} value={question.score} placeholder={t('questionary.score_placeholder')} name='score' onChange={(e) => handleChange(e, i)} />
                    <i type='button' id='addNewAnswer' onClick={addNewAnswer} className="fa-solid fa-circle-plus"></i>
                    <CustomTooltip
                        target={"addNewAnswer"}
                        text={t('questionary.add_answer')}
                        placement={"right"}
                    />
                </div>
                <ul className="list-group list-group-flush">
                    {
                        question.answers.map((answer, j) => (
                            <li key={j} className="list-group-item d-flex align-items-center gap-2">
                                <CustomTooltip
                                    target={"correct_answer"}
                                    text={t('questionary.is_correct')}
                                    placement={"left"}
                                />
                                <Input type='radio' id='correct_answer' name={`correct_answer(${i}-${j})`} checked={answer.correct_answer === true} onChange={() => setCorrect(i, j)} />
                                <Input type='text' value={answer.answer} name='answer' placeholder={`${t('questionary.answer_placeholder')} ${j + 1}`} onChange={(e) => handleChange(e, i, j)} />
                                {
                                    question.answers.length > 3 ?
                                        <>
                                            <i type='button' id='deleteAnswer' onClick={() => deleteAnswer(i, j)} className="fa-solid fa-circle-minus"></i>
                                            <CustomTooltip
                                                target={"deleteAnswer"}
                                                text={t('questionary.delete_answer')}
                                                placement={"right"}
                                            />
                                        </>
                                        :
                                        null
                                }
                            </li>
                        ))
                    }
                </ul>
            </div>
            {
                NumberOfQuestions > 1 ?
                    <div>
                        <i type='button' id='deleteQuestion' onClick={deleteQuestion} className="fa-solid fa-circle-minus"></i>
                        <CustomTooltip
                            target={"deleteQuestion"}
                            text={t('questionary.delete_question')}
                            placement={"right"}
                        />
                    </div>
                    : null
            }
        </div>
    )
}
