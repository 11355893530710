import React from 'react'
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip'
import { Link, useParams } from 'react-router-dom';

export const EmployeeDetailList = ({ employee, handleModalQuestionary }) => {

    const { id: instanceId } = useParams()

    const { t } = useTranslation();

    const employeeExam = () => {
        let questionaryCopy = { ...employee.questionary }
        questionaryCopy.questions = employee.exam.questionary
        questionaryCopy.note = employee.exam.note
        questionaryCopy.employee = employee
        return questionaryCopy
    }

    return (
        <tr>
            <td>{employee.employee_name} <small>({employee.employee_code})</small></td>
            <td>{employee.employee_viewed ? t('common.yes') : t('common.no')}</td>
            <td>{(employee.exam && employee.employee_approved) ? t('common.yes') : (employee.exam && employee.employee_approved) ? t('common.no') : '-'}</td>
            <td>
                {
                    employee.exam ?
                        `${employee.exam.note}/${employee.questionary.max_score}` :
                        '-'
                }
            </td>
            <td>
                {
                    employee.exam
                        ?
                        employee.exam.attempts
                        :
                        '-'
                }
            </td>
            <td>{employee.date_from ? employee.date_from.slice(0, 10).split('-').reverse().join('-') : '-'}</td>
            <td>{employee.date_to ? employee.date_to.slice(0, 10).split('-').reverse().join('-') : '-'}</td>
            <td>
                <div className="d-flex gap-3">
                    <div>
                        {(employee.exam) && (
                            <>
                                <CustomTooltip
                                    placement={"top"}
                                    target={`exam-detail-${employee.employee_code}`}
                                    text={t('employee.view_exam')}
                                />
                                <i
                                    className="fa-solid fa-clipboard-list"
                                    id={`exam-detail-${employee.employee_code}`}
                                    type='button'
                                    onClick={() => handleModalQuestionary(employeeExam())}
                                >
                                </i>
                            </>
                        )}
                    </div>
                    <div>
                        {(employee.exam && employee.employee_approved) && (
                            <>
                                <CustomTooltip
                                    placement={"top"}
                                    target={`certificate-detail-${employee.employee_code}`}
                                    text={t('employee.view_certificate')}
                                />
                                <Link
                                    id={`certificate-detail-${employee.employee_code}`}
                                    target='_blank'
                                    to={`/exam/result/${instanceId}/employee/${employee.employee_id}`}
                                >
                                    <i className="fa-solid fa-clipboard-check"></i>
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </td>
        </tr >
    )
}
