export const italian = {
  translation: {
    language: 'IT',
    "common": {
      "name": "Nome",
      "user_name": "Utente",
      "firstName": "Nome",
      "lastName": "Cognome",
      "fullName": "Nome e cognome",
      "profile": "Profilo",
      "generic": "Generico",
      "active": "Attivo",
      "genericProfile": "Profilo generico",
      "area": "La zona",
      "rol": " Funzione",
      "dni": "ID",
      "company": "Affare",
      "observations": "Altri dati",
      "description": "Descrizione",
      "requiredFields": "Campi richiesti",
      "status": "Condizione",
      "enabled": "Abilitato",
      "notEnabled": "Non abilitato",
      "code": "Codice",
      "created": "Registrato da",
      "choose": "Selezionare",
      "all": "Tutto",
      "yes": "sì",
      "no": "No",
      "cancel": "Annulla",
      "save": "Salva",
      "confirm": "Confermare",
      "search": "Ricerca",
      "confirmDelete": "Sei sicuro di voler eliminare il record?",
      "delete": "Rimuovere",
      "alertDelete": "Record cancellato",
      "error_token": "La sessione è scaduta, effettua nuovamente il login.",
      "error_server": "Si è verificato un errore di connessione al server, riprova più tardi",
      "error_tenant": "Istanza non trovata",
      "error_relations": "Impossibile eliminare il record perché contiene relazioni",
      "not_loaded": "Nessun record caricato",
      "error_filter": "Nessun risultato trovato per la ricerca",
      "alert_1": "Registrazione registrata con successo ",
      "alert_2": "Registro di sistema modificato con successo ",
      "close": "Chiudere",
      "send_induction": "Inviare",
      "view_detail": "Vedi dettaglio",
      "expire_induction": "Scade",
      "reset_induction": "Ristabilire",
      "action_edit": "Modificare",
      "action_delete": "Rimuovere",
      "view_versions": "Versioni",
      "name_date": "Data",
      "came_back": "Ritorno",
      "end": "Per finalizzare",
      "clean_selection": "Cancella selezione",
      "suitable": "Adatto",
      "no_suitable": "Inadatto",
      "earring": "Orecchino"
    },
    "login": {
      "user_name": "Utente",
      "password": "Parola d'ordine",
      "login": "Entrare dentro",
      "forgotPassword": "Hai dimenticato la password?",
      "error_1": "Credenziali errate!"
    },
    "forgotPassword": {
      "title": "Recupera la password",
      "changePassword": "Cambia la password",
      "recover": "Recuperare",
      "confirmPassword": "Conferma password",
      "mailSend": "Ti inviamo un'e-mail con la tua nuova password",
      "passwordChanged": "Password modificata",
      "error_1": "L'utente inserito non esiste"
    },
    layout: {
      "management": {
        "management": "Gestione",
        "inductions": "induzioni",
        "classifications": "Classificazioni",
        "users": "Utenti"
      },
      "visits": {
        "visits": "visite",
        "management": "Gestisci le visite",
        "newVisits": "nuova visita"
      },
      "user": {
        "changePassword": "Cambia la password",
        "loguot": "cancella la sottoscrizione"
      }
    },
    "user": {
      "title": "Cerca - Utenti",
      "new": "Nuovo utente",
      "inputError": "Il nome utente è già registrato",
      "register": "Nuovo utente",
      "edit": "Modifica utente"
    },
    "classifications": {
      "title": "Classificazioni",
      "new": "Nuovo",
      "inputError": "Il codice è già registrato",
      "register": "Nuovo",
      "edit": "Modificare"
    },
    "inductions": {
      "title": "Ricerca - Induzioni",
      "new": "nuova induzione",
      "inputError": "Il nome è già registrato",
      "register": "nuova induzione",
      "edit": "Modifica induzione",
      "since": "Data di entrata in vigore dal",
      "until": "Data di entrata in vigore fino al",
      "since_only": "Da",
      "until_only": "Fino a quando",
      "video": "Video/File",
      "sizeError": `Il file può pesare un massimo di`,
      "sending": "Salendo....",
      "seen": "Visto",
      "current": "Valido",
      "noCurrent": "Non valido",
      "finished": "Finito",
      "expired": "è scaduto",
      "futura": "futuro",
      "confirmExpired": "Sei sicuro di voler scadere l'induzione?",
      "confirmRecycle": "Sei sicuro di voler resettare l'induzione?",
      "expire": "Scade",
      "restore": "Ristabilire",
      "confirmState": "Lo stato è cambiato correttamente ",
      "download_file": "Download file",
      "confirm_read": "Conferma lettura",
      "error_relations": "Non è stato possibile eliminare il record perché ha relazioni con le visite",
      "date_error": {
        "from_error": "Deve essere maggiore o uguale alla data corrente",
        "to_error": "Deve essere maggiore della data di entrata in vigore da"
      },
      "view_file": "Vedi Induzione",
      "allow_download": "consentire il download",
      "activities_name": "Elemento",
      "employee_classifications": "Classificazione dei dipendenti",
      "associated_document": "Documento associato",
      "induction_validity": "Validità (giorni)",
      "induction_validity_only": "Validità",
      "induction_validity_only_days": "giorni",
      "video_percentage": " % del video per contrassegnarlo come visto",
      "induction_name": "Induzione",
      "format_error": "Sono consentiti solo file PDF o video",
      "no_questionary": "non dispone di un questionario",
      "n_page": "Pagina",
      "n_of": "di",
      "new_version": "La versione del questionario è stata aggiornata",
      "new_questionary_version": "C'è una nuova versione del quiz",
      "induction_expired": "induzione scaduta"
    },
    "visits": {
      "title": "Cerca - Visite",
      "new": "nuova visita",
      "register": "nuova visita",
      "edit": "Modifica Visita",
      "infoUser": "Informazioni utente",
      "mailStatus": "Condizione",
      "induccionStatus": "Stato di induzione",
      "validInductions": "Induzioni attuali",
      "error_relations": "Non è stato possibile eliminare la visita perché ha già interagito con il sistema",
      "inputError": "La visita è già registrata",
      "info_visit": "Dati visita",
      "mail_send": "Email inviata",
      "send_induction_message": {
        "start_part": "Sei sicuro di inviare a",
        "end_part": "le seguenti induzioni?"
      },
      "send_message_alert": "Induzioni inviate",
      "earring_to_assing": "Pendenza di assegnazione dell'induzione",
      "ask_assing_inductions": "Vuoi inviare induzioni a"
    },
    "questionary": {
      "title": "Ricerca - Questionari",
      "new": "Nuovi quiz",
      "register": "Nuovo Questionario",
      "edit": "Modifica questionario",
      "questionary": "Questionario",
      "version": "Versione",
      "name_questionary": "Nome del questionario",
      "max_score": "Miglior punteggio",
      "min_score": "Punteggio minimo",
      "new_question": "nuova domanda",
      "question_placeholder": "inserisci la domanda",
      "score_placeholder": "Inserisci il punteggio",
      "answer_placeholder": "Inserisci la risposta",
      "total_score_error": "La somma delle domande deve dare il punteggio massimo",
      "add_question": "Aggiungi domanda",
      "delete_question": "Elimina domanda",
      "add_answer": "Aggiungi risposta",
      "delete_answer": "Elimina risposta",
      "delete_questionary_error": "Il questionario viene utilizzato per un'induzione",
      "is_correct": "Risposta corretta",
      "questionary_plural": "Questionari",
      "add_one_question": "Crea 1 domanda",
      "add_two_question": "Crea 2 domande",
      "add_three_question": "Crea 3 domande",
      "add_five_question": "Crea 5 domande",
      "add_ten_question": "Crea 10 domande",
      "last_version_alert": "Sei sicuro di voler rimuovere l'ultima versione del quiz?",
      "choose_answer": "Seleziona una delle seguenti possibili risposte",
      "next_question": "Seguente",
      "prev_question": "Precedente",
      "full_screen": "A schermo intero",
      "congratulations": "Congratulazioni",
      "exam_pass_confirm": "Hai superato con successo il questionario ",
      "exam_pass_confirm_two": " corrispondente all'induzione ",
      "exam_pass_note": "Nota",
      "enabled_to_date": "È abilitato fino a",
      "print_exam": "Per stampare",
      "we_sorry": "Siamo spiacenti",
      "exam_reject_confirmation": "Non hai raggiunto il punteggio necessario per superare il quiz ",
      "try_again_question": "Vuoi provare ancora?",
      "try_again_action": "Riprova",
      "exam_pass_confirm_three": "Hai completato l'induzione",
      "exam_disabled_video_part_one": "Per terminare l'induzione è necessario visualizzare il",
      "exam_disabled_video_part_two": "del video",
      "exam_disabled_questionary": " e completare il questionario",
      "exam_disabled_pdf": "Per concludere l'induzione è necessario visualizzare il PDF completo",
      "not_found": "L'induzione non è disponibile o non esiste"
    },
    "contractor": {
      "id": "ID",
      "assigned_induction": "Assegnato",
      "contractor_classification": "Classificazione",
      "contractor_name": "Contraente",
      "send_induction_message": {
        "start_part": "Sei sicuro di inviare l'induzione",
        "end_part": "ai seguenti appaltatori?"
      },
      "send_message_alert": "L'induzione è stata inviata ai contraenti selezionati",
      "message_to_contractors": "Immettere un messaggio per gli appaltatori",
      "list_of_contractors": "Elenco degli appaltatori",
      "contractor_detail": "Particolare appaltatore",
      "assigned_employees": "Assegnato",
      "assigned_employees_view": "visto",
      "assigned_employees_passed": "Approvato",
      "total_exam": "Totale",
      "print_detail": "Dettagli di stampa",
      "employees_plural": "Dipendenti"
    },
    "employee": {
      "id": "ID",
      "assigned_induction": "Assegnato",
      "employee_classification": "Classificazione",
      "employee_name": "Dipendente",
      "send_induction_message": {
        "start_part": "Sei sicuro di inviare l'induzione",
        "end_part": "ai seguenti dipendenti?"
      },
      "send_message_alert": "L'induzione è stata inviata ai dipendenti selezionati",
      "contract_name": "Contrarre",
      "send": "Inviare",
      "list_of_employees": "Elenco dei dipendenti",
      "exam_passed": "Passato",
      "detail_employee": "Particolare del dipendente",
      "score_exam": "Punto",
      "attemps_exam": "No. Tentativi",
      "view_exam": "Vedi Esame",
      "view_certificate": 'Verifica certificato di approvazione',
      "employee_info": "Tutti i dipendenti vengono conteggiati senza tenere conto delle restrizioni degli utenti."
    }
  }
}