import React, { useContext, useEffect, useState } from 'react'
import { Container, Spinner } from 'reactstrap'
import { Resource } from '../../components/Resource/Resource'
import { UsersFilter, UsersTable } from '../../components/Users'
import { CustomAlert } from '../../components/CustomAlert/CustomAlert'
import { PaginationCustom } from '../../components/PaginationCustom/PaginationCustom'
import { useTranslation } from 'react-i18next'
import UsersContext from '../../context/Users/UsersContext'

export const Users = () => {

  const { usersState, usersGet, changePageUsers } = useContext(UsersContext);

  const { error, isFetching, totalPages, message, users, changingPage } = usersState;

  const { t } = useTranslation();

  const initialState = {
    user_name: '',
    first_name: '',
    last_name: '',
    is_generic: '',
    profile: '',
    active: '',
  }

  const [filter, setFilter] = useState(initialState);

  const [errorFilter, setErrorFilter] = useState(false);

  const [page, setPage] = useState(1);

  const { user_name, first_name, last_name, profile, active, is_generic } = filter;

  const handleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const search = () => {
    changePageUsers();
    usersGet(1, user_name, first_name, last_name, profile, active, is_generic);
    setErrorFilter(true);
  }

  const clean = () => {
    changePageUsers();
    setFilter(initialState);
    setPage(1)
    usersGet(1, '', '', '', '', '', '');
    setErrorFilter(false);
  }

  useEffect(() => {
    usersGet(page, user_name, first_name, last_name, profile, active, is_generic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
  return (
    <Container
      className='bg-white mt-5 pb-5 ps-5 pe-5 pt-5 rounded-3'>
      <Resource title={t('user.title')} resource={t('user.new')} newResource={'user'} />
      <hr className='bg-dark' />
      <UsersFilter handleChange={handleChange} search={search} clean={clean} filterValues={filter} />
      <hr className='bg-dark' />
      {
        isFetching ?
          <div className='d-flex justify-content-center'>
            <Spinner size={'xxl'} />
          </div>
          :
          users.length > 0 ?
            <>
              {
                changingPage ?
                  <div className='d-flex justify-content-center mt-5 mb-5 pt-5 pb-5'>
                    <Spinner size={'xxl'} />
                  </div>
                  :
                  <UsersTable users={users} usersGet={() => usersGet(page, user_name, first_name, last_name, profile, active, is_generic)} />
              }
              <>
                <PaginationCustom totalPages={totalPages} paginate={setPage} currentPage={page} changePage={changePageUsers}/>
              </>
            </>
            :
            users.length === 0 && errorFilter && !error && !!!isFetching ?
              <div className='d-flex justify-content-center'>{t('common.error_filter')}</div>
              :
              users.length === 0 && !errorFilter && !error && !!!isFetching ?
                <div className='d-flex justify-content-center'>{t('common.not_loaded')}</div>
                :
                null
      }
      {error ? <CustomAlert text={message} color={'danger'} /> : null}
    </Container>
  )
}
