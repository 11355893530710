import i18n from "i18next";
import { ERROR_DOCUMENTS, GET_DOCUMENTS, UNATORIZED } from "./types";

const DocumentReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_DOCUMENTS:
            return {
                ...state,
                documents: payload,
                isFetching: false,
                error: false,
                message: '',
                tokenError: false
            }
        case ERROR_DOCUMENTS:
            return {
                ...state,
                isFetching: false,
                error: true,
                message: i18n.t('common.error_server'),
            }
        case UNATORIZED:
            return {
                ...state,
                isFetching: false,
                error: true,
                message: i18n.t('common.error_token'),
                tokenError: true
            }
        default:
            return state
    }
}

export default DocumentReducer