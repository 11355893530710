import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoginContext from '../../context/Login/LoginContext';
import { getUserDataByKey, isAuthenticated } from '../../helpers/helpers';
import { CustomAlert } from '../CustomAlert/CustomAlert';

export const ForgotPassword = ({ modal, setModal }) => {

    const { passwordForgot, loginState, passwordChange } = useContext(LoginContext);

    const { passwordError, alert, message } = loginState

    const { t } = useTranslation();

    const auth = isAuthenticated();

    const id = getUserDataByKey('_id');

    const [userName, setUserName] = useState('');

    const [password, setPassword] = useState({ password: '', confirmPassword: '' });

    const disabled = auth ? !!!password.password.trim() || !!!password.confirmPassword.trim() || password.password !== password.confirmPassword : !userName.trim();

    const toggle = () => {
        setModal(!modal)
        setUserName('');
        setPassword({ password: '', confirmPassword: '' })
    };

    const handlePassword = (e) => {
        setPassword({
            ...password,
            [e.target.name]: e.target.value,
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (window.location.pathname === '/login') {
            passwordForgot(userName, toggle);
        } else {
            passwordChange(id, password.password, toggle);
        }
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className='mx-auto'>
                    {auth ? t('forgotPassword.changePassword') : t('forgotPassword.title')}
                </ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        {auth ?
                            <>
                                <Label htmlFor='password'>{t('login.password')}</Label>
                                <Input type='password' name='password' onChange={handlePassword} />
                                <Label htmlFor='confirmPassword'>{t('forgotPassword.confirmPassword')}</Label>
                                <Input type='password' name='confirmPassword' onChange={handlePassword} />
                            </>
                            :
                            <>
                                <Label htmlFor='userName'>{t('common.user_name')}</Label>
                                <Input type='text' name='userName' onChange={(e) => setUserName(e.target.value)} />
                            </>
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button type='submit' color="primary" disabled={disabled}>{auth ? t('common.confirm') : t('forgotPassword.recover')}</Button>
                        <Button onClick={toggle}>{t('common.cancel')}</Button>
                    </ModalFooter>
                    {/* {alert && !auth ? <CustomAlert color={'success'} text={t('forgotPassword.mailSend')} /> : null} */}
                    {alert ? <CustomAlert color={'success'} text={message} /> : null}
                    {passwordError ? <CustomAlert color={'danger'} text={message} /> : null}
                </Form>
            </Modal>
        </div>
    )
}
