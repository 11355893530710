import { ERROR_EXAM, GET_EXAM, SEEN_INDUCTION, SENDING } from "./types";

export const ExamReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_EXAM:
            return {
                ...state,
                exam: payload,
                isFetching: false,
                changingPage: false,
                sending: false,
                error: false,
                message: '',
                alert: false,
            }

        case ERROR_EXAM:
            return {
                ...state,
                isFetching: false,
                changingPage: false,
                sending: false,
                error: true,
                message: payload,
                alert: false,
            }

        case SENDING:
            return {
                ...state,
                sending: true
            }

        case SEEN_INDUCTION:
            return {
                ...state,
                exam: payload,
                isFetching: false,
                changingPage: false,
                sending: false,
                error: false,
                message: '',
                alert: false,
            }
        default:
            return state
    }
}
