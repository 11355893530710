import { customFetch } from "../helpers/customFetch";
import { URL, getConfig } from "../helpers/helpers";

export const createUser = async (values) => {
    try {
        const configs = getConfig('POST', values);
        const response = await customFetch(`${URL}users/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const getUsers = async (page, userName, firtName, lastName, profile, active, isGeneric) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}users/?page=${page}&user_name=${userName}&first_name=${firtName}&last_name=${lastName}&profile=${profile}&active=${active}&is_generic=${isGeneric}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const deleteUser = async (id) => {
    try {
        const configs = getConfig('DELETE');
        const response = await customFetch(`${URL}users/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const getUser = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}users/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const updateUser = async (id, values) => {
    try {
        const configs = getConfig('PUT', values);
        const response = await customFetch(`${URL}users/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const changePassword = async (id, newPassword) => {
    try {
        const configs = getConfig('PUT', { password: newPassword });
        const response = await customFetch(`${URL}users/change_password/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const forgotPassword = async (userName) => {
    try {
        var configs = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Basic ${btoa(`${process.env.REACT_APP_AUTH_USER}:${process.env.REACT_APP_AUTH_PASS}`)}`,
            },
            method: 'POST',
            body: JSON.stringify({ user_name: userName }),
        };
        const response = await customFetch(`${URL}users/forgot_password`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}