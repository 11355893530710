import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { CustomAlert } from '../../components/CustomAlert/CustomAlert';
import { EmployeeTable } from '../../components/Employee/EmployeeTable';
import { PaginationCustom } from '../../components/PaginationCustom/PaginationCustom';
import { SendInducctionOptions } from '../../components/SendInduction/SendInducctionOptions';
import { SendInductionModal } from '../../components/SendInduction/SendInductionModal';
import { EmployeeContext } from '../../context/Employee/EmployeeContext';
import { EmployeeFilter } from '../../components/Employee/EmployeeFilter';

export const Employee = () => {
  const { getEmployees, employeeState, changePageEmployee } = useContext(EmployeeContext);
  const { employees, error, isFetching, message, totalPages, visualizationId, visualizationMessage, changingPage } = employeeState;

  const initialStateFilter = {
    employee_name: '',
    employee_code: '',
    employee_classification_id: '',
    business_type_opening_code: '',
    contract_id: ''
  }

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [filter, setFilter] = useState(initialStateFilter);

  const [errorFilter, setErrorFilter] = useState(false);

  const [modal, setModal] = useState(false)

  const [page, setPage] = useState(1);

  const { id } = useParams();

  const { t } = useTranslation();

  const { employee_name, employee_code, employee_classification_id, business_type_opening_code, contract_id } = filter;

  const search = () => {
    changePageEmployee();
    setPage(1)
    getEmployees(id, 1, employee_name, employee_code, employee_classification_id, business_type_opening_code, contract_id);
    setErrorFilter(true);
  }

  const clean = () => {
    changePageEmployee();
    setPage(1)
    setFilter(initialStateFilter);
    getEmployees(id, 1, '', '', '', '', '');
    setErrorFilter(false);
  }

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value
    })
  }

  const disabled = () => {
    return selectedEmployees.length === 0
  }

  useEffect(() => {
    getEmployees(id, page, employee_name, employee_code, employee_classification_id, business_type_opening_code, contract_id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])


  return (
    <div>
      {
        modal ?
          <SendInductionModal
            modal={modal}
            setModal={setModal}
            setSelectedItems={setSelectedEmployees}
            selectedItems={selectedEmployees}
            getCallback={clean}
          />
          :
          null
      }
      <div className='mb-3' style={{ marginTop: '-35px' }}>
        <p dangerouslySetInnerHTML={{ __html: visualizationMessage ? visualizationMessage : '-' }}></p>
        <h3>{t('employee.list_of_employees')}</h3>
      </div>

      <EmployeeFilter
        handleChangeFilter={handleChangeFilter}
        search={search}
        clean={clean}
        filter={filter}
      />
      <hr className='bg-dark' />
      {
        isFetching ?
          <div className='d-flex justify-content-center'>
            <Spinner size={'xxl'} />
          </div>
          :
          employees.length > 0 ?
            <>
              {
                changingPage ?
                  <div className='d-flex justify-content-center mt-5 mb-5 pt-5 pb-5'>
                    <Spinner size={'xxl'} />
                  </div>
                  :
                  <EmployeeTable
                    employees={employees}
                    setSelectedEmployees={setSelectedEmployees}
                    selectedEmployees={selectedEmployees}
                    getCallback={clean}
                    visualizationId={visualizationId}
                  />
              }
              <div className='d-flex align-items-baseline'>
                {selectedEmployees.length > 0 ? <Button onClick={() => setSelectedEmployees([])} style={{ marginRight: '-150px' }}>{t('common.clean_selection')}</Button> : null}
                <div className="mx-auto">
                  <PaginationCustom totalPages={totalPages} paginate={setPage} currentPage={page} changePage={changePageEmployee} />
                </div>
              </div>
            </>
            :
            employees.length === 0 && errorFilter && !error ?
              <div className='d-flex justify-content-center'>{t('common.error_filter')}</div>
              :
              null
      }
      <SendInducctionOptions disabled={() => disabled()} setModal={setModal} cameBack={'/induction'} />
      {error ? <CustomAlert text={message} color={'danger'} /> : null}
    </div>
  )
}
