import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { ForgotPassword } from '../../components/ForgotPassword/ForgotPassword';
import { NavigationBar } from '../../components/NavigationBar/NavigationBar'
import { getTenantByKey, getUserDataByKey, setColor } from '../../helpers/helpers';

export const Main = ({ children }) => {

    const [modal, setModal] = useState(false);

    const minHeight = {
        minHeight: '85vh',
    };

    const userName = getUserDataByKey('first_name');

    useEffect(() => {
        setColor(getTenantByKey('theme'));
        document.title = getTenantByKey('app_name');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container className="themed-container p-0" fluid={true}>
            <ForgotPassword modal={modal} setModal={setModal} />
            <NavigationBar name={userName} setModal={setModal} />
            <Row className="p-0 m-0 d-flex justify-content-center align-content-center h-100">
                <Col style={minHeight}>{children}</Col>
            </Row>
        </Container>
    )
}
