import React from 'react'
import { useState } from 'react';
import { Table } from 'reactstrap'
import { EmployeeDetailList } from './EmployeeDetailList';
import { ExamModal } from './ExamModal';

export const EmployeeDetailTable = ({ employees, orderBy, headers, setHeaders }) => {

  const [questionary, setQuestionary] = useState(null);

  const [modal, setModal] = useState(false);

  const handleModalQuestionary = (questionary) => {
    setQuestionary(questionary);
    setModal(!modal)
  }

  const orderOpitons = {
    'employee_name': {
      asc: (a, b) => a.employee_name.localeCompare(b.employee_name),
      desc: (a, b) => b.employee_name.localeCompare(a.employee_name)
    },
    'employee_viewed': {
      asc: (a, b) => a.employee_viewed - b.employee_viewed,
      desc: (a, b) => b.employee_viewed - a.employee_viewed
    },
    'employee_approved': {
      asc: (a, b) => a.employee_approved - b.employee_approved,
      desc: (a, b) => b.employee_approved - a.employee_approved
    },
    'note': {
      asc: (a, b) => {
        if (a.exam && b.exam) {
          return a.exam.note - b.exam.note
        } else if (a.exam && !b.exam) {
          return 1
        } else if (!a.exam && b.exam) {
          return -1
        } else {
          return 0
        }
      },
      desc: (a, b) => {
        if (a.exam && b.exam) {
          return b.exam.note - a.exam.note
        } else if (a.exam && !b.exam) {
          return -1
        } else if (!a.exam && b.exam) {
          return 1
        } else {
          return 0
        }
      }
    },
    'attempts': {
      asc: (a, b) => {
        if (a.exam && b.exam) {
          return a.exam.attempts - b.exam.attempts
        } else if (a.exam && !b.exam) {
          return 1
        } else if (!a.exam && b.exam) {
          return -1
        } else {
          return 0
        }
      },
      desc: (a, b) => {
        if (a.exam && b.exam) {
          return b.exam.attempts - a.exam.attempts
        } else if (a.exam && !b.exam) {
          return -1
        } else if (!a.exam && b.exam) {
          return 1
        } else {
          return 0
        }
      }
    },
    'date_from': {
      asc: (a, b) => {
        if (a.date_from && b.date_from) {
          return new Date(a.date_from) - new Date(b.date_from)
        } else if (a.date_from && !b.date_from) {
          return 1
        } else if (!a.date_from && b.date_from) {
          return -1
        } else {
          return 0
        }
      },
      desc: (a, b) => {
        if (a.date_from && b.date_from) {
          return new Date(b.date_from) - new Date(a.date_from)
        } else if (a.date_from && !b.date_from) {
          return -1
        } else if (!a.date_from && b.date_from) {
          return 1
        } else {
          return 0
        }
      }
    },
    'date_to': {
      asc: (a, b) => {
        if (a.date_to && b.date_to) {
          return a.date_from - b.date_from
        } else if (a.date_to && !b.date_to) {
          return 1
        } else if (!a.date_to && b.date_to) {
          return -1
        } else {
          return 0
        }
      },
      desc: (a, b) => {
        if (a.date_to && b.date_to) {
          return b.date_from - a.date_from
        } else if (a.date_to && !b.date_to) {
          return -1
        } else if (!a.date_to && b.date_to) {
          return 1
        } else {
          return 0
        }
      }
    },
  }

  const handleSort = (key, order) => {
    const newData = headers.map((header) => (
      header.key === key
        ? { ...header, active: true, order: header.order === 'asc' ? 'desc' : 'asc' }
        : { ...header, active: false, order: 'asc' }
    ));
    orderBy(orderOpitons[key][order])
    setHeaders(newData);
  }

  return (
    <div>
      {modal ? <ExamModal modal={modal} setModal={setModal} questionary={questionary} /> : null}
      <div style={{ overflowX: 'auto' }}>
        <Table hover>
          <thead style={{ background: '#e0e3e6' }}>
            <tr>
              {
                headers.map((header, idx) => (
                  <th key={idx}>
                    <div className={`d-flex flex-row align-items-${header.order === 'asc' ? 'end' : 'center'} gap-2`} style={{ cursor: 'pointer' }} onClick={() => handleSort(header.key, header.order === 'asc' ? 'desc' : 'asc')}>
                      {header.label}
                      <i
                        className={`fas fa-sort-${header.order === 'asc' ? 'up' : 'down'} ${header.active ? 'text-primary' : ''}`}
                      >
                      </i>
                    </div>
                  </th>
                ))
              }
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              employees.map((employee, idx) => (
                <EmployeeDetailList
                  key={idx}
                  employee={employee}
                  handleModalQuestionary={handleModalQuestionary}
                />
              ))
            }
          </tbody>
        </Table>
      </div>
    </div>
  )
}
