import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { CustomAlert } from '../CustomAlert/CustomAlert';
import VisitContext from "../../context/Visit/VisitContext";
import ClassificationsContext from '../../context/Classifications/ClassificationsContext';
import UsersContext from '../../context/Users/UsersContext';
import InductionContext from '../../context/Induction/InductionContext';
import QuestionaryContext from '../../context/Questionary/QuestionaryContext';
import { useBasePath } from '../../customHooks/useBasePath';

export const DeleteModal = ({ setModal, modal, id, values, isLastVersion, getCallback }) => {
    const { deleteVisit, visitState } = useContext(VisitContext);
    const { deleteClassifications, classificationsState } = useContext(ClassificationsContext)
    const { userDelete, usersState } = useContext(UsersContext)
    const { questionaryState, deleteQuestionary } = useContext(QuestionaryContext)
    const { deleteInduction, inductionState } = useContext(InductionContext);

    const { t } = useTranslation();

    const location = useBasePath();

    const deleteOptions = {
        '/users': {
            delete: function () { userDelete(id, toggle, getCallback) },
            alert: usersState.alertDelete,
            error: usersState.deleteError,
            message: usersState.message
        },
        '/classifications': {
            delete: function () { deleteClassifications(id, toggle) },
            alert: classificationsState.alertDelete,
            error: classificationsState.deleteError,
            message: classificationsState.message
        },
        '/visits': {
            delete: function () { deleteVisit(id, toggle, getCallback) },
            alert: visitState.alertDelete,
            error: visitState.deleteError,
            message: visitState.message
        },
        '/induction': {
            delete: function () { deleteInduction(id, toggle, getCallback) },
            alert: inductionState.alertDelete,
            error: inductionState.deleteError,
            message: inductionState.message
        },
        '/questionary': {
            delete: function () { deleteQuestionary(id, toggle, getCallback) },
            alert: questionaryState.alertDelete,
            error: questionaryState.deleteError,
            message: questionaryState.message
        },
    }

    const toggle = () => {
        setModal(!modal)
    };

    const handleConfirm = () => {
        deleteOptions[location].delete();
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader>
                    {t('common.confirm')}
                </ModalHeader>
                <ModalBody>
                    {isLastVersion ? t('questionary.last_version_alert') : t('common.confirmDelete')}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleConfirm} color="primary">{t('common.delete')}</Button>
                    <Button onClick={toggle}>{t('common.cancel')}</Button>
                </ModalFooter>

                {deleteOptions[location].alert ? <CustomAlert text={t('common.alertDelete')} color={'success'} /> : null}

                {deleteOptions[location].error ? <CustomAlert text={deleteOptions[location].message} color={'danger'} /> : null}
            </Modal>
        </div>
    )
}
