import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Input, Label } from 'reactstrap'

export const ValidInductionsFilter = ({ handleChangeFilter, search, clean, filter }) => {
  const { t } = useTranslation();

  const { name, passed, send } = filter


  const onKeyPress = (e) => {
    if ((e.key === 'Enter')) {
      search();
    }
  };

  return (
    <div className='row'>
      <div className="col-md">
        <Label htmlFor='name'>{t('inductions.induction_name')}</Label>
        <Input
          type='text'
          name='name'
          value={name}
          onChange={handleChangeFilter}
          onKeyPress={onKeyPress}
        />
      </div>

      <div className="col-md">
        <Label htmlFor='passed'>{t('employee.exam_passed')}</Label>
        <select
          className='form-select'
          onChange={handleChangeFilter}
          name='passed'
          value={passed}
          onKeyPress={onKeyPress}
        >
          <option value="">{t('common.all')}</option>
          <option value="true">{t('common.yes')}</option>
          <option value="false">{t('common.no')}</option>
        </select>
      </div>

      <div className="col-md">
        <Label htmlFor='send'>{t('employee.send')}</Label>
        <select
          className='form-select'
          onChange={handleChangeFilter}
          name='send'
          value={send}
          onKeyPress={onKeyPress}
        >
          <option value="">{t('common.all')}</option>
          <option value="true">{t('common.yes')}</option>
          <option value="false">{t('common.no')}</option>
        </select>
      </div>

      <div className='col-md d-flex align-items-end gap-3 pt-4'>
        <Button
          onClick={clean}
          className="btn-secondary"
        >
          <i className="fa-solid fa-eraser"></i>
        </Button>
        <Button
          onClick={search}
          className="btn-secondary"
        >
          <i className="fa-solid fa-magnifying-glass" ></i>
        </Button>
      </div>

    </div>
  )
}
