import i18n from "i18next";
import { GET_USERS, GET_PROFILES, GET_USER, CREATE_USER, UPDATE_USER, DELETE_USER, UNATORIZED, ERROR_USERS, ERROR_USER_NAME, ERROR_PROFILES, DELETE_USER_ERROR, CLEAR_ALERTS, CHANGE_PAGE } from "./types";

const UsersReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case UNATORIZED:
            return {
                ...state,
                users: [],
                profiles: [],
                totalPages: null,
                userSelected: null,
                isFetching: false,
                sending: false,
                error: true,
                message: i18n.t('common.error_token'),
                inputError: false,
                alert: false,
            }

        case ERROR_PROFILES:
            return {
                ...state,
                profiles: [],
                isFetching: false,
                error: true,
                message: i18n.t('common.error_server'),
            }

        case ERROR_USERS:
            return {
                ...state,
                users: [],
                totalPages: null,
                isFetching: false,
                error: true,
                message: i18n.t('common.error_server'),
            }

        case GET_PROFILES:
            return {
                ...state,
                profiles: payload,
                isFetching: false,
                error: false,
            }

        case GET_USERS:
            return {
                ...state,
                users: payload.users,
                totalPages: payload.total_pages,
                userSelected: null,
                isFetching: false,
                changingPage: false,
                error: false,
                inputError: false,
                alert: false,
                sending: false,
                deleteError: false,
                alertDelete: false,
            }

        case CREATE_USER:
            return {
                ...state,
                isFetching: false,
                sending: true,
                error: false,
                message: i18n.t('common.alert_1'),
                inputError: false,
                alert: true,
            }

        case ERROR_USER_NAME:
            return {
                ...state,
                isFetching: false,
                sending: false,
                inputError: true,
            }

        case GET_USER:
            return {
                ...state,
                userSelected: payload,
                isFetching: false,
                error: false,
            }

        case UPDATE_USER:
            return {
                ...state,
                isFetching: false,
                sending: true,
                error: false,
                message: i18n.t('common.alert_2'),
                inputError: false,
                alert: true,
            }

        case DELETE_USER:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: true,
            }

        case DELETE_USER_ERROR:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                message: payload,
                inputError: false,
                alert: false,
                deleteError: true,
                alertDelete: false,
            }

        case CLEAR_ALERTS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
            }
        case CHANGE_PAGE: {
            return {
                ...state,
                changingPage: true,
            }
        }
        default:
            return state

    }
}

export default UsersReducer