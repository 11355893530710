import { ERROR_CONTRACTOR_CLASSIFICATION, GET_CONTRACTOR_CLASSIFICATION, UNATORIZED } from "./types";
import i18n from "i18next";

export const ContractorClassificationsReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CONTRACTOR_CLASSIFICATION:
            return {
                ...state,
                contractorClassifications: payload,
                isFetching: false,
                error: false,
                tokenError: false
            }
        case ERROR_CONTRACTOR_CLASSIFICATION:
            return {
                ...state,
                isFetching: false,
                error: true,
                message: i18n.t('common.error_server'),
            }
        case UNATORIZED:
            return {
                ...state,
                isFetching: false,
                error: true,
                message: i18n.t('common.error_token'),
                tokenError: true
            }
        default:
            return state
    }
}
