import React, { useContext, useEffect, useState } from 'react'
import logo from '../../assets/images/exactian-logo.png'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardText, Col, Container, Form, Input, Label, Row, Spinner } from 'reactstrap'
import { InductionsDownloadTable } from '../Induction/InductionsDownloadTable';
import { getTenantByKey } from '../../helpers/helpers'
import { CustomAlert } from '../CustomAlert/CustomAlert';
import TenantContext from '../../context/Tenant/TenantContext';
import VisitContext from '../../context/Visit/VisitContext';
import InductionContext from '../../context/Induction/InductionContext';
export const VisitFormDownload = () => {

    const { getTenant, tenant } = useContext(TenantContext);

    const { getVisit, visitState, upddateVisitFromVisit } = useContext(VisitContext);

    const { inductionState } = useContext(InductionContext);

    const { visitSelected, alert, error } = visitState;

    const { t } = useTranslation();

    const { id } = useParams();

    const clientLogo = getTenantByKey('logo');

    const version = getTenantByKey('version');

    const classificationsSingularName = getTenantByKey('singular_classification_name');

    const initialValuesVisits = {
        first_name: '',
        last_name: '',
        email: '',
        classification: null,
        user_created: null,
        document_number: '',
        company_name: '',
        observations: '',
        user_full_name: '',
        user_rol: '',
        user_area: ''
    }

    const [visit, setVisit] = useState(initialValuesVisits);

    const disabled = !!!visit.document_number.trim() || !!!visit.company_name.trim();

    const handleVisists = (e) => {
        setVisit({
            ...visit,
            [e.target.name]: e.target.value,
        });
    };

    const submit = (e) => {
        e.preventDefault();
        window.scroll(0, 0);
        upddateVisitFromVisit(id, visit);
    }

    useEffect(() => {
        getTenant();
        getVisit(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (visitSelected) setVisit(visitSelected)
    }, [visitSelected])

    return (
        <Container className="themed-container align-content-center h-100" fluid="md">
            <Row className="justify-content-center align-content-center h-100">
                <Col md="6" className="m-1 p-0 aign-content-center">
                    <Card body className="m-0 p-1">
                        <div style={{ height: '60px' }} className='bg-primary'>
                            {
                                tenant.isFetching && !tenant.error ?
                                    < Spinner color="light" className='d-flex mx-auto' />
                                    :
                                    <img src={tenant.error ? logo : clientLogo} className='d-flex mx-auto' alt="ford-logo" />
                            }
                        </div>
                        <CardBody>
                            {
                                tenant.error ?
                                    <CardText className="text-center">{t('common.error_tenant')}</CardText>
                                    :
                                    <>

                                        {error || inductionState.error ? <CustomAlert text={t('common.error_server')} color={'danger'} /> : null}
                                        {alert ? <CustomAlert text={t('common.alert_2')} color={'success'} /> : null}
                                        <h4 className='text-center mt-4'>{t('visits.validInductions')}</h4>
                                        <InductionsDownloadTable />
                                        <h4 className='text-center mt-4'>{t('visits.info_visit')}</h4>
                                        <hr />
                                        <div className="row g-3">
                                            <div className="col">
                                                <Label htmlFor='first_name'>{t('common.firstName')}: <span className='fw-bold'>{visit.first_name}</span></Label>
                                            </div>
                                            <div className="col">
                                                <Label htmlFor='last_name'>{t('common.lastName')}: <span className='fw-bold'>{visit.last_name}</span></Label>
                                            </div>
                                        </div>
                                        <div className="row g-3">
                                            <div className="col">
                                                <Label htmlFor='email'>Email: <span className='fw-bold'>{visit.email}</span></Label>
                                            </div>
                                            <div className="col">
                                                <Label htmlFor='classification'>{classificationsSingularName}: <span className='fw-bold'>{visit.classification ? visit.classification.description : '-'}</span></Label>
                                            </div>
                                        </div>
                                        <Form onSubmit={submit}>
                                            <div className="row">
                                                <div className="col">
                                                    <Label htmlFor='document_number' className='fw-bold'>* {t('common.dni')}</Label>
                                                    <Input type='number' value={visit.document_number} name='document_number' onChange={handleVisists} />
                                                </div>
                                                <div className="col">
                                                    <Label htmlFor='company_name' className='fw-bold'>* {t('common.company')}</Label>
                                                    <Input type='text' value={visit.company_name} name='company_name' onChange={handleVisists} />
                                                </div>
                                            </div>
                                            <div>
                                                <Label htmlFor='observations'>{t('common.observations')}</Label>
                                                <textarea value={visit.observations} name='observations' style={{ height: '200px' }} className="form-control mb-4" onChange={handleVisists}></textarea>
                                            </div>
                                            <p className='fw-bold'>* {t('common.requiredFields')}</p>

                                            <Button className="btn-primary d-flex mx-auto" disabled={disabled}><span>{t('common.save')}</span></Button>
                                        </Form>
                                    </>

                            }
                        </CardBody>
                        <div style={{ height: '40px' }} className='bg-primary text-white text-center'>
                            {
                                tenant.isFetching && !tenant.error ?
                                    <Spinner size={'XXL'} className='d-flex mx-auto' />
                                    :
                                    <small>
                                        V. {tenant.error ? null : version} - © - Exactian Consulting S.A. -{' '}
                                        <a
                                            href="https://www.exactian.com"
                                            className="card-link text-white"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            www.exactian.com
                                        </a>
                                    </small>
                            }
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
