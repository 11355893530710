export const GET_VISITS = 'GET_VISITS';
export const GET_VISIT = 'GET_VISIT';
export const CREATE_VISIT = 'CREATE_VISIT';
export const UPDATE_VISIT = 'UPDATE_VISIT';
export const DELETE_VISIT = 'DELETE_VISIT';
export const DELETE_VISIT_ERROR = 'DELETE_VISIT_ERROR';
export const UNATORIZED = 'UNATORIZED'
export const ERROR_VISITS = 'ERROR_VISITS';
export const ERROR_EMAIL = 'ERROR_EMAIL';
export const SEND_MAIL = 'SEND_MAIL';
export const CLEAR_ALERTS = 'CLEAR_ALERTS'
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const GET_VALID_INDUCTIONS = 'GET_VALID_INDUCTIONS';
export const SENDING = 'SENDING';
export const SEND_INDUCTION = 'SEND_INDUCTION';
export const SEND_INDUCTION_ERROR = 'SEND_INDUCTION_ERROR';
export const GRANT = 'GRANT';
export const REVOKE = 'REVOKE';
export const STATUS_ERROR = 'STATUS_ERROR';
