import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Table } from 'reactstrap'
import { getCustomNameByKey } from '../../helpers/helpers';
import { RevokeOrGrantModal } from '../RevokeOrGrantModal/RevokeOrGrantModal';
import { ContractorList } from './ContractorList';
export const ContractorTable = ({ contractors, selectedContractors, setSelectedContractors, visualizationId, getCallback }) => {

    const { t } = useTranslation();

    const allAsigned = contractors.every((contractor) => contractor.assignment_date !== null)

    const [modal, setModal] = useState(false);

    const [values, setValues] = useState(null)

    const [contractorId, setContractorId] = useState(null)

    const handleModal = (values, contractorId) => {
        setModal(true);
        setContractorId(contractorId)
        setValues(values)
    }

    const handleContractorSelected = (e, contractor) => {
        const { checked } = e.target;
        if (checked) {
            setSelectedContractors([...selectedContractors, contractor])
        } else {
            setSelectedContractors(selectedContractors.filter((selectedContractor) => selectedContractor.contractor_id !== contractor.contractor_id))
        }
    }

    const isAdded = (id) => {
        const isAlReadyAdded = selectedContractors.some((selectedContractor) => selectedContractor.contractor_id === id);
        return isAlReadyAdded;
    }

    const selectAll = (e) => {
        const { checked } = e.target;
        let newData = [...selectedContractors];
        if (checked) {
            contractors.forEach(contractor => {
                const wasAdded = selectedContractors.some((selectedContractor) => selectedContractor.contractor_id === contractor.contractor_id);
                if (!wasAdded && contractor.assignment_date === null) {
                    newData.push(contractor);
                }
            });
        } else {
            newData = selectedContractors.filter((selectedContractor) => !contractors.some((contractor) => contractor.contractor_id === selectedContractor.contractor_id));
        }
        setSelectedContractors(newData);
    }

    const isAllAdded = () => {
        const checkLength = contractors.length;
        const filter = selectedContractors.filter((selectedContractor) => contractors.some((contractor) => contractor.contractor_id === selectedContractor.contractor_id))
        return checkLength === filter.length
    }

    return (
        <div>
            {
                modal ?
                    <RevokeOrGrantModal
                        setModal={setModal}
                        modal={modal}
                        id={visualizationId}
                        values={values}
                        getCallback={getCallback}
                        contractorId={contractorId}
                    />
                    :
                    null
            }
            <div style={{ overflowX: 'auto' }}>
                <Table hover>
                    <thead style={{ background: '#e0e3e6' }}>
                        <tr>
                            <th>
                                {
                                    allAsigned ?
                                        null :
                                        <Input
                                            type='checkbox'
                                            onChange={selectAll}
                                            checked={isAllAdded()}
                                        />
                                }
                            </th>
                            <th>
                                {t('contractor.contractor_name')}
                            </th>
                            <th>
                                {t('contractor.contractor_classification')}
                            </th>
                            <th>
                                {t('inductions.activities_name')}
                            </th>
                            <th>
                                {getCustomNameByKey('businessTypeNames')}
                            </th>
                            <th>
                                {t('contractor.assigned_induction')}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {contractors.map((contractor) => (
                            <ContractorList
                                key={contractor.contractor_id}
                                contractor={contractor}
                                handleContractorSelected={handleContractorSelected}
                                isAdded={isAdded}
                                handleModal={handleModal}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
