import React from 'react'
import { useReducer } from 'react'
import ContractorClassificationsContext from './ContractorClassificationsContext'
import { contractorClassificationsGet } from '../../services/contractorClassificationsService'
import { ContractorClassificationsReducer } from './ContractorClassificationsReducer'
import { logout } from '../../services/loginService'

export const ContractorClassificationsState = ({ children }) => {
    const initialState = {
        contractorClassifications: [],
        isFetching: true,
        error: false,
        message: '',
        tokenError: false
    }

    const [state, dispatch] = useReducer(ContractorClassificationsReducer, initialState);

    const getContractorClassifications = async () => {
        try {
            const response = await contractorClassificationsGet();
            if (response.ok) {
                dispatch({
                    type: 'GET_CONTRACTOR_CLASSIFICATION',
                    payload: response.data.response,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CONTRACTOR_CLASSIFICATION',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_CONTRACTOR_CLASSIFICATION',
            })
            console.log(e)
        }
    }
    
    return (
        <ContractorClassificationsContext.Provider value={{
            contractorClassificationsState: state,
            getContractorClassifications
        }}>
            {children}
        </ContractorClassificationsContext.Provider>
    )
}
