import { Navigate, Route, Routes } from "react-router-dom";
import { ContextProvider } from "./context/ContextProvider";
import { AuthRoutes } from "./routes/AuthRoutes";
import { UniqueExamRoutes } from "./routes/UniqueExamRoutes";
import { ExamRoutes } from "./routes/ExamRoutes";
import { PublicRoute } from "./routes/PublicRoute";
import { MainRoutes } from "./routes/MainRoutes";
import { PrivateRoute } from "./routes/PrivateRoute";

function App() {

  return (
    <ContextProvider>
      <Routes>

        <Route
          path="login/*"
          element={
            <PublicRoute>
              <AuthRoutes />
            </PublicRoute>
          }
        />

        <Route
          path="visit/*"
          element={
            <PublicRoute>
              <UniqueExamRoutes />
            </PublicRoute>
          }
        />

        <Route
          path="exam/*"
          element={<ExamRoutes />}
        />

        <Route
          path="/*"
          element={
            <PrivateRoute>
              <MainRoutes />
            </PrivateRoute>
          }
        />


        <Route
          path="*"
          element={<Navigate to={'/login'} replace />}
        />

      </Routes>
    </ContextProvider>
  );
}

export default App;
