import React from 'react'
import { useReducer } from 'react';
import { detailGet, employeesGet, employeesGrant, employeesRevoke, employeesSendInduction } from '../../services/employeeService';
import { logout } from '../../services/loginService';
import { EmployeeContext } from './EmployeeContext'
import { EmployeeReducer } from './EmployeeReducer'
import i18n from "i18next";

export const EmployeeState = ({ children }) => {
    const initialState = {
        employees: [],
        visualizationId: null,
        employeeSelected: null,
        visualizationMessage: '',
        totalPages: null,
        isFetching: true,
        changingPage: false,
        sending: false,
        error: false,
        message: '',
        alert: false,
        errorSend: false,
        alertStatus: false,
        errorStatus: false,
    };

    const [state, dispatch] = useReducer(EmployeeReducer, initialState);

    const sending = () => {
        dispatch({
            type: 'SENDING'
        })
    }

    const getEmployees = async (inductionId, page, employee_name, employee_code, employee_classification_id, business_type_opening_code, contract_id) => {
        try {
            const response = await employeesGet(inductionId, page, employee_name, employee_code, employee_classification_id, business_type_opening_code, contract_id);
            if (response.ok) {
                dispatch({
                    type: 'GET_EMPLOYEES',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_EMPLOYEES',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_EMPLOYEES',
            })
            console.log(e)
        }
    }

    const sendInductionToEmployee = async (values, toggle, setSelectedContractors, getCallback) => {
        try {
            sending();
            const response = await employeesSendInduction(values);
            if (response.ok) {
                dispatch({
                    type: 'SEND_INDUCTION',
                    payload: response.data,
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                    setSelectedContractors([])
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'SEND_INDUCTION_ERROR',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'SEND_INDUCTION_ERROR',
            })
            console.log(e)
        }
    }

    const revokeToEmployee = async (id, employeeId, contractorID, toggle, getCallback) => {
        try {
            const response = await employeesRevoke(id, { contractor: contractorID, employee: employeeId });
            if (response.ok) {
                dispatch({
                    type: 'REVOKE_INDUCTION',
                    payload: response.data,
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'STATUS_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'STATUS_ERROR',
                payload: i18n.t('common.error_server')
            })
            setTimeout(() => {
                dispatch({
                    type: 'CLEAR_ALERTS',
                })
            }, 3000);
            console.log(e)
        }
    }

    const grantToEmployee = async (id, employeeId, contractorID, toggle, getCallback) => {
        try {
            const response = await employeesGrant(id, { contractor: contractorID, employee: employeeId });
            if (response.ok) {
                dispatch({
                    type: 'GRANT_INDUCTION',
                    payload: response.data,
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'STATUS_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'STATUS_ERROR',
                payload: i18n.t('common.error_server')
            })
            setTimeout(() => {
                dispatch({
                    type: 'CLEAR_ALERTS',
                })
            }, 3000);
            console.log(e)
        }
    }

    const changePageEmployee = () => {
        dispatch({
            type: 'CHANGE_PAGE'
        })
    }

    const cleanEmployees = () => {
        dispatch({
            type: 'CLEAN_EMPLOYEES'
        })
    }

    const getDetail = async (id, contractId) => {
        try {
            const response = await detailGet(id, contractId);
            if (response.ok) {
                dispatch({
                    type: 'GET_DETAIL',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_EMPLOYEES',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_EMPLOYEES',
            })
            console.log(e)
        }
    }

    return (
        <EmployeeContext.Provider value={{
            employeeState: state,
            getEmployees,
            sendInductionToEmployee,
            revokeToEmployee,
            grantToEmployee,
            changePageEmployee,
            cleanEmployees,
            getDetail
        }}>
            {children}
        </EmployeeContext.Provider>
    )
}
