import { Navigate, Route, Routes } from "react-router-dom"
import { ProfileRoutes } from "../components/ProfileRoutes/ProfileRoutes"
import { Induction } from "../containers/Induction/Induction"
import { isUnique } from "../helpers/helpers"
import { InductionForm, InductionIntegratedForm } from "../components/Induction"
import { IntegratedRoutes } from "../components/IntegratedRoutes/IntegratedRoutes"
import { InductionDetails } from "../components/Induction/InductionDetails"
import { ContractorDetail } from "../containers/ContractorDetail/ContractorDetail"
import { Employee } from "../containers/Employee/Employee"
import { Contractor } from "../containers/Contractor/Contractor"
import { EmployeeDetail } from "../containers/EmployeeDetail/EmployeeDetail"

export const InductionRoutes = () => {
    return (
        <Routes>

            <Route
                path="/"
                element={
                    <ProfileRoutes
                        validRoles={['01', '02', '03', '04']}
                    >
                        <Induction />
                    </ProfileRoutes>
                }
            />

            <Route
                path="new"
                element={
                    <ProfileRoutes
                        validRoles={isUnique() ? ['01', '02'] : ['01']}
                    >
                        {isUnique() ? <InductionForm /> : <InductionIntegratedForm />}
                    </ProfileRoutes>
                }
            />

            <Route
                path="edit/:id"
                element={
                    <ProfileRoutes
                        validRoles={isUnique() ? ['01', '02'] : ['01']}
                    >
                        {isUnique() ? <InductionForm /> : <InductionIntegratedForm />}
                    </ProfileRoutes>
                }
            />

            <Route
                path="details/contractor/:id"
                element={
                    <IntegratedRoutes>
                        <ProfileRoutes validRoles={['01', '02', '03']}>
                            <InductionDetails>
                                <ContractorDetail />
                            </InductionDetails>
                        </ProfileRoutes>
                    </IntegratedRoutes>
                }
            />

            <Route
                path="details/employee/:id/:contractorId/"
                element={
                    <IntegratedRoutes>
                        <ProfileRoutes validRoles={['01', '02', '03', '04']}>
                            <InductionDetails>
                                <EmployeeDetail />
                            </InductionDetails>
                        </ProfileRoutes>
                    </IntegratedRoutes>
                }
            />

            <Route
                path="send/contractor/:id"
                element={
                    <IntegratedRoutes>
                        <ProfileRoutes validRoles={['01', '02']}>
                            <InductionDetails>
                                <Contractor />
                            </InductionDetails>
                        </ProfileRoutes>
                    </IntegratedRoutes>
                }
            />

            <Route
                path="send/employee/:id"
                element={
                    <IntegratedRoutes>
                        <ProfileRoutes validRoles={['04']}>
                            <InductionDetails>
                                <Employee />
                            </InductionDetails>
                        </ProfileRoutes>
                    </IntegratedRoutes>
                }
            />

            <Route path="*" element={<Navigate to={'/'} replace />} />

        </Routes>
    )
}
