import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTenantByKey } from '../../helpers/helpers';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

export const VisitList = ({ visit, handleModal, reSendMail }) => {
    const { t } = useTranslation();
    const [showOtherData, setShowOtherData] = useState(false);
    const classificationsSingularName = getTenantByKey('singular_classification_name');
    return (
        <>
            <tr>
                <td>
                    <i
                        className={showOtherData ? "fa-solid fa-angles-up" : "fas fa-angle-double-down"}
                        type='button'
                        onClick={() => setShowOtherData(!showOtherData)}
                        style={{ marginRight: '-12px' }}
                    >
                    </i>
                </td>
                <td>
                    {visit.last_name}, {visit.first_name}
                </td>
                <td>
                    {visit.email}
                </td>
                <td>
                    {visit.document_number ? visit.document_number : '-'}
                </td>
                <td>
                    {visit.user_created.user_name} {visit.user_created.is_generic === true ? <i className="fa-solid fa-users ms-1"></i> : null}
                </td>
                <td >
                    <span
                        style={{ minWidth: '78px' }}
                        className={visit.status === 'V' ? 'badge bg-success' : 'badge bg-danger'}
                    >
                        {visit.status === 'V' ? t('inductions.current') : t('inductions.noCurrent')}
                    </span>
                </td>
                <td className='d-flex gap-3 align-items-center flex-wrap'>

                    <CustomTooltip
                        placement={"top"}
                        target={`send-${visit._id}`}
                        text={t('common.send_induction')}
                    />
                    <i
                        className="fa-solid fa-paper-plane"
                        onClick={() => reSendMail(visit._id)}
                        type='button'
                        id={`send-${visit._id}`}
                    >
                    </i>

                    <CustomTooltip
                        placement={"top"}
                        target={`edit-${visit._id}`}
                        text={t('common.action_edit')}
                    />
                    <Link
                        style={{ color: 'black' }}
                        to={`edit/${visit._id}`}
                        id={`edit-${visit._id}`}
                    >
                        <i className="fa-solid fa-file-pen"></i>
                    </Link>

                    <CustomTooltip
                        placement={"top"}
                        target={`delete-${visit._id}`}
                        text={t('common.action_delete')}
                    />
                    <i

                        className="fa-solid fa-trash"
                        onClick={() => handleModal(visit._id)}
                        type='button'
                        id={`delete-${visit._id}`}
                    >
                    </i>
                </td>
            </tr>

            {
                showOtherData ?
                    <tr>
                        <td colSpan={7}>
                            <p className='fw-bold'>{t('common.company')}: <span className='fw-normal ms-2'>{visit.company_name ? visit.company_name : '-'}</span></p>
                            <p className='fw-bold'>{t('common.observations')}: <span className='fw-normal ms-2'>{visit.observations ? visit.observations : '-'}</span></p>
                            <p className='fw-bold'>{classificationsSingularName}:<span className='fw-normal ms-2'>{visit.classification ? visit.classification.description : '-'}</span></p>
                            {visit.user_created.is_generic === true ?
                                <>
                                    <p className='fw-bold'>{t('common.created')}:<span className='fw-normal ms-2'>{visit.user_full_name}</span></p>
                                    <p className='fw-bold'>{t('common.area')}:<span className='fw-normal ms-2'>{visit.user_area}</span></p>
                                    <p className='fw-bold'>{t('common.rol')}:<span className='fw-normal ms-2'>{visit.user_rol}</span></p>
                                </>
                                : null
                            }
                        </td>
                    </tr>
                    :
                    null
            }
        </>
    )
}
