import { GET_TENANT, TENANT_ERROR } from "./types";
import i18n from "i18next";
const TenantReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_TENANT:
            return {
                ...state,
                tenantInfo: payload,
                themeInfo: payload.theme,
                isFetching: false,
            }

        case TENANT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: true,
                message: i18n.t('common.error_tenant')
            }

        default:
            return state

    }
}

export default TenantReducer