import { customFetch } from "../helpers/customFetch";
import { URL } from "../helpers/helpers";

export const tenant = async () => {
    try {
        var configs = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Basic ${btoa(`${process.env.REACT_APP_AUTH_USER}:${process.env.REACT_APP_AUTH_PASS}`)}`,
            },
            method: 'GET',
        };
        const response = await customFetch(`${URL}tenant`, configs);
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            const resData = await response.json();
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        return console.log(e);
    }
}