import i18n from "i18next";
import { GET_VISITS, GET_VISIT, CREATE_VISIT, UPDATE_VISIT, DELETE_VISIT, UNATORIZED, ERROR_VISITS, ERROR_EMAIL, SEND_MAIL, CLEAR_ALERTS, DELETE_VISIT_ERROR, CHANGE_PAGE, GET_VALID_INDUCTIONS, SEND_INDUCTION, SEND_INDUCTION_ERROR, SENDING, REVOKE, GRANT, STATUS_ERROR } from "./types";
const VisitReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case UNATORIZED:
            return {
                ...state,
                visits: [],
                validInductions: [],
                totalPages: null,
                visitSelected: null,
                isFetching: false,
                sending: false,
                error: true,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                message: i18n.t('common.error_token'),
                errorSend: false,
                errorStatus: true,
                alertStatus: false,
            }

        case ERROR_VISITS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: true,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                message: i18n.t('common.error_server'),
            }

        case ERROR_EMAIL:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: true,
                alert: false,
                deleteError: false,
                alertDelete: false,
            }

        case GET_VISITS:
            return {
                ...state,
                visits: payload.visits_pages,
                validInductions: [],
                totalPages: payload.total_pages,
                visitSelected: null,
                isFetching: false,
                changingPage: false,
                sending: false,
                error: false,
                message: '',
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                errorSend: false,
            }

        case CREATE_VISIT:
            return {
                ...state,
                sending: true,
                error: false,
                message: i18n.t('common.alert_1'),
                inputError: false,
                alert: true,
                deleteError: false,
                alertDelete: false,
                visitSelected: payload,
            }

        case GET_VISIT:
            return {
                ...state,
                visitSelected: payload,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                deleteError: false,
                alertDelete: false,
            }

        case UPDATE_VISIT:
            return {
                ...state,
                sending: true,
                error: false,
                message: i18n.t('common.alert_2'),
                inputError: false,
                alert: true,
                deleteError: false,
                alertDelete: false,
            }

        case CLEAR_ALERTS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                message: '',
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                alertStatus: false,
                errorStatus: false,
            }

        case SEND_MAIL:
            return {
                ...state,
                alert: true,
                message: '',
            }

        case DELETE_VISIT:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: true,
            }

        case DELETE_VISIT_ERROR:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: true,
                alertDelete: false,
                message: payload
            }

        case CHANGE_PAGE: {
            return {
                ...state,
                changingPage: true,
            }
        }
        case GET_VALID_INDUCTIONS: {
            return {
                ...state,
                validInductions: payload,
                isFetching: false,
                changingPage: false,
                sending: false,
                error: false,
                message: '',
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                alertStatus: false,
                errorStatus: false,
            }
        }
        case SEND_INDUCTION:
            return {
                ...state,
                alert: true,
                errorSend: false,
                sending: true,
            }
        case SEND_INDUCTION_ERROR:
            return {
                ...state,
                alert: false,
                errorSend: true,
                message: i18n.t('common.error_server'),
                sending: false,
            }
        case SENDING:
            return {
                ...state,
                sending: true
            }

        case REVOKE:
            return {
                ...state,
                alertStatus: true,
                errorStatus: false,
                sending: true,
            }

        case GRANT:
            return {
                ...state,
                alertStatus: true,
                errorStatus: false,
                sending: true,
            }


        case STATUS_ERROR:
            return {
                ...state,
                alertStatus: false,
                errorStatus: true,
                sending: false,
                message: payload,
            }
        default:
            return state

    }
}

export default VisitReducer