import { Editor } from '@tinymce/tinymce-react'
import React from 'react'
import { getTenantByKey } from '../../helpers/helpers'
export const CustomEditor = ({ name, handleChange, value, placeholder }) => {
    const KEY = process.env.REACT_APP_TINY_MCE_KEY
    const language = getTenantByKey('language')
    return (
        <Editor
            apiKey={KEY}
            init={{
                height: 200,
                menubar: 'edit insert format table help',
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'charmap',
                    'searchreplace', 'visualblocks',
                    'insertdatetime', 'table', 'paste', 'help',
                ],
                toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                language: language,
                paste_block_drop: false,
                paste_data_images: true,
                paste_as_text: true,
                placeholder: placeholder
            }}
            value={value}
            onEditorChange={(e) => handleChange({ target: { name: name, value: e } })}
        />
    )
}
