import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import BusinessTypeOpeningsContext from '../../context/BusinessTypeOpenings/BusinessTypeOpeningsContext';
import BusinessTypesContext from '../../context/BusinessTypes/BusinessTypesContext';
import ContractorClassificationsContext from '../../context/ContractorClassifications/ContractorClassificationsContext';
import { getCustomNameByKey, getTenantByKey } from '../../helpers/helpers';

export const ContractorFilter = ({ handleChangeFilter, search, clean, filter }) => {

    const { contractorClassificationsState, getContractorClassifications } = useContext(ContractorClassificationsContext);
    const { contractorClassifications } = contractorClassificationsState;

    const { businessTypeOpeningsState, getBusinessTypeOpeningsByInduction } = useContext(BusinessTypeOpeningsContext);
    const { businessTypeOpenings } = businessTypeOpeningsState;

    const { getBusinessTypesByIndcution, businessTypesState } = useContext(BusinessTypesContext);
    const { businessTypes } = businessTypesState;

    const { t } = useTranslation();

    const { id } = useParams();

    const { contractor_name, contractor_code, business_type_code, contractor_classification_id, business_type_opening_code } = filter;

    const onKeyPress = (e) => {
        if ((e.key === 'Enter')) {
            search();
        }
    };

    useEffect(() => {
        getContractorClassifications();
        getBusinessTypeOpeningsByInduction(id);
        getBusinessTypesByIndcution(id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='row'>
            <div className='col'>
                <Label
                    htmlFor='contractor_name'
                >
                    {t('common.firstName')}
                </Label>
                <Input
                    type='text'
                    name='contractor_name'
                    value={contractor_name}
                    onChange={handleChangeFilter}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='col'>
                <Label
                    htmlFor='contractor_code'
                >
                    {getTenantByKey('contractor_doc')}
                </Label>
                <Input
                    type='text'
                    name='contractor_code'
                    value={contractor_code}
                    onChange={handleChangeFilter}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='col'>
                <Label
                    htmlFor='business_type_code'
                >
                    {t('inductions.activities_name')}
                </Label>
                <select
                    className='form-select'
                    name='business_type_code'
                    value={business_type_code}
                    onChange={handleChangeFilter}
                >
                    <option value="">{t('common.all')}</option>
                    {businessTypes.map((businessType) => (
                        <option
                            key={businessType.code}
                            value={businessType.code}
                        >
                            {businessType.description}
                        </option>
                    ))}
                </select>
            </div>

            <div className='col'>
                <Label
                    htmlFor='contractor_classification_id'
                >
                    {t('contractor.contractor_classification')}
                </Label>
                <select
                    className='form-select'
                    name='contractor_classification_id'
                    value={contractor_classification_id}
                    onChange={handleChangeFilter}
                >
                    <option value="">{t('common.all')}</option>
                    {contractorClassifications.map((contractorClassification) => (
                        <option
                            key={contractorClassification.id}
                            value={contractorClassification.id}
                        >
                            {contractorClassification.description}
                        </option>
                    ))}
                </select>
            </div>

            <div className='col'>
                <Label
                    htmlFor='business_type_opening_code'
                >
                    {getCustomNameByKey('businessTypeNames')}
                </Label>
                <select
                    className='form-select'
                    name='business_type_opening_code'
                    value={business_type_opening_code}
                    onChange={handleChangeFilter}
                >
                    <option value="">{t('common.all')}</option>
                    {businessTypeOpenings.map((businessTypeOpening) => (
                        <option
                            key={businessTypeOpening.code}
                            value={businessTypeOpening.code}
                        >
                            {businessTypeOpening.description}
                        </option>
                    ))}
                </select>
            </div>

            <div className='col' style={{ marginTop: '32px' }}>
                <div className='d-flex align-items-end gap-3'>
                    <Button
                        onClick={clean}
                        className="btn-secondary"
                    >
                        <i className="fa-solid fa-eraser"></i>
                    </Button>
                    <Button
                        onClick={search}
                        className="btn-secondary"
                    >
                        <i className="fa-solid fa-magnifying-glass" ></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}
