import React, { useContext, useEffect } from 'react'
import { Input, Label, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import UsersContext from '../../context/Users/UsersContext';

export const UsersFilter = ({ handleChange, search, clean, filterValues }) => {
    const { profilesGet, usersState } = useContext(UsersContext);
    const { profiles } = usersState
    const { t } = useTranslation();
    const { user_name, first_name, last_name, profile, active, is_generic } = filterValues;

    const onKeyPress = (e) => {
        if ((e.key === 'Enter')) {
            search();
        }
    };

    useEffect(() => {
        profilesGet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='row row-cols-1 row-cols-lg-7 align-items-end'>

            <div className='col-md'>
                <Label htmlFor='user_name' >{t('common.user_name')}</Label>
                <Input
                    type='text'
                    value={user_name}
                    name='user_name'
                    onChange={handleChange}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='col-md'>
                <Label htmlFor='first_name'>{t('common.firstName')}</Label>
                <Input
                    type='text'
                    value={first_name}
                    name='first_name'
                    onChange={handleChange}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='col-md'>
                <Label htmlFor='last_name'>{t('common.lastName')}</Label>
                <Input
                    type='text'
                    value={last_name}
                    name='last_name'
                    onChange={handleChange}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='col-md'>
                <Label htmlFor='profile'>{t('common.profile')}</Label>
                <select
                    className='form-select'
                    value={profile}
                    name="profile"
                    onChange={handleChange}
                >
                    <option value={''}>{t('common.all')}</option>
                    {profiles.map((profile) => (
                        <option key={profile._id} value={profile._id}>{profile.description}</option>
                    ))}
                </select>
            </div>

            <div className='col-md'>
                <Label htmlFor='is_generic'>{t('common.generic')}</Label>
                <select
                    className='form-select'
                    name='is_generic'
                    value={is_generic}
                    onChange={handleChange}
                >
                    <option value={''}>{t('common.all')}</option>
                    <option value={true}>{t('common.yes')}</option>
                    <option value={false}>{t('common.no')}</option>
                </select>
            </div>

            <div className='col-md'>
                <Label htmlFor='active'>{t('common.active')}</Label>
                <select
                    className='form-select'
                    name="active"
                    value={active}
                    onChange={handleChange}
                >
                    <option value={''}>{t('common.all')}</option>
                    <option value={true}>{t('common.yes')}</option>
                    <option value={false}>{t('common.no')}</option>
                </select>
            </div>

            <div className='col-md mt-3'>
                <div className='d-flex align-items-end gap-3'>
                    <Button
                        onClick={clean}
                        className="btn-secondary"
                    >
                        <i className="fa-solid fa-eraser"></i>
                    </Button>
                    <Button
                        onClick={search}
                        className="btn-secondary"
                    >
                        <i className="fa-solid fa-magnifying-glass" ></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}
