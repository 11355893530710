export const english = {
  translation: {
    language: 'EN',
    "common": {
      "name": "Name",
      "user_name": "Username",
      "firstName": "Name",
      "lastName": "Surname",
      "fullName": "Full name",
      "profile": "Profile",
      "generic": "Generic",
      "active": "Active",
      "genericProfile": "Generic Profile",
      "area": "Area",
      "rol": " Function",
      "dni": "ID",
      "company": "Business",
      "observations": "Other data",
      "description": "Description",
      "requiredFields": "Required fields",
      "status": "Condition",
      "enabled": "Enabled",
      "notEnabled": "Not Enabled",
      "code": "Code",
      "created": "Registered by",
      "choose": "Select",
      "all": "All",
      "yes": "Yes",
      "no": "No",
      "cancel": "Cancel",
      "save": "Save",
      "confirm": "Confirm",
      "search": "Search",
      "confirmDelete": "Are you sure you want to delete the record?",
      "delete": "Remove",
      "alertDelete": "Record Deleted",
      "error_token": "The session has expired, please log in again.",
      "error_server": "A server connection error has occurred, please try again later",
      "error_tenant": "Instance not found",
      "error_relations": "The record could not be deleted because it has relationships",
      "not_loaded": "No record loaded",
      "error_filter": "No results were found for the search",
      "alert_1": "Registration successfully registered ",
      "alert_2": "Registry Modified Successfully ",
      "close": "Close",
      "send_induction": "Send",
      "view_detail": "See detail",
      "expire_induction": "Expire",
      "reset_induction": "Restore",
      "action_edit": "Edit",
      "action_delete": "Remove",
      "view_versions": "Versions",
      "name_date": "Date",
      "came_back": "Return",
      "end": "Finish",
      "clean_selection": "Clear Selection",
      "suitable": "Suitable",
      "no_suitable": "No Suitable",
      "earring": "Earring"
    },
    "login": {
      "user_name": "User",
      "password": "Password",
      "login": "Get into",
      "forgotPassword": "Forgot your password?",
      "error_1": "Wrong Credentials!"
    },
    "forgotPassword": {
      "title": "Recover password",
      "changePassword": "Change Password",
      "recover": "Retrieve",
      "confirmPassword": "Confirm Password",
      "mailSend": "We send you an email with your new password",
      "passwordChanged": "Modified Password",
      "error_1": "The entered user does not exist"
    },
    "layout": {
      "management": {
        "management": "Management",
        "inductions": "Inductions",
        "classifications": "Classifications",
        "users": "Users"
      },
      "visits": {
        "visits": "Visits",
        "management": "Manage Visits",
        "newVisits": "New Visit"
      },
      "user": {
        "changePassword": "Change Password",
        "loguot": "Sign off"
      }
    },
    "user": {
      "title": "Search - Users",
      "new": "New user",
      "inputError": "The username is already registered",
      "register": "New user",
      "edit": "Edit User"
    },
    "classifications": {
      "title": "Classifications",
      "new": "New",
      "inputError": "The code is already registered",
      "register": "New",
      "edit": "Edit"
    },
    "inductions": {
      "title": "Search - Inductions",
      "new": "New Induction",
      "inputError": "The name is already registered",
      "register": "New Induction",
      "edit": "Edit Induction",
      "since": "Effective date from",
      "until": "Effective date until",
      "since_only": "Since",
      "until_only": "Until",
      "video": "Video/File",
      "sizeError": `The file can weigh a maximum of`,
      "sending": "Going up....",
      "seen": "Viewed",
      "current": "Valid",
      "noCurrent": "Not Valid",
      "finished": "Finished",
      "expired": "expired",
      "futura": "future",
      "confirmExpired": "Are you sure you want to expire the induction?",
      "confirmRecycle": "Are you sure you want to reset the induction?",
      "expire": "Expire",
      "restore": "Restore",
      "confirmState": "Status changed successfully ",
      "download_file": "Download File",
      "confirm_read": "Confirm Reading",
      "error_relations": "The record could not be deleted because it has relations with visits",
      "date_error": {
        "from_error": "Must be greater than or equal to the current date",
        "to_error": "Must be greater than the effective date from"
      },
      "view_file": "See Induction",
      "allow_download": "Allow download",
      "activities_name": "Activity",
      "employee_classifications": "Employee Classification",
      "associated_document": "Associated document",
      "induction_validity": "Validity (days)",
      "induction_validity_only": "Validity",
      "induction_validity_only_days": "days",
      "video_percentage": " % of the video to mark it as seen",
      "induction_name": "Induction",
      "format_error": "Only PDF or Video files are allowed",
      "no_questionary": "does not have a questionnaire",
      "n_page": "Page",
      "n_of": "of",
      "new_version": "The version of the questionnaire was updated",
      "new_questionary_version": "There is a new version of the quiz",
      "induction_expired": "expired induction"
    },
    "visits": {
      "title": "Search - Visits",
      "new": "New visit",
      "register": "New visit",
      "edit": "Edit Visit",
      "infoUser": "User information",
      "mailStatus": "Condition",
      "induccionStatus": "Induction Status",
      "validInductions": "Current Inductions",
      "error_relations": "You could not delete the visit because it has already interacted with the system",
      "inputError": "The visit is already registered",
      "info_visit": "Visit data",
      "mail_send": "E-mail sent",
      "send_induction_message": {
        "start_part": "Are you sure to send to",
        "end_part": "the following inductions?"
      },
      "send_message_alert": "Inductions Sent",
      "earring_to_assing": "Induction Assignment Slope",
      "ask_assing_inductions": "Do you want to send inductions to"
    },
    "questionary": {
      "title": "Search - Questionnaires",
      "new": "New Quizzes",
      "register": "New Questionnaire",
      "edit": "Edit Questionnaire",
      "questionary": "Questionnaire",
      "version": "Version",
      "name_questionary": "Questionnaire Name",
      "max_score": "Top Score",
      "min_score": "Minimum Score",
      "new_question": "New question",
      "question_placeholder": "enter the question",
      "score_placeholder": "Enter the score",
      "answer_placeholder": "Enter the answer",
      "total_score_error": "The sum of the questions must give the maximum score",
      "add_question": "Add Question",
      "delete_question": "Delete Question",
      "add_answer": "Add Answer",
      "delete_answer": "Delete Answer",
      "delete_questionary_error": "The questionnaire is being used for an induction",
      "is_correct": "Correct answer",
      "questionary_plural": "Questionnaires",
      "add_one_question": "Create 1 Question",
      "add_two_question": "Create 2 Questions",
      "add_three_question": "Create 3 Questions",
      "add_five_question": "Create 5 Questions",
      "add_ten_question": "Create 10 Questions",
      "last_version_alert": "Are you sure you want to remove the latest version of the quiz?",
      "choose_answer": "Select one of the following possible answers",
      "next_question": "Following",
      "prev_question": "Previous",
      "full_screen": "Full screen",
      "congratulations": "Congratulations",
      "exam_pass_confirm": "You have successfully passed the questionnaire ",
      "exam_pass_confirm_two": " corresponding to the induction ",
      "exam_pass_note": "Note",
      "enabled_to_date": "It is enabled until",
      "print_exam": "Print",
      "we_sorry": "We're sorry",
      "exam_reject_confirmation": "You have not reached the necessary score to pass the quiz ",
      "try_again_question": "Do you want to try again?",
      "try_again_action": "Try again",
      "exam_pass_confirm_three": "You have completed the induction",
      "exam_disabled_video_part_two": "of the video",
      "exam_disabled_questionary": " and complete the questionnaire",
      "exam_disabled_pdf": "To finish the induction it is necessary to view the complete PDF",
      "exam_disabled_video_part_one": "To finish the induction it is required to visualize the",
      "exam_disabled_video_part_other": "To finish the induction it is required to visualize the",
      "not_found": "Induction not available or does not exist"
    },
    "contractor": {
      "id": "ID",
      "assigned_induction": "Assigned",
      "contractor_classification": "Classification",
      "contractor_name": "Contractor",
      "send_induction_message": {
        "start_part": "Are you sure to send the Induction",
        "end_part": "to the following contractors?"
      },
      "send_message_alert": "The induction was sent to the selected contractors",
      "message_to_contractors": "Enter a message for contractors",
      "list_of_contractors": "List of Contractors",
      "contractor_detail": "Contractor Detail",
      "assigned_employees": "Assigned",
      "assigned_employees_view": "viewed",
      "assigned_employees_passed": "Approved",
      "total_exam": "Total",
      "print_detail": "Print Detail",
      "employees_plural": "Employees"
    },
    "employee": {
      "id": "ID",
      "assigned_induction": "Assigned",
      "employee_classification": "Classification",
      "employee_name": "Employee",
      "send_induction_message": {
        "start_part": "Are you sure to send the Induction",
        "end_part": "to the following employees?"
      },
      "send_message_alert": "The induction was sent to the selected employees",
      "contract_name": "Contract",
      "send": "Send",
      "list_of_employees": "Employee List",
      "exam_passed": "Passed",
      "detail_employee": "Employee Detail",
      "score_exam": "Score",
      "attemps_exam": "No. Attempts",
      "view_exam": "See Exam",
      "view_certificate": 'View Approval Certificate',
      "employee_info": "All employees are counted without taking user restrictions into account."
    }
  },
};