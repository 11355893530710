import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Spinner } from 'reactstrap'
import { CustomAlert } from '../../components/CustomAlert/CustomAlert'
import { InductionFilter, InductionTable } from '../../components/Induction'
import { PaginationCustom } from '../../components/PaginationCustom/PaginationCustom'
import { Resource } from '../../components/Resource/Resource'
import { ContractorContext } from '../../context/Contractor/ContractorContext'
import { EmployeeContext } from '../../context/Employee/EmployeeContext'
import InductionContext from '../../context/Induction/InductionContext'
import { getUserDataByKey, isIntegrated } from '../../helpers/helpers'

export const Induction = () => {

    const { getInductions, inductionState, changePageInduction } = useContext(InductionContext);

    const { cleanContractors } = useContext(ContractorContext);

    const { cleanEmployees } = useContext(EmployeeContext);

    const { inductions, totalPages, isFetching, error, message, changingPage } = inductionState;

    const { t } = useTranslation();

    const initialState = {
        name: '',
        description: '',
        date_from: '',
        date_to: '',
        status: isIntegrated() ? '' : 'V',
        active: isIntegrated() ? true : ''
    }

    const [filter, setFilter] = useState(initialState);

    const [errorFilter, setErrorFilter] = useState(false);

    const [page, setPage] = useState(1);

    const { name, description, date_from, date_to, status, active } = filter;

    const profile = getUserDataByKey('profile');

    const checkRol = isIntegrated() && ['02', '03', '04'].some((invalidRol) => invalidRol === profile)

    const handleChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value,
        });
    };


    const search = () => {
        changePageInduction();
        setPage(1)
        getInductions(1, name, description, date_from, date_to, status, active);
        setErrorFilter(true)
    }

    const clean = () => {
        changePageInduction();
        setPage(1)
        setFilter(initialState);
        isIntegrated() ? getInductions(1, '', '', '', '', '', true) : getInductions(1, '', '', '', '', 'V', '')
        setErrorFilter(false);
    }

    useEffect(() => {
        getInductions(page, name, description, date_from, date_to, status, active);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
        if (isIntegrated()) getUserDataByKey('profile') === '04' ? cleanEmployees() : cleanContractors();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Container className='bg-white mt-5 pb-5 ps-5 pe-5 pt-5 rounded-3'>
            <Resource title={t('inductions.title')} resource={t('inductions.new')} newResource={'induction'} typeRol={checkRol} />
            <hr className='bg-dark' />
            <InductionFilter handleChange={handleChange} search={search} clean={clean} filterValues={filter} />
            <hr className='bg-dark' />
            {
                isFetching ?
                    <div className='d-flex justify-content-center'>
                        <Spinner size={'xxl'} />
                    </div>
                    :
                    inductions.length > 0 ?
                        <>
                            {
                                changingPage ?
                                    <div className='d-flex justify-content-center mt-5 mb-5 pt-5 pb-5'>
                                        <Spinner size={'xxl'} />
                                    </div> :
                                    <InductionTable inductions={inductions} getInductions={() => getInductions(page, name, description, date_from, date_to, status, active)} />
                            }
                            <>
                                <PaginationCustom totalPages={totalPages} paginate={setPage} currentPage={page} changePage={changePageInduction} />
                            </>
                        </>
                        :
                        inductions.length === 0 && errorFilter && !error && !!!isFetching ?
                            <div className='d-flex justify-content-center'>{t('common.error_filter')}</div>
                            :
                            inductions.length === 0 && !errorFilter && !error && !!!isFetching ?
                                <div className='d-flex justify-content-center'>{t('common.not_loaded')}</div>
                                :
                                null
            }
            {error ? <CustomAlert text={message} color={'danger'} /> : null}
        </Container>
    )
}
