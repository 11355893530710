import { customFetch } from "../helpers/customFetch";
import { URL, getConfig } from "../helpers/helpers";

export const contractorsGet = async (id, page, contractor_name, contractor_code, contractor_classification_id, business_type_code, business_type_opening_code) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visualizations/induction/${id}?page=${page}&contractor_name=${contractor_name}&contractor_code=${contractor_code}&contractor_classification_id=${contractor_classification_id}&business_type_code=${business_type_code}&business_type_opening_code=${business_type_opening_code}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const contractorsSendInduction = async (values) => {
    try {
        const configs = getConfig('POST', values);
        const response = await customFetch(`${URL}visualizations/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const contractorsRevoke = async (id, contractor) => {
    try {
        const configs = getConfig('POST', contractor);
        const response = await customFetch(`${URL}visualizations/revokeContractor/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const contractorsGrant = async (id, contractor) => {
    try {
        const configs = getConfig('POST', contractor);
        const response = await customFetch(`${URL}visualizations/grantContractor/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const summaryGet = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visualizations/summary/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e)
    }
}

export const exportSummary = async (inductionId) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visualizations/detailXLS/${inductionId}`, configs);
        const resData = await response.blob();
        if (!response.ok) {
            return {
                ok: false,
                data: response,
            };
        } else {
            return {
                ok: true,
                data: resData,
            };
        }
    } catch (e) {
        console.log(e);
    }
};

export const totalGet = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visualizations/totalDetail/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e)
    }
}