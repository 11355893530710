import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap'
import { DeleteModal } from '../DeleteModal/DeleteModal';
import { UsersList } from './UsersList';

export const UsersTable = ({ users, usersGet }) => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(null);
    const handleModal = (id) => {
        setModal(true);
        setId(id);
    }
    return (
        <Fragment>
            <DeleteModal modal={modal} setModal={setModal} name={'user.first_name'} id={id} getCallback={usersGet} />
            <div style={{ overflowX: 'auto' }}>
                <Table hover>
                    <thead style={{ background: '#e0e3e6' }}>
                        <tr>
                            <th colSpan={2}>
                                {t('common.user_name')}
                            </th>
                            <th>
                                {t('common.firstName')}, {t('common.lastName')}
                            </th>
                            <th>
                                {t('common.profile')}
                            </th>
                            <th>
                                {t('common.generic')}
                            </th>
                            <th>
                                {t('common.active')}
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <UsersList key={user._id} user={user} handleModal={handleModal} />
                        ))}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    )
}
