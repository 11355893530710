import { useReducer } from 'react';
import InductionContext from "./InductionContext";
import InductionReducer from "./InductionReducer";
import { inductionDownload, inductionGet, inductionGetByContractor, inductionPreviewDeleteURL, inductionPreviewGet, inductionsContractorGet, inductionsCreate, inductionsDelete, inductionsExpire, inductionsGet, inductionsStatusGet, inductionsUpdate, inductionsValidGet } from "../../services/inductionService";
import { logout } from '../../services/loginService';
import { confirmVisualized } from '../../services/visistsService';
import { useNavigate } from 'react-router-dom';
import i18n from "i18next";
import { getUserDataByKey } from '../../helpers/helpers';

export const InductionState = ({ children }) => {
    const initialState = {
        inductions: [],
        validInductions: [],
        totalPages: null,
        inductionSelected: null,
        assigned_to_contractors: false,
        assigned_to_employee: false,
        isFetching: true,
        changingPage: false,
        sending: false,
        error: false,
        message: '',
        inputError: false,
        alert: false,
        deleteError: false,
        alertDelete: false,
        alertStatus: false,
        url: '',
    }

    const [state, dispatch] = useReducer(InductionReducer, initialState);

    const navigate = useNavigate();

    const getInductions = async (page, name, description, date_from, date_to, status, active) => {
        try {
            let response;
            response = getUserDataByKey('profile') === '04' ? await inductionsContractorGet(getUserDataByKey('_id'), page, name, active) : await inductionsGet(page, name, description, date_from, date_to, status, active);
            if (response.ok) {
                dispatch({
                    type: 'GET_INDUCTIONS',
                    payload: response.data
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_INDUCTIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e);
        }
    }
    const createInduction = async (induction) => {
        try {
            dispatch({
                type: 'SENDING',
            })
            let newInduction = Object.assign({}, induction)

            if (newInduction.classification === '') newInduction.classification = null

            if (newInduction.questionary === '') newInduction.questionary = null

            if (newInduction.business_types) newInduction.business_types = JSON.stringify(newInduction.business_types)
            if (newInduction.business_type_openings) newInduction.business_type_openings = JSON.stringify(newInduction.business_type_openings)
            if (newInduction.employee_classifications) newInduction.employee_classifications = JSON.stringify(newInduction.employee_classifications)

            const response = await inductionsCreate(newInduction);
            if (response.ok) {
                dispatch({
                    type: 'CREATE_INDUCTION',
                    payload: response.data
                })

                setTimeout(() => {
                    navigate('/induction')
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 409:
                        dispatch({
                            type: 'INPUT_ERROR',
                        })
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_INDUCTIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e);
        }
    }
    const getInduction = async (id) => {
        try {
            let response;
            response = getUserDataByKey('profile') === '04' ? await inductionGetByContractor(id, getUserDataByKey('_id')) : await inductionGet(id);
            if (response.ok) {
                dispatch({
                    type: 'GET_INDUCTION',
                    payload: response.data
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_INDUCTIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e);
        }
    }
    const updateInduction = async (id, induction) => {
        try {
            let newInduction = Object.assign({}, induction)

            if (newInduction.classification === '') newInduction.classification = null
            if (typeof (newInduction.classification) === 'object' && newInduction.classification !== null) newInduction.classification = newInduction.classification._id;

            if (newInduction.questionary === '') newInduction.questionary = null
            if (typeof (newInduction.questionary) === 'object' && newInduction.questionary !== null) newInduction.questionary = newInduction.questionary._id;

            if (newInduction.business_types) newInduction.business_types = JSON.stringify(newInduction.business_types)
            if (newInduction.business_type_openings) newInduction.business_type_openings = JSON.stringify(newInduction.business_type_openings)
            if (newInduction.employee_classifications) newInduction.employee_classifications = JSON.stringify(newInduction.employee_classifications)

            const response = await inductionsUpdate(id, newInduction);
            if (response.ok) {
                dispatch({
                    type: 'UPDATE_INDUCTION',
                    payload: response.data
                })
                setTimeout(() => {
                    navigate('/induction')
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 409:
                        dispatch({
                            type: 'INPUT_ERROR',
                        })
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_INDUCTIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e);
        }
    }
    const deleteInduction = async (id, toggle, getCallback) => {
        try {
            const response = await inductionsDelete(id);
            if (response.ok) {
                dispatch({
                    type: 'DELETE_INDUCTION',
                    payload: response.data
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 406:
                        dispatch({
                            type: 'DELETE_INDUCTION_ERROR',
                            payload: i18n.t('common.error_relations')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                    default:
                        dispatch({
                            type: 'DELETE_INDUCTION_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'DELETE_INDUCTION_ERROR',
                payload: i18n.t('common.error_server')
            })
            console.log(e);
        }
    }
    const updateInductionActive = async (id, activeValue, toggle, getCallback) => {
        try {
            const response = await inductionsUpdate(id, { active: activeValue });
            if (response.ok) {
                dispatch({
                    type: 'UPDATE_INDUCTION_STATUS',
                    payload: response.data
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'DELETE_INDUCTION_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'DELETE_INDUCTION_ERROR',
                payload: i18n.t('common.error_server')
            })
            setTimeout(() => {
                dispatch({
                    type: 'CLEAR_ALERTS',
                })
            }, 3000);
            console.log(e);
        }
    }
    const expireInduction = async (id, toggle, getCallback) => {
        try {
            const response = await inductionsExpire(id);
            if (response.ok) {
                dispatch({
                    type: 'UPDATE_INDUCTION_STATUS',
                    payload: response.data
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'DELETE_INDUCTION_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'DELETE_INDUCTION_ERROR',
                payload: i18n.t('common.error_server')
            })
            setTimeout(() => {
                dispatch({
                    type: 'CLEAR_ALERTS',
                })
            }, 3000);
            console.log(e);
        }
    }
    const downloadInduction = async (filename, setIsDownloading) => {
        try {
            const response = await inductionDownload(filename);
            if (response.ok) {
                const link = document.createElement("a");
                link.target = "_blank";
                link.download = filename
                link.href = URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
                link.click();
                setIsDownloading(false);
            } else {
                dispatch({
                    type: 'ERROR_INDUCTIONS',
                })
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e);
        }
    }
    const getValidInducctions = async (id) => {
        try {
            const response = await inductionsValidGet(id)
            if (response.ok) {
                dispatch({
                    type: 'GET_VALID_INDUCTIONS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ERROR_INDUCTIONS',
                })
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e)
        }
    }
    const visualized = async (visitId, inductionId) => {
        try {
            const response = await confirmVisualized(visitId, inductionId);
            if (response.ok) {
                getValidInducctions(visitId)
            } else {
                dispatch({
                    type: 'ERROR_INDUCTIONS',
                })
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e);
        }
    }
    const previewInduction = async (filename, visitId) => {
        try {
            const response = await inductionPreviewGet(filename, visitId);
            if (response.ok) {
                dispatch({
                    type: 'GET_URL',
                    payload: response.data.url,
                })
            } else {
                dispatch({
                    type: 'ERROR_INDUCTIONS',
                })
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e);
        }
    }
    const previewInductionDeleteURL = async (filename, visitId) => {
        try {
            const response = await inductionPreviewDeleteURL(filename, visitId);
            if (response.ok) {
                dispatch({
                    type: 'DELETE_URL',
                })
            }
            else {

                dispatch({
                    type: 'ERROR_INDUCTIONS',
                })
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e);
        }
    }
    const getStatusInducction = async (id) => {
        try {
            const response = await inductionsStatusGet(id)
            if (response.ok) {
                dispatch({
                    type: 'GET_STATUS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ERROR_INDUCTIONS',
                })
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_INDUCTIONS',
            })
            console.log(e)
        }
    }
    const changePageInduction = () => {
        dispatch({
            type: 'CHANGE_PAGE'
        })
    }
    return (
        <InductionContext.Provider
            value={{
                inductionState: state,
                getInductions,
                createInduction,
                getInduction,
                updateInduction,
                deleteInduction,
                updateInductionActive,
                downloadInduction,
                getValidInducctions,
                visualized,
                previewInduction,
                previewInductionDeleteURL,
                expireInduction,
                getStatusInducction,
                changePageInduction
            }}
        >
            {children}
        </InductionContext.Provider>
    )
}
