export const GET_CONTRACTORS = 'GET_CONTRACTORS';
export const GET_CONTRACTOR = 'GET_CONTRACTOR';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const UNATORIZED = 'UNATORIZED';
export const ERROR_CONTRACTORS = 'ERROR_CONTRACTORS';
export const SEND_INDUCTION = 'SEND_INDUCTION';
export const SEND_INDUCTION_ERROR = 'SEND_INDUCTION_ERROR';
export const REVOKE_INDUCTION = 'REVOKE_INDUCTION';
export const GRANT_INDUCTION = 'GRANT_INDUCTION';
export const STATUS_ERROR = 'STATUS_ERROR';
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const CLEAN_CONTRACTORS = 'CLEAN_CONTRACTORS';
export const GET_TOTAL = 'GET_TOTAL';
export const SENDING = 'SENDING';
