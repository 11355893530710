import { customFetch } from "../helpers/customFetch";
import { getConfigExactian, URL, URL_EXACTIAN } from "../helpers/helpers";

export const login = async (values) => {
    try {
        var configs = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(values),
        };
        const response = await customFetch(`${URL}users/login`, configs);
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            const resData = await response.json();
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        return console.log(e);
    }
}

export const getBusinessTypeName = async () => {
    try {
        const configs = getConfigExactian('GET');
        const response = await customFetch(`${URL_EXACTIAN}ws2/exactian/admin/settings/Setting?setting=nomapertura`, configs)
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            const resData = await response.json();
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const getEmployeeClassificationName = async () => {
    try {
        const configs = getConfigExactian('GET');
        const response = await customFetch(`${URL_EXACTIAN}ws2/exactian/admin/settings/Setting?setting=emp_classif_name`, configs)
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            const resData = await response.json();
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const loginByExactian = async (token) => {
    try {
        var configs = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'auth-token': `${token}`,

            },
            method: 'POST',
        };
        const response = await customFetch(`${URL}users/loginByExactian`, configs);
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            const resData = await response.json();
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        return console.log(e);
    }
}

export const logout = () => {
    localStorage.removeItem('userData');
    window.location.pathname = "/login"
}