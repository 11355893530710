import { customFetch } from "../helpers/customFetch";
import { URL_EXACTIAN, getConfigExactian } from "../helpers/helpers";

export const contractssGet = async () => {
    try {
        const configs = getConfigExactian('GET');
        const response = await customFetch(`${URL_EXACTIAN}ws2/exactian/contracts/Contracts`, configs)
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            const resData = await response.json();
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}