import React from 'react'
import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/loginService';
import { inductionGrant, inductionRevoke, inductionsSend, sendMail, validInductionsGet, visitCreate, visitDelete, visitFromVisitUpdate, visitGet, visitsGet, visitUpdate } from '../../services/visistsService';
import VisitContext from "./VisitContext";
import VisitReducer from "./VisitReducer";
import i18n from "i18next";
import { isUnique } from '../../helpers/helpers';

export const VisitState = ({ children }) => {
    const initialState = {
        visits: [],
        validInductions: [],
        totalPages: null,
        visitSelected: null,
        isFetching: true,
        changingPage: false,
        sending: false,
        error: false,
        message: '',
        inputError: false,
        alert: false,
        deleteError: false,
        alertDelete: false,
        errorSend: false,
        alertStatus: false,
        errorStatus: false,
    }

    const [state, dispatch] = useReducer(VisitReducer, initialState);

    const navigate = useNavigate();

    const getVisists = async (page, first_name, last_name, document_number, status) => {
        try {
            const response = await visitsGet(page, first_name, last_name, document_number, status);
            if (response.ok) {
                dispatch({
                    type: 'GET_VISITS',
                    payload: response.data
                })
                // setVisits(response.data.visits_pages);
                // setTotalPages(response.data.total_pages)
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_VISITS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_VISITS',
            })
            console.log(e);
        }
    }

    const createVisist = async (visit) => {
        try {
            if (visit.classification === '') visit.classification = null
            const response = await visitCreate(visit);
            if (response.ok) {
                dispatch({
                    type: 'CREATE_VISIT',
                    payload: response.data
                })
                if (isUnique()) {
                    setTimeout(() => {
                        navigate('/visits')
                    }, 1000);
                }
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 409:
                        dispatch({
                            type: 'ERROR_EMAIL',
                        })
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_VISITS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_VISITS',
            })
            console.log(e);
        }
    }

    const getVisit = async (id) => {
        try {
            const response = await visitGet(id);
            if (response.ok) {
                dispatch({
                    type: 'GET_VISIT',
                    payload: response.data
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 500:
                        dispatch({
                            type: 'ERROR_VISITS',
                        })
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_VISITS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_VISITS',
            })
            console.log(e);
        }
    }

    const updateVisit = async (id, visit) => {
        try {
            if (visit.classification === '') visit.classification = null;
            if (typeof (visit.classification) === 'object' && visit.classification !== null) visit.classification = visit.classification._id;
            const response = await visitUpdate(id, visit);
            if (response.ok) {
                dispatch({
                    type: 'UPDATE_VISIT',
                    payload: response.data
                })
                if (isUnique()) {
                    setTimeout(() => {
                        navigate('/visits')
                    }, 1000);
                }
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 409:
                        dispatch({
                            type: 'ERROR_EMAIL',
                        })
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_VISITS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_VISITS',
            })
            console.log(e);
        }
    }

    const reSendMail = async (id) => {
        try {
            const response = await sendMail(id);
            if (response.ok) {
                dispatch({
                    type: 'SEND_MAIL',
                    payload: response.data
                })
                setTimeout(() => {
                    dispatch({
                        type: 'CLEAR_ALERTS',
                    })
                }, 1000);
            } else {

                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_VISITS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_VISITS',
            })
            console.log(e);
        }
    }

    const deleteVisit = async (id, toggle, getCallback) => {
        try {
            const response = await visitDelete(id);
            if (response.ok) {
                dispatch({
                    type: 'DELETE_VISIT',
                    payload: id
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 406:
                        dispatch({
                            type: 'DELETE_VISIT_ERROR',
                            payload: i18n.t('common.error_relations')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                    default:
                        dispatch({
                            type: 'DELETE_VISIT_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'DELETE_VISIT_ERROR',
                payload: i18n.t('common.error_server')
            })
            console.log(e);
        }
    }

    const upddateVisitFromVisit = async (id, visit) => {
        try {
            const response = await visitFromVisitUpdate(id, visit);
            if (response.ok) {
                dispatch({
                    type: 'UPDATE_VISIT',
                    payload: response.data
                })
                setTimeout(() => {
                    dispatch({
                        type: 'CLEAR_ALERTS'
                    })
                }, 1000);
            } else {
                dispatch({
                    type: 'ERROR_VISITS',
                })
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_VISITS',
            })
            console.log(e);
        }
    }

    const changePageVisit = () => {
        dispatch({
            type: 'CHANGE_PAGE'
        })
    }

    const getValidInductions = async (visit_id) => {
        try {
            const response = await validInductionsGet(visit_id);
            if (response.ok) {
                dispatch({
                    type: 'GET_VALID_INDUCTIONS',
                    payload: response.data
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_VISITS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_VISITS',
            })
            console.log(e);
        }
    }

    const sending = () => {
        dispatch({
            type: 'SENDING'
        })
    }

    const sendInductions = async (values, toggle, setSelectedInductions, getCallback) => {
        try {
            sending();
            const response = await inductionsSend(values);
            if (response.ok) {
                dispatch({
                    type: 'SEND_INDUCTION',
                    payload: response.data,
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                    setSelectedInductions([])
                }, 1000);

            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'SEND_INDUCTION_ERROR',
                        })
                        break;
                }
            }
        }
        catch (e) {
            dispatch({
                type: 'SEND_INDUCTION_ERROR',
            })
            console.log(e);
        }
    }

    const revokeInduction = async (visit, toggle, getCallback) => {
        try {
            const response = await inductionRevoke(visit);
            if (response.ok) {
                dispatch({
                    type: 'REVOKE',
                    payload: response.data,
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'STATUS_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'STATUS_ERROR',
                payload: i18n.t('common.error_server')
            })
            setTimeout(() => {
                dispatch({
                    type: 'CLEAR_ALERTS',
                })
            }, 3000);
            console.log(e)
        }
    }

    const grantToInduction = async (visit, toggle, getCallback) => {
        try {
            const response = await inductionGrant(visit);
            if (response.ok) {
                dispatch({
                    type: 'GRANT',
                    payload: response.data,
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'STATUS_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'STATUS_ERROR',
                payload: i18n.t('common.error_server')
            })
            setTimeout(() => {
                dispatch({
                    type: 'CLEAR_ALERTS',
                })
            }, 3000);
            console.log(e)
        }
    }

    return (
        <VisitContext.Provider
            value={{
                visitState: state,
                getVisists,
                createVisist,
                getVisit,
                updateVisit,
                reSendMail,
                deleteVisit,
                upddateVisitFromVisit,
                changePageVisit,
                getValidInductions,
                sendInductions,
                revokeInduction,
                grantToInduction
            }}
        >
            {children}
        </VisitContext.Provider>
    )
}
