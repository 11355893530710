import React from 'react'
import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/loginService';
import { getProfiles } from '../../services/profilesService';
import { createUser, deleteUser, getUser, getUsers, updateUser } from '../../services/usersService';
import UsersContext from "./UsersContext";
import UsersReducer from "./UsersReducer";
import i18n from "i18next";
import { getUserDataByKey } from '../../helpers/helpers';

export const UsersState = ({ children }) => {
    const initialState = {
        users: [],
        profiles: [],
        totalPages: null,
        userSelected: null,
        isFetching: true,
        changingPage: false,
        sending: false,
        error: false,
        message: '',
        inputError: false,
        alert: false,
        deleteError: false,
        alertDelete: false,
    }

    const userId = getUserDataByKey('_id');

    const [state, dispatch] = useReducer(UsersReducer, initialState);

    const navigate = useNavigate();

    const profilesGet = async () => {
        try {
            const response = await getProfiles();
            if (response.ok) {
                dispatch({
                    type: 'GET_PROFILES',
                    payload: response.data
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_PROFILES',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_PROFILES',
            })
            console.log(e);
        }
    }

    const usersGet = async (page, userName, firtName, lastName, profile, active, isGeneric) => {
        try {
            const response = await getUsers(page, userName, firtName, lastName, profile, active, isGeneric);
            if (response.ok) {
                dispatch({
                    type: 'GET_USERS',
                    payload: response.data
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_USERS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_USERS',
            })
            console.log(e);
        }
    }

    const register = async (user) => {
        try {
            const response = await createUser(user);
            if (response.ok) {
                dispatch({
                    type: 'CREATE_USER',
                    payload: response.data
                })
                setTimeout(() => {
                    navigate('/users')
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 409:
                        dispatch({
                            type: 'ERROR_USER_NAME',
                        })
                        break;
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_USERS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_USERS',
            })
            console.log(e);
        }
    }

    const userGet = async (id) => {
        try {
            const response = await getUser(id);
            if (response.ok) {
                dispatch({
                    type: 'GET_USER',
                    payload: response.data
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_USERS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_USERS',
            })
            console.log(e);
        }
    }

    const userUpdate = async (id, user) => {
        try {
            const response = await updateUser(id, user);
            if (response.ok) {
                dispatch({
                    type: 'UPDATE_USER',
                    payload: response.data
                })
                setTimeout(() => {
                    if (id === userId) {
                        logout();
                    } else {
                        navigate('/users');
                    }
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 409:
                        dispatch({
                            type: 'ERROR_USER_NAME',
                        })
                        break;
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_USERS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_USERS',
            })
            console.log(e);
        }
    }

    const userDelete = async (id, toggle, getCallback) => {
        try {
            const response = await deleteUser(id);
            if (response.ok) {
                dispatch({
                    type: 'DELETE_USER',
                    payload: id
                })
                setTimeout(() => {
                    toggle();
                    getCallback();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 406:
                        dispatch({
                            type: 'DELETE_USER_ERROR',
                            payload: i18n.t('common.error_relations')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                    default:
                        dispatch({
                            type: 'DELETE_USER_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'DELETE_USER_ERROR',
                payload: i18n.t('common.error_server')
            })
            console.log(e);
        }
    }

    const changePageUsers = () => {
        dispatch({
            type: 'CHANGE_PAGE'
        })
    }

    return (
        <UsersContext.Provider value={{
            usersState: state,
            profilesGet,
            usersGet,
            register,
            userGet,
            userUpdate,
            userDelete,
            changePageUsers
        }}>
            {children}
        </UsersContext.Provider>
    )
}
