import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { CustomAlert } from '../../components/CustomAlert/CustomAlert';
import { EmployeeDetailFilter } from '../../components/Employee/EmployeeDetailFilter'
import { EmployeeDetailTable } from '../../components/Employee/EmployeeDetailTable';
import { LocalPaginationCustom } from '../../components/LocalPaginationCustom/LocalPaginationCustom';
import { EmployeeContext } from '../../context/Employee/EmployeeContext';
import { getCurrentItems } from '../../helpers/helpers';

export const EmployeeDetail = () => {

    const { id, contractorId } = useParams();

    const { t } = useTranslation();

    const { getDetail, employeeState } = useContext(EmployeeContext);

    const { employees, error, isFetching, message, visualizationId } = employeeState;

    const initialStateFilter = {
        employee_name: '',
        employee_code: '',
        employee_viewed: '',
        employee_approved: '',
    }

    const initialStateHeaders = [
        { key: 'employee_name', label: t('employee.employee_name'), order: 'asc', active: true },
        { key: 'employee_viewed', label: t('inductions.seen'), order: 'asc', active: false },
        { key: 'employee_approved', label: t('employee.exam_passed'), order: 'asc', active: false },
        { key: 'note', label: t('employee.score_exam'), order: 'asc', active: false },
        { key: 'attempts', label: t('employee.attemps_exam'), order: 'asc', active: false },
        { key: 'date_from', label: t('inductions.since_only'), order: 'asc', active: false },
        { key: 'date_to', label: t('inductions.until_only'), order: 'asc', active: false },

    ]

    const [stateEmployees, setStateEmployees] = useState([]);

    const [filter, setFilter] = useState(initialStateFilter);

    const [headers, setHeaders] = useState(initialStateHeaders)

    const [errorFilter, setErrorFilter] = useState(false);

    const [page, setPage] = useState(1);

    const { employee_name, employee_code, employee_viewed, employee_approved } = filter;

    const navigate = useNavigate();

    const currentEmployees = getCurrentItems(stateEmployees, page)

    const search = () => {
        const filterQuery = employees.filter((employee) =>
            employee.employee_name.toLocaleLowerCase().includes(employee_name.toLocaleLowerCase()) &&
            employee.employee_code.toLocaleLowerCase().includes(employee_code.toLocaleLowerCase()) &&
            String(employee.employee_viewed).includes(employee_viewed) &&
            String(employee.employee_approved).includes(employee_approved)
        )
        setPage(1)
        setStateEmployees(filterQuery);
        setErrorFilter(true);
    }

    const orderBy = (order) => {
        const filterQuery = [...stateEmployees].sort(order)
        setStateEmployees(filterQuery);
    }

    const clean = () => {
        setPage(1)
        setFilter(initialStateFilter);
        setHeaders(initialStateHeaders);
        setStateEmployees(employees);
        setErrorFilter(false);
    }

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilter({
            ...filter,
            [name]: value
        })
    }

    useEffect(() => {
        getDetail(id, contractorId)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, contractorId])

    useEffect(() => {
        setStateEmployees(employees.sort((a, b) => a.employee_name.localeCompare(b.employee_name)))
    }, [employees])


    return (
        <div>
            <div>
                <h4>{t('employee.detail_employee')}</h4>
            </div>
            <EmployeeDetailFilter
                handleChangeFilter={handleChangeFilter}
                search={search}
                clean={clean}
                filter={filter}
            />
            <hr className='bg-dark' />
            {
                isFetching ?
                    <div className='d-flex justify-content-center'>
                        <Spinner size={'xxl'} />
                    </div>
                    :
                    stateEmployees.length > 0 ?
                        <>
                            <EmployeeDetailTable
                                employees={currentEmployees}
                                visualizationId={visualizationId}
                                orderBy={orderBy}
                                headers={headers}
                                setHeaders={setHeaders}
                            />
                            <LocalPaginationCustom page={page} totalItems={stateEmployees.length} setPage={setPage} />
                        </>
                        :
                        stateEmployees.length === 0 && errorFilter && !error ?
                            <div className='d-flex justify-content-center'>{t('common.error_filter')}</div>
                            :
                            stateEmployees.length === 0 && !errorFilter && !error && !isFetching ?
                                <div className='d-flex justify-content-center'>{t('common.not_loaded')}</div>
                                :
                                null
            }
            {error ? <CustomAlert text={message} color={'danger'} /> : null}
            <div className='d-flex justify-content-center gap-4 mt-3'>
                <Button
                    color="secondary"
                    onClick={() => navigate(`/induction/details/contractor/${id}`)}
                >
                    {t('common.came_back')}
                </Button>
            </div>
        </div>
    )
}
