import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom';
import { classificationGet, classificationsCreate, classificationsDelete, classificationsGet, classificationsUpdate } from '../../services/classificationsService';
import { logout } from '../../services/loginService';
import ClassificationsContext from './ClassificationsContext'
import ClassificationsReducer from "./ClassificationsReducer";
import i18n from "i18next";
export const ClassificationsState = ({ children }) => {
    const initialState = {
        classifications: [],
        classificationSelected: null,
        isFetching: true,
        sending: false,
        error: false,
        message: '',
        inputError: false,
        inputErrorMessage: '',
        alert: false,
        deleteError: false,
        alertDelete: false,
    }
    const [state, dispatch] = useReducer(ClassificationsReducer, initialState)
    const navigate = useNavigate();

    const getClassifications = async (active) => {
        try {
            const response = await classificationsGet(active);
            if (response.ok) {
                dispatch({
                    type: 'GET_CLASSIFICATIONS',
                    payload: response.data
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CLASSIFICATIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_CLASSIFICATIONS',
            })
            console.log(e);
        }
    }
    const createClassifications = async (classification) => {
        try {
            const response = await classificationsCreate(classification);
            if (response.ok) {
                dispatch({
                    type: 'CREATE_CLASSIFICATION',
                    payload: response.data
                })
                setTimeout(() => {
                    navigate('/classifications')
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 409:
                        dispatch({
                            type: 'ERROR_CLASSIFICATION_CODE',
                        })
                        break;
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CLASSIFICATIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_CLASSIFICATIONS',
            })
            console.log(e);
        }
    }
    const getClassification = async (id) => {
        try {
            const response = await classificationGet(id);
            if (response.ok) {
                dispatch({
                    type: 'GET_CLASSIFICATION',
                    payload: response.data
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CLASSIFICATIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_CLASSIFICATIONS',
            })
            console.log(e);
        }
    }
    const updateClassifications = async (id, classification) => {
        try {
            const response = await classificationsUpdate(id, classification);
            if (response.ok) {
                dispatch({
                    type: 'UPDATE_CLASSIFICATION',
                    payload: response.data
                })
                setTimeout(() => {
                    navigate('/classifications')
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 409:
                        dispatch({
                            type: 'ERROR_CLASSIFICATION_CODE',
                        })
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_CLASSIFICATIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_CLASSIFICATIONS',
            })
            console.log(e);
        }
    }
    const deleteClassifications = async (id, toggle) => {
        try {
            const response = await classificationsDelete(id);
            if (response.ok) {
                dispatch({
                    type: 'DELETE_CLASSIFICATION',
                    payload: id
                })
                setTimeout(() => {
                    dispatch({
                        type: 'CLEAR_ALERTS',
                    })
                    toggle();
                }, 1000);
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    case 406:
                        dispatch({
                            type: 'DELETE_CLASSIFICATION_ERROR',
                            payload: i18n.t('common.error_relations')
                        })
                        setTimeout(() => {
                            dispatch({
                                type: 'CLEAR_ALERTS',
                            })
                        }, 3000);
                        break;
                    default:
                        dispatch({
                            type: 'DELETE_CLASSIFICATION_ERROR',
                            payload: i18n.t('common.error_server')
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'DELETE_CLASSIFICATION_ERROR',
                payload: i18n.t('common.error_server')
            })
            console.log(e);
        }
    }
    return (
        <ClassificationsContext.Provider
            value={{
                getClassifications,
                classificationsState: state,
                createClassifications,
                getClassification,
                updateClassifications,
                deleteClassifications
            }}
        >
            {children}
        </ClassificationsContext.Provider>
    )
}
