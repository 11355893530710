import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Spinner } from 'reactstrap'
import VisitContext from '../../context/Visit/VisitContext';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

export const VisitDetail = ({ children }) => {

    const { id } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { visitState, getVisit } = useContext(VisitContext);

    const { error, message, visitSelected } = visitState;

    useEffect(() => {
        getVisit(id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])



    return (
        <Container className='bg-white mt-5 pb-5 ps-5 pe-5 pt-5 rounded-3'>

            <div className='d-flex justify-content-between'>
                {
                    visitSelected && !error ?
                        <div>
                            <h4>{visitSelected.last_name} {visitSelected.first_name}</h4>
                            <h5>{visitSelected.document_number ? visitSelected.document_number : '-'}</h5>
                            <h6>{visitSelected.email}</h6>
                        </div>
                        :
                        error ?
                            <div className='mx-auto'>
                                <CustomAlert color={'danger'} text={message} />
                            </div>
                            :
                            <Spinner />
                }
                <div>
                    <CustomTooltip
                        placement={"top"}
                        target={"get_back"}
                        text={t('common.came_back')}
                    />
                    <Button
                        onClick={() => navigate('/visits')}
                    >
                        <i
                            className="fa-solid fa-arrow-left"
                            id='get_back'
                        >
                        </i>
                    </Button>
                </div>
            </div>
            <hr className='bg-dark' />
            {children}
        </Container>
    )
}
