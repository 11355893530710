import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logo from '../../assets/images/exactian-logo.png'
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown, Collapse } from 'reactstrap';
import { logout } from '../../services/loginService';
import { useTranslation } from 'react-i18next';
import { getTenantByKey, getUserDataByKey, isIntegrated, isUnique } from '../../helpers/helpers';
import { useBasePath } from '../../customHooks/useBasePath';

export const NavigationBar = ({ name, setModal }) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const { t } = useTranslation();

    const location = useBasePath();

    const profile = getUserDataByKey('profile');

    const classificationsName = getTenantByKey('classification_name');

    const isVisible = (roles = []) => roles.some(role => role === profile);

    const isOnScreen = (onScreenOptions = []) => onScreenOptions.some(onScreenOption => onScreenOption === location);

    const navOptions = [
        {
            key: t('layout.management.management'),
            options: [
                {
                    key: t('layout.management.inductions'),
                    navigate: '/induction',
                    onlyFor: ['01', '02'],
                },
                ...(isUnique() ?
                    [{
                        key: t('layout.management.users'),
                        navigate: '/users',
                        onlyFor: ['01'],
                    }]
                    :
                    []
                ),
                ...(isUnique() ?
                    [{
                        key: classificationsName,
                        navigate: '/classifications',
                        onlyFor: ['01'],
                    }]
                    :
                    []
                ),
                ...(isIntegrated() ?
                    [{
                        key: t('questionary.questionary_plural'),
                        navigate: '/questionary',
                        onlyFor: ['01'],
                    }]
                    :
                    []
                ),
            ],
            onlyFor: ['01', '02'],
            onScreen: [
                "/induction",
                "/induction/new",
                "/induction/edit",
                "/induction/details/contractor",
                "/induction/details/employee",
                "/induction/send/contractor",
                "/induction/send/employee",
                "/users",
                "/users/new",
                "/users/edit",
                "/classifications",
                "/classifications/new",
                "/classifications/edit",
                "/questionary",
                "/questionary/new",
                "/questionary/edit",
            ],
        },
        {
            key: t('layout.visits.visits'),
            options: [
                {
                    key: t('layout.visits.newVisits'),
                    navigate: '/visits/new',
                    onlyFor: ['01', '02'],
                },
                {
                    key: t('layout.visits.management'),
                    navigate: '/visits',
                    onlyFor: ['01'],
                },
            ],
            onlyFor: ['01', '02'],
            onScreen: [
                "/visits",
                "/visits/new",
                "/visits/edit",
                "/visits/send",
            ],
        },
    ];

    const toggle = () => setDropdownOpen(!dropdownOpen);

    return (
        <Navbar dark expand="md" className="bg_exactian_gradient">
            <NavbarBrand className="me-auto">
                <img src={logo} className="img-fluid logo" alt="Exactian logo" />
            </NavbarBrand>
            <Nav navbar>
                <NavbarToggler onClick={toggle} />
                <Collapse navbar isOpen={dropdownOpen}>
                    {navOptions.map(({ onlyFor, key, onScreen, options }) =>
                    (isVisible(onlyFor) && (
                        <UncontrolledDropdown key={key}>
                            <DropdownToggle
                                className={(isOnScreen(onScreen)) ? 'active' : null}
                                caret
                                nav
                            >
                                {key}
                            </DropdownToggle>
                            <DropdownMenu>
                                {options.map(({ onlyFor, key, navigate }) =>
                                (isVisible(onlyFor) && (
                                    <Link
                                        key={key}
                                        to={navigate}
                                    >
                                        <DropdownItem className="d-flex align-items-baseline">
                                            {key}
                                        </DropdownItem>
                                    </Link >
                                ))
                                )}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    ))
                    )}
                    <UncontrolledDropdown>
                        <DropdownToggle caret nav>{name}</DropdownToggle>
                        <DropdownMenu>
                            {
                                isUnique() ?
                                    <>
                                        <DropdownItem onClick={() => setModal(true)} className="d-flex align-items-baseline">
                                            <i className="fa-solid fa-gear icon-style"></i><span className='px-1'>{t('layout.user.changePassword')}</span>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                    </>
                                    :
                                    null
                            }
                            <DropdownItem onClick={logout} className="d-flex align-content-end">
                                <i className="fa-solid fa-right-from-bracket icon-style"></i><span style={{ marginTop: '-1px' }} className='px-1'>{t('layout.user.loguot')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Collapse>
            </Nav>
        </Navbar>
    )

}