import React from 'react'
import { itemsPerPage } from '../../helpers/helpers';
import ReactPaginate from "react-paginate";

export const LocalPaginationCustom = ({ page, totalItems, setPage }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            forcePage={(page - 1)}
            onPageChange={(number) => setPage(number.selected + 1)}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
        />
    )
}
