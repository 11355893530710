import React, { useContext } from 'react'
import { Container, Spinner } from 'reactstrap'
import TenantContext from '../../context/Tenant/TenantContext'
import ExamContext from '../../context/Exam/ExamContext';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import 'react-circular-progressbar/dist/styles.css';
import { ExamCertificate } from './ExamCertificate';

export const ExamResult = () => {

    const { id, employeeId, visitId } = useParams();

    const { getTenant, tenant } = useContext(TenantContext);

    const { examState, getExam, getExamForVisit } = useContext(ExamContext);
    const { exam } = examState;

    useEffect(() => {
        getTenant();
        if (exam === null) {
            if (employeeId) {
                getExam(id, employeeId);
            }
            if (visitId) {
                getExamForVisit(id, visitId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (tenant.error) {
        return <Navigate to={'/login'} replace />
    }

    if (tenant.isFetching || examState.sending || examState.isFetching) {
        return (
            <div className='position-absolute top-50 start-50'>
                <Spinner size={'xl'} />
            </div>
        )
    }

    if (exam && exam.viewed === false) {
        return <Navigate to={employeeId ? `/exam/${id}/employee/${employeeId}` : `/exam/${id}/visit/${visitId}`} replace />
    }

    return (
        <Container className="bg-white pb-5 pt-5 mt-5 rounded-3" fluid="md">
            <ExamCertificate
                {...exam}
                {...exam?.visit}
                isEmployee={!!employeeId}
                isVisit={!!visitId}
                tryAgainLink={employeeId ? `/exam/${id}/employee/${employeeId}` : `/exam/${id}/visit/${visitId}`}
            />
            {examState.error && (
                <div className='mt-5'>
                    <CustomAlert color={'danger'} text={examState.message} />
                </div>
            )}
        </Container>
    )

}
