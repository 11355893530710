import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip'

export const VisitIntegratedList = ({ visit, handleModal }) => {
    const { t } = useTranslation();

    const status = {
        A: {
            badge: 'badge bg-success',
            label: t('common.suitable')
        },
        N: {
            badge: 'badge bg-danger',
            label: t('common.no_suitable')
        },
        P: {
            badge: 'badge bg-warning',
            label: t('common.earring')
        }
    }

    return (
        <tr>
            <td>
                {visit.last_name}, {visit.first_name}
            </td>
            <td>
                {visit.email}
            </td>
            <td>
                {visit.document_number ? visit.document_number : '-'}
            </td>
            <td>
                {visit.user_created}
            </td>
            <td >
                {
                    visit.status === 'P' ?
                        <CustomTooltip
                            placement={"top"}
                            target={"status"}
                            text={t('visits.earring_to_assing')}
                        />
                        :
                        null
                }
                <span
                    style={{ minWidth: '78px' }}
                    className={status[visit.status].badge}
                    id={visit.status === 'P' ? 'status' : ''}
                >
                    {status[visit.status].label}
                </span>
            </td>
            <td className='d-flex gap-3 align-items-center flex-wrap'>

                <CustomTooltip
                    placement={"top"}
                    target={`send-${visit._id}`}
                    text={t('common.send_induction')}
                />
                <Link
                    style={{ color: 'black' }}
                    to={`send/${visit._id}`}
                    id={`send-${visit._id}`}
                >
                    <i className="fa-solid fa-paper-plane"></i>
                </Link>

                <CustomTooltip
                    placement={"top"}
                    target={`edit-${visit._id}`}
                    text={t('common.action_edit')}
                />
                <Link
                    style={{ color: 'black' }}
                    to={`edit/${visit._id}`}
                    id={`edit-${visit._id}`}
                >
                    <i className="fa-solid fa-file-pen"></i>
                </Link>

                <CustomTooltip
                    placement={"top"}
                    target={`delete-${visit._id}`}
                    text={t('common.action_delete')}
                />
                <i

                    className="fa-solid fa-trash"
                    onClick={() => handleModal(visit._id)}
                    type='button'
                    id={`delete-${visit._id}`}
                >
                </i>
            </td>
        </tr>
    )
}
