import { customFetch } from "../helpers/customFetch";
import { URL, getConfig, getUserDataByKey } from "../helpers/helpers";

export const inductionsCreate = async (values) => {
    try {
        const formData = new FormData();
        for (const key in values) {
            formData.set(key, values[key]);
        }
        var configs = {
            headers: {
                'auth-token': `${getUserDataByKey('token')}`,
            },
            method: 'POST',
            body: formData,
        };
        const response = await customFetch(`${URL}inductions/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionsGet = async (page, name, description, date_from, date_to, status, active) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}inductions/?page=${page}&name=${name}&description=${description}&date_from=${date_from}&date_to=${date_to}&status=${status}&active=${active}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionsContractorGet = async (contractorId, page, name, active) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}inductions/contractor/${contractorId}/?page=${page}&name=${name}&active=${active}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionsDelete = async (id) => {
    try {
        const configs = getConfig('DELETE');
        const response = await customFetch(`${URL}inductions/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionsUpdate = async (id, values) => {
    try {
        const configs = getConfig('PUT', values);
        const response = await customFetch(`${URL}inductions/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionsExpire = async (id) => {
    try {
        const configs = getConfig('PUT');
        const response = await customFetch(`${URL}inductions/expire/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionGet = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}inductions/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionGetByContractor = async (id, contractorId) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}inductions/viewContractor/${id}/${contractorId}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionsValidGet = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}inductions/valid/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionDownload = async (fileName) => {
    try {
        const configs = getConfig('POST', { file_name: fileName });
        const response = await customFetch(`${URL}inductions/file`, configs);
        const resData = await response.blob();
        if (!response.ok) {
            return {
                ok: false,
                data: response,
            };
        } else {
            return {
                ok: true,
                data: resData,
            };
        }
    } catch (e) {
        console.log(e);
    }
};

export const inductionPreviewGet = async (file_name, visit_id) => {
    try {
        const configs = getConfig('POST', { file_name: file_name, visit_id: visit_id });
        const response = await customFetch(`${URL}inductions/viewfile`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionPreviewDeleteURL = async (file_name, visit_id) => {
    try {
        const configs = getConfig('DELETE', { file_name: file_name, visit_id: visit_id });
        const response = await customFetch(`${URL}inductions/viewfile`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionsStatusGet = async (id) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}inductions/status/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}