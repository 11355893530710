import { customFetch } from "../helpers/customFetch";
import { URL, getConfig } from "../helpers/helpers";

export const examGet = async (inductionId, employeeId) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visualizations/viewedInductionByEmployee/${inductionId}/${employeeId}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const inductionSeen = async (inductionValues, visualizationId) => {
    try {
        const configs = getConfig('POST', inductionValues);
        const response = await customFetch(`${URL}visualizations/viewedInduction/${visualizationId}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const questionaryPost = async (questionaryValues, visualizationId) => {
    try {
        const configs = getConfig('POST', questionaryValues);
        const response = await customFetch(`${URL}visualizations/answerQuestionary/${visualizationId}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const examGetForVisit = async (inductionId, visitId) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visualizations_visit/${visitId}/${inductionId}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}


export const inductionSeenForVisit = async (values) => {
    try {
        const configs = getConfig('POST', values);
        const response = await customFetch(`${URL}visualizations_visit/viewedInduction/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const questionaryPostForVisit = async (questionaryValues) => {
    try {
        const configs = getConfig('POST', questionaryValues);
        const response = await customFetch(`${URL}visualizations_visit/answerQuestionary/`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}