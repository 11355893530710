import React, { useReducer } from 'react'
import { employeeClassificationGet, employeeClassificationGetByInduction } from '../../services/employeeClassificationService';
import { logout } from '../../services/loginService';
import EmployeeClassificationContext from './EmployeeClassificationContext';
import EmployeeClassificationReducer from './EmployeeClassificationReducer';

export const EmployeeClassificationState = ({ children }) => {
    const initialState = {
        employeeClassifications: [],
        isFetching: true,
        error: false,
        message: '',
        tokenError: false
    }

    const [state, dispatch] = useReducer(EmployeeClassificationReducer, initialState);

    const getEmployeeClassifications = async () => {
        try {
            const response = await employeeClassificationGet()
            if (response.ok) {
                dispatch({
                    type: 'GET_EMPLOYEE_CLASSIFICATIONS',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_EMPLOYEE_CLASSIFICATIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_EMPLOYEE_CLASSIFICATIONS',
            })
            console.log(e)
        }
    }

    const getEmployeeClassificationsByInduction = async (id) => {
        try {
            const response = await employeeClassificationGetByInduction(id)
            if (response.ok) {
                dispatch({
                    type: 'GET_EMPLOYEE_CLASSIFICATIONS',
                    payload: response.data,
                })
            } else {
                switch (response.data.status) {
                    case 401:
                        dispatch({
                            type: 'UNATORIZED',
                        })
                        setTimeout(() => {
                            logout();
                        }, 1000);
                        break;
                    default:
                        dispatch({
                            type: 'ERROR_EMPLOYEE_CLASSIFICATIONS',
                        })
                        break;
                }
            }
        } catch (e) {
            dispatch({
                type: 'ERROR_EMPLOYEE_CLASSIFICATIONS',
            })
            console.log(e)
        }
    }


    return (
        <EmployeeClassificationContext.Provider
            value={{
                employeeClassificationsState: state,
                getEmployeeClassifications,
                getEmployeeClassificationsByInduction
            }}
        >
            {children}
        </EmployeeClassificationContext.Provider>
    )
}
