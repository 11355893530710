import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Spinner } from 'reactstrap'
import { ClassificationsTable } from '../../components/Classifications'
import { CustomAlert } from '../../components/CustomAlert/CustomAlert'
import { Resource } from '../../components/Resource/Resource'
import ClassificationsContext from '../../context/Classifications/ClassificationsContext'
import { getTenantByKey } from '../../helpers/helpers'

export const Classifications = () => {
  const { getClassifications, classificationsState } = useContext(ClassificationsContext)
  const { error, message, isFetching, classifications } = classificationsState;

  const { t } = useTranslation();

  const classificationsName = getTenantByKey('classification_name');
  const classificationsSingularName = getTenantByKey('singular_classification_name');


  useEffect(() => {
    getClassifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Container
      className='bg-white mt-5 pb-5 ps-5 pe-5 pt-5 rounded-3'>
      <Resource title={classificationsName} resource={`${t('classifications.new')} ${classificationsSingularName}`} newResource={'classifications'} />
      <hr className='bg-dark' />
      {
        isFetching ?
          <div className='d-flex justify-content-center'>
            <Spinner size={'xxl'} />
          </div>
          :
          classifications.length > 0 ?
            <ClassificationsTable classifications={classifications} />
            :
            classifications.length === 0 && !error && !!!isFetching ?
              <div className='d-flex justify-content-center'>{t('common.not_loaded')}</div>
              :
              null
      }
      {error ? <CustomAlert text={message} color={'danger'} /> : null}
    </Container>
  )
}
