import React, { useState } from 'react'
import ReactPlayer from 'react-player'

export const CustomPlayer = ({ url, videoPercentage, setWasSeen }) => {
    const [duration, setDuration] = useState(null);
    const handleSeen = (progress) => {
        if (progress.playedSeconds >= duration) {
            setWasSeen(true);
        }
    }
    return (
        <>
            <ReactPlayer
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                controls={true}
                url={url}
                width={'100%'}
                height={'100%'}
                onDuration={(duration) => setDuration(Math.floor((duration / 100) * videoPercentage))}
                onProgress={(progress) => handleSeen(progress)}
            />
        </>
    )
}
