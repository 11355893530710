import { Route, Routes } from "react-router-dom"
import { Questionary } from "../containers/Questionary/Questionary"
import { QuestionaryForm } from "../components/Questionary/QuestionaryForm"

export const QuestionaryRoutes = () => {
    return (
        <Routes>
            
            <Route
                path="/"
                element={<Questionary />}
            />

            <Route
                path="new"
                element={<QuestionaryForm />}
            />

            <Route
                path="edit/:id"
                element={<QuestionaryForm />}
            />

        </Routes>
    )
}
