import { Navigate, Route, Routes } from "react-router-dom"
import { Main } from "../containers/Main/Main"
import { VisitRoutes } from "./VisitRoutes"
import { InductionRoutes } from "./InductionRoutes"
import { UniqueRoutes } from "../components/UniqueRoutes/UniqueRoutes"
import { ProfileRoutes } from "../components/ProfileRoutes/ProfileRoutes"
import { UsersRoutes } from "./UsersRoutes"
import { ClassificationsRoutes } from "./ClassificationsRoutes"
import { IntegratedRoutes } from "../components/IntegratedRoutes/IntegratedRoutes"
import { QuestionaryRoutes } from "./QuestionaryRoutes"
import { isUnique } from "../helpers/helpers"

export const MainRoutes = () => {
    return (
        <Main>
            <Routes>

                <Route
                    path="visits/*"
                    element={<VisitRoutes />}
                />

                <Route
                    path="induction/*"
                    element={<InductionRoutes />}
                />

                <Route
                    path="users/*"
                    element={
                        <UniqueRoutes>
                            <ProfileRoutes validRoles={['01']}>
                                <UsersRoutes />
                            </ProfileRoutes>
                        </UniqueRoutes>
                    }
                />

                <Route
                    path="classifications/*"
                    element={
                        <UniqueRoutes>
                            <ProfileRoutes validRoles={['01']}>
                                <ClassificationsRoutes />
                            </ProfileRoutes>
                        </UniqueRoutes>
                    }
                />

                <Route
                    path="questionary/*"
                    element={
                        <IntegratedRoutes>
                            <ProfileRoutes validRoles={['01']}>
                                <QuestionaryRoutes />
                            </ProfileRoutes>
                        </IntegratedRoutes>
                    }
                />

                <Route
                    path="*"
                    element={<Navigate to={isUnique() ? '/visit' : '/induction'} replace />}
                />

            </Routes>
        </Main>
    )
}
