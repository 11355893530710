import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Container, Spinner } from 'reactstrap';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import { InductionDownloadOrPreview } from './InductionDownloadOrPreview';
import InductionContext from '../../context/Induction/InductionContext';
import QuestionaryContext from '../../context/Questionary/QuestionaryContext';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { getUserDataByKey } from '../../helpers/helpers';
import { QuestionaryModal } from '../Questionary/QuestionaryModal';
export const InductionDetails = ({ children }) => {

    const { t } = useTranslation();

    const { inductionState, getInduction } = useContext(InductionContext);
    const { error, message, inductionSelected } = inductionState

    const { getQuestionnaires, questionaryState } = useContext(QuestionaryContext);
    const { questionnaires } = questionaryState;

    const { id, contractorId } = useParams();

    const navigate = useNavigate();

    const isLastVersion = inductionSelected && inductionSelected.questionary ? questionnaires.some((questionary) => questionary._id === inductionSelected.questionary._id) : false

    const { pathname: location } = useLocation();

    const styleOptions = {
        [`/induction/send/contractor/${id}`]: 'bg-white mt-2 pb-1 ps-5 pe-5 pt-4 rounded-3',
        [`/induction/details/contractor/${id}`]: 'bg-white mt-5 pb-5 ps-5 pe-5 pt-5 rounded-3',
        [`/induction/send/employee/${id}`]: 'bg-white mt-2 pb-1 ps-5 pe-5 pt-4 rounded-3',
        [`/induction/details/employee/${id}/${contractorId}`]: 'bg-white mt-5 pb-5 ps-5 pe-5 pt-5 rounded-3',
    }
    const [modal, setModal] = useState(false);

    useEffect(() => {
        getInduction(id);
        getQuestionnaires('', true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <Container className={styleOptions[location]}>
            {modal ? <QuestionaryModal modal={modal} setModal={setModal} questionary={inductionSelected.questionary} /> : null}
            <div className='d-flex justify-content-between'>
                {
                    inductionSelected && !error ?
                        <div>
                            <h4>{inductionSelected.name}</h4>
                            <i>{inductionSelected.description ? inductionSelected.description : '-'}</i>
                            <InductionDownloadOrPreview induction={inductionSelected} />
                            <div className='d-flex align-items-center gap-2 mt-1 flex-wrap'>
                                {inductionSelected.questionary ?
                                    <span onClick={() => setModal(true)} className='link-primary fw-bold' style={{ cursor: 'pointer' }}>{inductionSelected.questionary.name} <small>(v{inductionSelected.questionary.version}.0)</small> </span>
                                    :
                                    <span>{t('inductions.no_questionary')}</span>
                                }
                                {
                                    inductionSelected.questionary && isLastVersion === false && getUserDataByKey('profile') !== '04' ?
                                        <div className='d-flex align-items-center gap-2 flex-wrap'>
                                            <Badge color='warning'>{t('inductions.new_questionary_version')}</Badge>

                                            {
                                                getUserDataByKey('profile') !== '02' && getUserDataByKey('profile') !== '03' ?
                                                    <>
                                                        <CustomTooltip
                                                            placement={"top"}
                                                            target={"edit"}
                                                            text={t('common.action_edit')}
                                                        />
                                                        <Link
                                                            style={{ color: 'black', width: '14px' }}
                                                            to={`/edit/induction/${id}`}
                                                        >
                                                            <i
                                                                className="fa-solid fa-file-pen"
                                                                id='edit'
                                                            >
                                                            </i>
                                                        </Link>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <h6 className='mt-1'>{t('inductions.induction_validity_only')} {inductionSelected.expiration_days} {t('inductions.induction_validity_only_days')}</h6>
                        </div >
                        :
                        error ?
                            <div className='mx-auto'>
                                <CustomAlert color={'danger'} text={message} />
                            </div>
                            :
                            <Spinner />
                }
                <div>
                    <CustomTooltip
                        placement={"top"}
                        target={"get_back"}
                        text={t('common.came_back')}
                    />
                    <Button
                        onClick={() => navigate('/induction')}
                    >
                        <i
                            className="fa-solid fa-arrow-left"
                            id='get_back'
                        >
                        </i>
                    </Button>
                </div>
            </div >
            <hr className='bg-dark' />
            <div className='mt-5'>
                {children}
            </div>
        </Container >
    )
}
