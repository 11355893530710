import { CHANGE_PAGE, CLEAN_EMPLOYEES, CLEAR_ALERTS, ERROR_EMPLOYEES, GET_DETAIL, GET_EMPLOYEES, GRANT_INDUCTION, REVOKE_INDUCTION, SENDING, SEND_INDUCTION, SEND_INDUCTION_ERROR, STATUS_ERROR, UNATORIZED } from './types';
import i18n from "i18next";

export const EmployeeReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_EMPLOYEES:
            return {
                ...state,
                employees: payload.employees,
                totalPages: payload.total_pages,
                visualizationId: payload.visualization_id,
                visualizationMessage: payload.contractor_viewed.message,
                employeeSelected: null,
                isFetching: false,
                changingPage: false,
                sending: false,
                error: false,
                message: '',
                alert: false,
                errorSend: false,
                alertStatus: false,
                errorStatus: false,
            }
        case UNATORIZED:
            return {
                ...state,
                employees: [],
                visualizationId: null,
                totalPages: null,
                employeeSelected: null,
                isFetching: false,
                sending: false,
                error: true,
                message: i18n.t('common.error_token'),
                alert: false,
                errorSend: true,
                alertStatus: false,
                errorStatus: true,
            }
        case ERROR_EMPLOYEES:
            return {
                ...state,
                employeeSelected: null,
                isFetching: false,
                sending: false,
                error: true,
                message: i18n.t('common.error_server'),
                alert: false,
                errorSend: false
            }
        case SEND_INDUCTION:
            return {
                ...state,
                alert: true,
                errorSend: false,
                sending: true,
            }
        case SEND_INDUCTION_ERROR:
            return {
                ...state,
                alert: false,
                errorSend: true,
                message: i18n.t('common.error_server'),
                sending: false,
            }
        case STATUS_ERROR:
            return {
                ...state,
                alertStatus: false,
                errorStatus: true,
                sending: false,
                message: payload,
            }
        case REVOKE_INDUCTION:
            return {
                ...state,
                alertStatus: true,
                errorStatus: false,
                sending: true,
            }
        case GRANT_INDUCTION:
            return {
                ...state,
                alertStatus: true,
                errorStatus: false,
                sending: true,
            }
        case CLEAR_ALERTS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                alert: false,
                errorSend: false,
                alertStatus: false,
                errorStatus: false,
            }
        case CHANGE_PAGE: {
            return {
                ...state,
                changingPage: true,
            }
        }
        case CLEAN_EMPLOYEES: {
            return {
                ...state,
                employees: [],
                isFetching: true
            }
        }

        case GET_DETAIL:
            return {
                ...state,
                employees: payload.details,
                visualizationId: payload.visualization_id,
                employeeSelected: null,
                isFetching: false,
                changingPage: false,
                sending: false,
                error: false,
                message: '',
                alert: false,
                errorSend: false,
                alertStatus: false,
                errorStatus: false,
            }
        case SENDING: {
            return {
                ...state,
                sending: true
            }
        }
        default:
            return state
    }
}
