import React from 'react'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Table } from 'reactstrap'
import { ExamModal } from '../Employee/ExamModal';
import { RevokeOrGrantModal } from '../RevokeOrGrantModal/RevokeOrGrantModal';
import { ValidInductionsList } from './ValidInductionsList'

export const ValidInductionsTable = ({ inductions, orderBy, selectedInductions, setSelectedInductions, headers, setHeaders, getCallback }) => {

    const [modal, setModal] = useState(false);

    const [modalQuestionary, setModalQuestionary] = useState(false);

    const [values, setValues] = useState(null)

    const [inductionId, setInductionId] = useState(null);

    const [questionary, setQuestionary] = useState(null);

    const currentDate = new Date();

    const handleModal = (values, inductionId) => {
        setModal(true);
        setValues(values)
        setInductionId(inductionId)
    }

    const handleModalQuestionary = (questionary) => {
        setQuestionary(questionary);
        setModalQuestionary(true)
    }

    const { id } = useParams();

    const orderOpitons = {
        'induction': {
            asc: (a, b) => a.induction.name.localeCompare(b.induction.name),
            desc: (a, b) => b.induction.name.localeCompare(a.induction.name)
        },
        'questionary': {
            asc: (a, b) => {
                if (a.questionary && b.questionary) {
                    return a.questionary.name.localeCompare(b.questionary.name) || a.questionary.version - b.questionary.version
                } else if (a.questionary && !b.questionary) {
                    return 1
                } else if (!a.questionary && b.questionary) {
                    return -1
                } else {
                    return 0
                }
            },
            desc: (a, b) => {
                if (a.questionary && b.questionary) {
                    return b.questionary.name.localeCompare(a.questionary.name) || b.questionary.version - a.questionary.version
                } else if (a.questionary && !b.questionary) {
                    return -1
                } else if (!a.questionary && b.questionary) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        'approved': {
            asc: (a, b) => a.approved - b.approved,
            desc: (a, b) => b.approved - a.approved
        },
        'viewed': {
            asc: (a, b) => a.viewed - b.viewed,
            desc: (a, b) => b.viewed - a.viewed
        },
        'sended': {
            asc: (a, b) => a.sended - b.sended,
            desc: (a, b) => b.sended - a.sended
        },
        'note': {
            asc: (a, b) => {
                if (a.exam && b.exam) {
                    return a.exam.note - b.exam.note
                } else if (a.exam && !b.exam) {
                    return 1
                } else if (!a.exam && b.exam) {
                    return -1
                } else {
                    return 0
                }
            },
            desc: (a, b) => {
                if (a.exam && b.exam) {
                    return b.exam.note - a.exam.note
                } else if (a.exam && !b.exam) {
                    return -1
                } else if (!a.exam && b.exam) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        'attempts': {
            asc: (a, b) => {
                if (a.exam && b.exam) {
                    return a.exam.attempts - b.exam.attempts
                } else if (a.exam && !b.exam) {
                    return 1
                } else if (!a.exam && b.exam) {
                    return -1
                } else {
                    return 0
                }
            },
            desc: (a, b) => {
                if (a.exam && b.exam) {
                    return b.exam.attempts - a.exam.attempts
                } else if (a.exam && !b.exam) {
                    return -1
                } else if (!a.exam && b.exam) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        'date_from': {
            asc: (a, b) => {
                if (a.date_from && b.date_from) {
                    return new Date(a.date_from) - new Date(b.date_from)
                } else if (a.date_from && !b.date_from) {
                    return 1
                } else if (!a.date_from && b.date_from) {
                    return -1
                } else {
                    return 0
                }
            },
            desc: (a, b) => {
                if (a.date_from && b.date_from) {
                    return new Date(b.date_from) - new Date(a.date_from)
                } else if (a.date_from && !b.date_from) {
                    return -1
                } else if (!a.date_from && b.date_from) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        'date_to': {
            asc: (a, b) => {
                if (a.date_to && b.date_to) {
                    return a.date_from - b.date_from
                } else if (a.date_to && !b.date_to) {
                    return 1
                } else if (!a.date_to && b.date_to) {
                    return -1
                } else {
                    return 0
                }
            },
            desc: (a, b) => {
                if (a.date_to && b.date_to) {
                    return b.date_from - a.date_from
                } else if (a.date_to && !b.date_to) {
                    return -1
                } else if (!a.date_to && b.date_to) {
                    return 1
                } else {
                    return 0
                }
            }
        },
    }

    const handleInductionSelected = (e, induction) => {
        const { checked } = e.target;
        if (checked) {
            setSelectedInductions([...selectedInductions, induction])
        } else {
            setSelectedInductions(selectedInductions.filter((selectedInduction) => selectedInduction.induction._id !== induction.induction._id))
        }
    }

    const isAdded = (id) => {
        const isAlReadyAdded = selectedInductions.some((selectedInduction) => selectedInduction.induction._id === id);
        return isAlReadyAdded;
    }

    const canReAdd = (induction) => {
        if (induction.date_to) {
            return +currentDate > +new Date(induction.date_to)
        }
        if (induction.sended) {
            return induction.viewed === false && induction.active === true
        }
        return induction.sended === false
    }

    const selectAll = (e) => {
        const { checked } = e.target;
        let newData = [...selectedInductions];
        if (checked) {
            inductions.forEach(induction => {
                const wasAdded = selectedInductions.some((selectedInduction) => selectedInduction.induction._id === induction.induction._id);
                const canReAddAgain = canReAdd(induction)
                if (wasAdded === false && canReAddAgain) {
                    newData.push(induction);
                }
            });
        } else {
            newData = selectedInductions.filter((selectedInduction) => !inductions.some((induction) => induction.induction._id === selectedInduction.induction._id));
        }
        setSelectedInductions(newData);
    }

    const isAllAdded = () => {
        const checkLength = inductions.filter((induction) => canReAdd(induction))
        const filter = selectedInductions.filter((selectedInduction) => inductions.some((induction) => induction.induction._id === selectedInduction.induction._id))
        return checkLength.length === filter.length
    }

    const handleSort = (key, order) => {
        const newData = headers.map((header) => (
            header.key === key
                ? { ...header, active: true, order: header.order === 'asc' ? 'desc' : 'asc' }
                : { ...header, active: false, order: 'asc' }
        ));
        orderBy(orderOpitons[key][order])
        setHeaders(newData);
    }

    const allAsigned = inductions.some((induction) => canReAdd(induction))

    return (
        <div>
            {
                modal ?
                    <RevokeOrGrantModal
                        setModal={setModal}
                        modal={modal}
                        values={values}
                        getCallback={getCallback}
                        visit={{ visit: id, induction: inductionId }}
                    />
                    :
                    null
            }
            {modalQuestionary ? <ExamModal modal={modalQuestionary} setModal={setModalQuestionary} questionary={questionary} /> : null}
            <div style={{ overflowX: 'auto' }}>
                <Table hover>
                    <thead style={{ background: '#e0e3e6' }}>
                        <tr>
                            <th>
                                {
                                    allAsigned ?
                                        <Input
                                            type='checkbox'
                                            onChange={selectAll}
                                            checked={isAllAdded()}
                                        />
                                        :
                                        null
                                }
                            </th>
                            {
                                headers.map((header, idx) => (
                                    <th key={idx}>
                                        <div
                                            className={`d-flex flex-row align-items-${header.order === 'asc' ? 'end' : 'center'} gap-2`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleSort(header.key, header.order === 'asc' ? 'desc' : 'asc')}
                                        >
                                            {header.label}
                                            <i
                                                className={`fas fa-sort-${header.order === 'asc' ? 'up' : 'down'} ${header.active ? 'text-primary' : ''}`}
                                            >
                                            </i>
                                        </div>
                                    </th>
                                ))
                            }
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            inductions.map((induction, idx) => (
                                <ValidInductionsList
                                    induction={induction}
                                    key={idx}
                                    handleModalQuestionary={handleModalQuestionary}
                                    handleInductionSelected={handleInductionSelected}
                                    isAdded={isAdded}
                                    handleModal={handleModal}
                                />
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
