export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const UNATORIZED = 'UNATORIZED';
export const ERROR_EMPLOYEES = 'ERROR_EMPLOYEES';
export const SEND_INDUCTION = 'SEND_INDUCTION';
export const SEND_INDUCTION_ERROR = 'SEND_INDUCTION_ERROR';
export const REVOKE_INDUCTION = 'REVOKE_INDUCTION';
export const GRANT_INDUCTION = 'GRANT_INDUCTION';
export const STATUS_ERROR = 'STATUS_ERROR';
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const CLEAN_EMPLOYEES = 'CLEAN_EMPLOYEES';
export const GET_DETAIL = 'GET_DETAIL';
export const SENDING = 'SENDING';