import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';

export const InductionsDownloadList = ({ validInduction, visualized, handleVisualized, handleDownloadAndVisualized }) => {
    const { t } = useTranslation();
    const [showDescription, setShowDescription] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    return (
        <Fragment>
            <tr>
                <td className='d-flex align-items-center gap-2' onClick={() => setShowDescription(!showDescription)} style={{ cursor: 'pointer' }}>
                    <i className={showDescription ? "fa-solid fa-angles-up" : "fas fa-angle-double-down"}></i>
                    <span>{validInduction.name}</span>
                </td>
                <td>
                    {validInduction.visualized === true ? <i style={{ color: 'green' }} className="fa-solid fa-circle-check"></i> : <i style={{ color: 'red' }} className="fa-solid fa-circle-xmark"></i>}
                </td>
            </tr>
            {
                showDescription ?
                    <tr>
                        <td colSpan={2}>
                            <i>{validInduction.description ? validInduction.description : '-'}</i>
                            <br />
                            {
                                validInduction.files.length > 0 ?
                                    validInduction.files[0].type.split('/')[0] === 'image' || validInduction.files[0].type.split('/')[0] === 'video' ?
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            className='link-primary'
                                            onClick={() => handleVisualized(validInduction)}
                                        >
                                            {t('inductions.view_file')} <i className="fa-solid fa-play"></i>
                                        </span>
                                        :
                                        <>
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                className='link-primary'
                                                onClick={() => handleDownloadAndVisualized(validInduction, setIsDownloading)}
                                            >
                                                {t('inductions.download_file')}
                                            </span>
                                            {isDownloading ? <Spinner className='ms-1' size={'sm'} /> : null}
                                        </>
                                    :
                                    validInduction.visualized === false ?

                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => visualized(validInduction._id)}
                                            className='link-primary'>
                                            {t('inductions.confirm_read')}
                                        </span>
                                        :
                                        null
                            }
                        </td>
                    </tr>
                    :
                    null
            }
        </Fragment >
    )
}
