import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, Input, Label } from 'reactstrap'
import ClassificationsContext from '../../context/Classifications/ClassificationsContext';
import { getTenantByKey } from '../../helpers/helpers';
import { CustomAlert } from '../CustomAlert/CustomAlert';

export const ClassificationsForm = () => {
  const { createClassifications, getClassification, classificationsState, updateClassifications } = useContext(ClassificationsContext);
  const { error, message, inputError, alert, sending, classificationSelected } = classificationsState;
  const { t } = useTranslation();
  const { id } = useParams();

  const iniitalState = {
    code: '',
    description: '',
    active: '',
  }

  const [classification, setClassification] = useState(iniitalState)

  const isEditForm = !!id;

  const classificationsSingularName = getTenantByKey('singular_classification_name');

  const navigate = useNavigate();


  const handleClassification = (e) => {
    setClassification({
      ...classification,
      [e.target.name]: e.target.value,
    });
  };

  const disabled = () => {
    if (isEditForm) {
      return !!!classification.code.trim() || !!!classification.description.trim();
    } else {
      return !!!classification.code.trim() || !!!classification.active.trim() || !!!classification.description.trim();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditForm) {
      updateClassifications(id, classification);
    } else {
      createClassifications(classification);
    }
  }

  useEffect(() => {
    if (isEditForm) getClassification(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (classificationSelected) setClassification(classificationSelected)
  }, [classificationSelected])

  return (
    <Container className='bg-white pb-5 rounded-3'>
      <Form className='mt-5 pb-5 pt-5 w-75 mx-auto' onSubmit={handleSubmit}>

        <h3 className='text-center'>{isEditForm ? t('classifications.edit') : t('classifications.register')} {classificationsSingularName}</h3>
        {error && !inputError ? <CustomAlert text={message} color={'danger'} /> : null}
        {alert ? <CustomAlert text={message} color={'success'} /> : null}

        <hr />

        <Label htmlFor='code' className='fw-bold'>* {t('common.code')}</Label>
        <Input type='text' name='code' value={classification.code} onChange={handleClassification} />
        {inputError ? <div className='alert-danger mt-2'>{t('classifications.inputError')}</div> : null}

        <Label htmlFor='description' className='fw-bold'>* {t('common.description')}</Label>
        <textarea name="description" className="form-control" value={classification.description} onChange={handleClassification}></textarea>

        <Label htmlFor='active' className='fw-bold'>* {t('common.enabled')}</Label>
        <select className="form-select" name='active' value={classification.active} onChange={handleClassification}>
          {isEditForm ? null : <option value={''}>{t('common.choose')}</option>}
          <option value={'true'}>{t('common.yes')}</option>
          <option value={'false'}>{t('common.no')}</option>
        </select>

        <p className='fw-bold mt-4'>* {t('common.requiredFields')}</p>

        <div className='d-flex justify-content-center gap-4'>
          <Button className="btn-secondary" disabled={sending} onClick={() => navigate('/classifications')}>{t('common.cancel')}</Button>
          <Button className="btn-primary" disabled={sending ? sending : disabled()}>{t('common.save')}</Button>
        </div>

      </Form>
    </Container>
  )
}
