import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Form, Input, Label, Button } from 'reactstrap'
import ClassificationsContext from "../../context/Classifications/ClassificationsContext";
import VisitContext from "../../context/Visit/VisitContext";
import { getTenantByKey, getUserDataByKey, isIntegrated } from '../../helpers/helpers';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import { useState } from 'react';
import { RedirectVisitModal } from './RedirectVisitModal';
export const VisitsForm = () => {

    const { createVisist, visitState, getVisit, updateVisit } = useContext(VisitContext);

    const { error, inputError, alert, sending, message, visitSelected } = visitState;

    const { getClassifications, classificationsState } = useContext(ClassificationsContext);

    const { classifications } = classificationsState

    const { id } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate()

    const isGegeric = getUserDataByKey('is_generic')

    const userId = getUserDataByKey('_id');

    const classificationsSingularName = getTenantByKey('singular_classification_name');

    const isEditForm = !!id;

    const initialValuesVisits = {
        first_name: '',
        last_name: '',
        email: '',
        classification: null,
        user_created: null,
        document_number: '',
        company_name: '',
        observations: '',
        user_full_name: '',
        user_rol: '',
        user_area: ''
    }

    const [visit, setVisit] = useState(initialValuesVisits);

    const checkUser = visit.user_created ? visit.user_created._id : visit.user_created

    const formGeneric = isEditForm ? isGegeric && checkUser === userId : isGegeric;

    const handleVisists = (e) => {
        setVisit({
            ...visit,
            [e.target.name]: e.target.value,
        });
    };

    const disabled = () => {
        switch (isEditForm) {
            case true:
                if (isGegeric === true && checkUser === userId) {
                    return !!!visit.first_name.trim() || !!!visit.last_name.trim() || !!!visit.email.trim() || !!!visit.user_full_name.trim() || !!!visit.user_rol.trim() || !!!visit.user_area.trim();
                } else {
                    return !!!visit.first_name.trim() || !!!visit.last_name.trim() || !!!visit.email.trim();
                }
                ;
            case false:
                if (isGegeric === true) {
                    return !!!visit.first_name.trim() || !!!visit.last_name.trim() || !!!visit.email.trim() || !!!visit.user_full_name.trim() || !!!visit.user_rol.trim() || !!!visit.user_area.trim();
                } else {
                    return !!!visit.first_name.trim() || !!!visit.last_name.trim() || !!!visit.email.trim();
                }

            default:
                break;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        window.scroll(0, 0);
        if (isEditForm) {
            updateVisit(id, visit);
        } else {
            visit.user_created = userId;
            createVisist(visit);
        }
    }

    useEffect(() => {
        if (isEditForm) getVisit(id);
        getClassifications(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (visitSelected && isEditForm) setVisit(visitSelected)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visitSelected])


    return (
        <Container>
            <Form className='d-flex mt-5 gap-5 bg-white pb-5 pt-5 rounded-3 flex-wrap' onSubmit={handleSubmit}>
                <Container>
                    <h2 className='text-center'>{!isEditForm ? t('visits.register') : t('visits.edit') + ' #'}</h2>
                    {alert ? <CustomAlert text={message} color={'success'} /> : null}
                    {alert && isIntegrated() ? <RedirectVisitModal visit={visitSelected} alert={alert} /> : null}
                    {error ? <CustomAlert text={message} color={'danger'} /> : null}
                    {classificationsState.error && !error ? <CustomAlert text={classificationsState.message} color={'danger'} /> : null}
                    <hr />
                    <div className="row">
                        <div className="col-md">
                            <Label className='fw-bold' htmlFor='first_name'>* {t('common.firstName')}</Label>
                            <Input type='text' name='first_name' value={visit.first_name} onChange={handleVisists} />
                        </div>
                        <div className="col-md">
                            <Label className='fw-bold' htmlFor='last_name'>* {t('common.lastName')}</Label>
                            <Input type='text' name='last_name' value={visit.last_name} onChange={handleVisists} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md">
                            <Label className='fw-bold' htmlFor='email'>* Email</Label>
                            <Input type='email' name='email' value={visit.email} onChange={handleVisists} />
                            {inputError ? <div className='alert-danger mt-2'>{t('visits.inputError')}</div> : null}
                        </div>
                        {
                            !isIntegrated() ?
                                <div className="col-md">
                                    <Label htmlFor='classification'>{classificationsSingularName}</Label>
                                    <select className="form-select" name='classification' value={visit.classification ? visit.classification._id : ''} onChange={handleVisists}>
                                        <option value={''}>{t('common.choose')}</option>
                                        {classifications.map((classification) => (
                                            <option key={classification._id} value={classification._id}>{classification.description}</option>
                                        ))}
                                    </select>
                                </div>
                                :
                                null
                        }
                    </div>

                    <div className="row">
                        <div className="col-md">
                            <Label htmlFor='document_number'>{t('common.dni')}</Label>
                            <Input value={visit.document_number} type='number' min={0} name='document_number' onChange={handleVisists} />
                        </div>
                        <div className="col-md">
                            <Label htmlFor='company_name'>{t('common.company')}</Label>
                            <Input value={visit.company_name} type='text' name='company_name' onChange={handleVisists} />
                        </div>
                    </div>
                    <Label htmlFor='otherData'>{t('common.observations')}</Label>
                    <textarea name='observations' style={{ height: '200px' }} value={visit.observations} className="form-control mb-4" onChange={handleVisists} ></textarea>
                </Container>
                {
                    formGeneric ?
                        < Container >
                            <h2 className='text-center'>{t('visits.infoUser')}</h2>
                            <hr />

                            <Label className='fw-bold' htmlFor='user_full_name'>* {t('common.fullName')}</Label>
                            <Input value={visit.user_full_name} type='text' name='user_full_name' onChange={handleVisists} />

                            <Label className='fw-bold' htmlFor='user_rol'>* {t('common.rol')}</Label>
                            <Input value={visit.user_rol} type='text' name='user_rol' onChange={handleVisists} />

                            <Label className='fw-bold' htmlFor='user_area'>* {t('common.area')}</Label>
                            <Input value={visit.user_area} type='text' name='user_area' onChange={handleVisists} />

                        </Container>
                        :
                        null
                }
                <Container>
                    <p className='fw-bold'>* {t('common.requiredFields')}</p>
                    <div className='d-flex justify-content-center gap-5 flex-wrap-reverse'>
                        <Button className="btn-secondary" disabled={sending} onClick={() => navigate('/visits')}>{t('common.cancel')}</Button>
                        <Button className="btn-primary" disabled={!sending ? disabled() : true} >{t('common.save')}</Button>
                    </div>
                </Container>

            </Form >
        </Container >
    )
}
