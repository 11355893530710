import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Label, Button } from 'reactstrap'
import { isUnique } from '../../helpers/helpers';

export const VisitsFilter = ({ handleChange, search, clean, filterValues }) => {
    const { t } = useTranslation();
    const { first_name, last_name, document_number, status } = filterValues;
    const onKeyPress = (e) => {
        if ((e.key === 'Enter')) {
            search();
        }
    };

    return (
        <div className='row align-items-end'>
            <div className='col-md'>
                <Label htmlFor='first_name'>{t('common.firstName')}</Label>
                <Input
                    value={first_name}
                    type='text'
                    onChange={handleChange} name='first_name'
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='col-md'>
                <Label htmlFor='last_name'>{t('common.lastName')}</Label>
                <Input
                    value={last_name}
                    type='text'
                    onChange={handleChange} name='last_name'
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='col-md'>
                <Label htmlFor='document_number'>{t('common.dni')}</Label>
                <Input
                    value={document_number}
                    type='number'
                    name='document_number'
                    onChange={handleChange}
                    onKeyPress={onKeyPress}
                />
            </div>

            <div className='col-md'>
                <Label htmlFor='status'>{t('common.status')}</Label>
                <select
                    value={status}
                    className="form-select"
                    name='status'
                    onChange={handleChange}
                >
                    <option value={''}>{t('common.all')}</option>
                    {
                        isUnique() ?
                            <>
                                <option className='option-style' value="V">{t('inductions.current')}</option>
                                <option className='option-style' value="N">{t('inductions.noCurrent')}</option>
                            </>
                            :
                            <>
                                <option className='option-style' value="A">{t('common.suitable')}</option>
                                <option className='option-style' value="N">{t('common.no_suitable')}</option>
                                <option className='option-style' value="P">{t('common.earring')}</option>
                            </>
                    }
                </select>
            </div>

            <div className="col-md mt-3">
                <div className='d-flex align-items-end gap-3'>
                    <Button
                        onClick={clean}
                        className="btn-secondary">
                        <i className="fa-solid fa-eraser"></i>
                    </Button>
                    <Button
                        onClick={search}
                        className="btn-secondary">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </Button>
                </div>
            </div>

        </div>
    )
}