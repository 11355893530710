import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom';
import { Input } from 'reactstrap'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

export const ContractorList = ({ contractor, handleContractorSelected, isAdded, handleModal }) => {
    const { t } = useTranslation();
    const { active, assignment_date, business_type, business_type_openings, contractor_classification, contractor_code, contractor_id, contractor_name } = contractor

    const added = isAdded(contractor_id)

    const { id } = useParams();

    return (
        <tr>
            <td>
                {
                    assignment_date ?
                        null
                        :
                        <Input
                            type='checkbox'
                            onChange={(e) => handleContractorSelected(e, contractor)}
                            checked={added}
                        />
                }
            </td>
            <td><span>{contractor_name}</span> <small>({contractor_code})</small> </td>
            <td>{contractor_classification}</td>
            <td>{business_type}</td>
            <td>
                {business_type_openings.length > 0 ?
                    business_type_openings.map((businessTypeOpenings) => (
                        <Fragment key={businessTypeOpenings.business_type_opening_code}>
                            {businessTypeOpenings.business_type_opening_description}
                            <br />
                        </Fragment>
                    ))
                    :
                    '-'
                }
            </td>
            <td>{assignment_date ? assignment_date.slice(0, 10).split('-').reverse().join('-') : t('common.no')}</td>
            <td>
                {
                    assignment_date ?
                        <>
                            <CustomTooltip
                                placement={"top"}
                                target={active ? `revoke-${contractor_id}` : `grant-${contractor_id}`}
                                text={active ? t('common.expire_induction') : t('common.reset_induction')}
                            />
                            <i
                                className={active ? "fa-solid fa-circle-xmark me-3 link-danger" : "fa-solid fa-recycle me-3 link-success"}
                                type='button'
                                id={active ? `revoke-${contractor_id}` : `grant-${contractor_id}`}
                                onClick={() => handleModal(!active, contractor_id)}
                            >
                            </i>
                            <CustomTooltip
                                placement={"top"}
                                target={`detail-${contractor_id}`}
                                text={t('common.view_detail')}
                            />
                            <Link
                                style={{ color: 'black', width: '14px' }}
                                to={`/induction/details/employee/${id}/${contractor_id}`}
                            >
                                <i
                                    className="fa-solid fa-circle-info"
                                    id={`detail-${contractor_id}`}
                                >
                                </i>
                            </Link>
                        </>
                        :
                        null
                }
            </td>
        </tr>
    )
}
