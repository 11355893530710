import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Login } from '../containers/Login/Login'
import { LoginExactian } from '../components/LoginExactian/LoginExactian'

export const AuthRoutes = () => {
    return (
        <Routes>

            <Route
                path="/"
                element={<Login />}
            />

            <Route
                path="exactian/:token"
                element={<LoginExactian />}
            />

            <Route
                path="*"
                element={<Navigate to={'/'} replace />}
            />

        </Routes>
    )
}
