export const portuguese = {
  translation: {
    language: 'BR',
    "common": {
      "name": "Nome",
      "user_name": "Usuário",
      "firstName": "Nome",
      "lastName": "Sobrenome",
      "fullName": "Nome completo",
      "profile": "Perfil",
      "generic": "Genérico",
      "active": "Ativo",
      "genericProfile": "Perfil genérico",
      "area": "Área",
      "rol": " Função",
      "dni": "EU IRIA",
      "company": "O negócio",
      "observations": "Outros dados",
      "description": "Descrição",
      "requiredFields": "Os campos obrigatórios",
      "status": "Doença",
      "enabled": "Habilitado",
      "notEnabled": "Não habilitado",
      "code": "Código",
      "created": "Registrado por",
      "choose": "Selecionar",
      "all": "Tudo",
      "yes": "Sim",
      "no": "Não",
      "cancel": "Cancelar",
      "save": "Salvar",
      "confirm": "confirme",
      "search": "Procurar",
      "confirmDelete": "Tem certeza de que deseja excluir o registro?",
      "delete": "Remover",
      "alertDelete": "Registro excluído",
      "error_token": "A sessão expirou, faça login novamente.",
      "error_server": "Ocorreu um erro de conexão do servidor, tente novamente mais tarde",
      "error_tenant": "Instância não encontrada",
      "error_relations": "O registro não pôde ser excluído porque tem relacionamentos",
      "not_loaded": "Nenhum registro carregado",
      "error_filter": "Nenhum resultado foi encontrado para a busca",
      "alert_1": "Cadastro registrado com sucesso ",
      "alert_2": "Registro modificado com sucesso ",
      "close": "Fechar",
      "send_induction": "Mandar",
      "view_detail": "Ver detalhe",
      "expire_induction": "Expirar",
      "reset_induction": "Restaurar",
      "action_edit": "Editar",
      "action_delete": "Remover",
      "view_versions": "Versões",
      "name_date": "Encontro",
      "came_back": "Retornar",
      "end": "Para finalizar",
      "clean_selection": "Seleção clara",
      "suitable": "Adequado",
      "no_suitable": "Não apto",
      "earring": "Brinco"
    },
    "login": {
      "user_name": "Usuário",
      "password": "Senha",
      "login": "Entrar",
      "forgotPassword": "Esqueceu sua senha?",
      "error_1": "Credenciais erradas!"
    },
    "forgotPassword": {
      "title": "Recuperar senha",
      "changePassword": "Mudar senha",
      "recover": "Recuperar",
      "confirmPassword": "Confirmar senha",
      "mailSend": "Enviamos um e-mail com sua nova senha",
      "passwordChanged": "Senha modificada",
      "error_1": "O usuário inserido não existe"
    },
    "layout": {
      "management": {
        "management": "Gestão",
        "inductions": "induções",
        "classifications": "Classifications",
        "users": "Usuários"
      },
      "visits": {
        "visits": "visitas",
        "management": "Gerenciar visitas",
        "newVisits": "nova visita"
      },
      "user": {
        "changePassword": "Mudar senha",
        "loguot": "Fechar Sessão"
      }
    },
    "user": {
      "title": "Pesquisa - Usuários",
      "new": "Novo usuário",
      "inputError": "O nome de usuário já está registrado",
      "register": "Novo usuário",
      "edit": "Editar usuário"
    },
    "classifications": {
      "title": "Classifications",
      "new": "Novo",
      "inputError": "O código já está registrado",
      "register": "Novo",
      "edit": "Editar"
    },
    "inductions": {
      "title": "Pesquisa - Induções",
      "new": "nova indução",
      "inputError": "O nome já está registrado",
      "register": "nova indução",
      "edit": "Editar indução",
      "since": "Data efetiva de",
      "until": "Data efetiva até",
      "since_only": "Desde",
      "until_only": "Até",
      "video": "Arquivo de vídeo",
      "sizeError": `O arquivo pode pesar no máximo`,
      "sending": "Subindo....",
      "seen": "Visualizado",
      "current": "Válido",
      "noCurrent": "Inválido",
      "finished": "Finalizado",
      "expired": "expirado",
      "futura": "futuro",
      "confirmExpired": "Tem certeza de que deseja expirar a indução?",
      "confirmRecycle": "Tem certeza de que deseja redefinir a indução?",
      "expire": "Expirar",
      "restore": "Restaurar",
      "confirmState": "Status alterado com sucesso ",
      "download_file": "⇬ Fazer download do arquivo",
      "confirm_read": "Confirmar leitura",
      "error_relations": "O registro não pôde ser excluído porque tem relação com visitas",
      "date_error": {
        "from_error": "Deve ser maior ou igual à data atual",
        "to_error": "Deve ser maior que a data efetiva de"
      },
      "view_file": "Veja Indução",
      "allow_download": "permitir download",
      "activities_name": "Item",
      "employee_classifications": "Classificação do funcionário",
      "associated_document": "Documento associado",
      "induction_validity": "Validade (dias)",
      "induction_validity_only": "Validade",
      "induction_validity_only_days": "dias",
      "video_percentage": " % do vídeo para marcá-lo como assistido",
      "induction_name": "Indução",
      "format_error": "Somente arquivos PDF ou Vídeo são permitidos",
      "no_questionary": "não tem questionário",
      "n_page": "Página",
      "n_of": "do",
      "new_version": "A versão do questionário foi atualizada",
      "new_questionary_version": "Há uma nova versão do quiz",
      "induction_expired": "indução expirada"
    },
    "visits": {
      "title": "Pesquisa - Visitas",
      "new": "nova visita",
      "register": "nova visita",
      "edit": "Editar visita",
      "infoUser": "Informação do usuário",
      "mailStatus": "Doença",
      "induccionStatus": "Status de indução",
      "validInductions": "Induções atuais",
      "error_relations": "Não foi possível excluir a visita porque ela já interagiu com o sistema",
      "inputError": "A visita já está registrada",
      "info_visit": "Dados da visita",
      "mail_send": "Email enviado",
      "send_induction_message": {
        "start_part": "Tem certeza de enviar para",
        "end_part": "as seguintes induções?"
      },
      "send_message_alert": "Induções enviadas",
      "earring_to_assing": "Inclinação de Atribuição de Indução",
      "ask_assing_inductions": "Você quer enviar induções para"
    },
    "questionary": {
      "title": "Pesquisa - Questionários",
      "new": "Novos testes",
      "register": "Novo questionário",
      "edit": "Editar questionário",
      "questionary": "Questionário",
      "version": "Versão",
      "name_questionary": "Nome do questionário",
      "max_score": "Melhor pontuação",
      "min_score": "Pontuação Mínima",
      "new_question": "nova pergunta",
      "question_placeholder": "digite a pergunta",
      "score_placeholder": "Digite a pontuação",
      "answer_placeholder": "Digite a resposta",
      "total_score_error": "A soma das questões deve dar a pontuação máxima",
      "add_question": "Adicionar pergunta",
      "delete_question": "Excluir pergunta",
      "add_answer": "Adicionar resposta",
      "delete_answer": "Excluir resposta",
      "delete_questionary_error": "O questionário está sendo usado para uma indução",
      "is_correct": "Resposta correta",
      "questionary_plural": "Questionários",
      "add_one_question": "Criar 1 pergunta",
      "add_two_question": "Criar 2 perguntas",
      "add_three_question": "Criar 3 perguntas",
      "add_five_question": "Crie 5 perguntas",
      "add_ten_question": "Crie 10 perguntas",
      "last_version_alert": "Tem certeza de que deseja remover a versão mais recente do questionário?",
      "choose_answer": "Selecione uma das seguintes respostas possíveis",
      "next_question": "Seguindo",
      "prev_question": "Anterior",
      "full_screen": "Tela completa",
      "congratulations": "Parabéns",
      "exam_pass_confirm": "Você foi aprovado no questionário ",
      "exam_pass_confirm_two": " correspondente à indução ",
      "exam_pass_note": "Observação",
      "enabled_to_date": "Está habilitado até",
      "print_exam": "Imprimir",
      "we_sorry": "Sentimos muito",
      "exam_reject_confirmation": "Você não atingiu a pontuação necessária para passar no teste ",
      "try_again_question": "Você quer tentar novamente?",
      "try_again_action": "Tente novamente",
      "exam_pass_confirm_three": "Você completou a indução",
      "exam_disabled_video_part_one": "Para finalizar a indução é necessário visualizar o",
      "exam_disabled_video_part_two": "do vídeo",
      "exam_disabled_questionary": " e preencha o questionário",
      "exam_disabled_pdf": "Para finalizar a indução é necessário visualizar o PDF completo",
      "not_found": "A indução não está disponível ou não existe"
    },
    "contractor": {
      "id": "ID",
      "assigned_induction": "Atribuído",
      "contractor_classification": "Classificação",
      "contractor_name": "Empreiteira",
      "send_induction_message": {
        "start_part": "Tem certeza de enviar a indução",
        "end_part": "aos seguintes contratantes?"
      },
      "send_message_alert": "A indução foi enviada aos empreiteiros selecionados",
      "message_to_contractors": "Digite uma mensagem para contratados",
      "list_of_contractors": "Lista de Contratados",
      "contractor_detail": "Detalhe da Contratada",
      "assigned_employees": "Atribuído",
      "assigned_employees_view": "visto",
      "assigned_employees_passed": "Aprovado",
      "total_exam": "Total",
      "print_detail": "Detalhes da impressão",
      "employees_plural": "Funcionários"
    },
    "employee": {
      "id": "ID",
      "assigned_induction": "Atribuído",
      "employee_classification": "Classificação",
      "employee_name": "Empregado",
      "send_induction_message": {
        "start_part": "Tem certeza de enviar a indução",
        "end_part": "aos seguintes funcionários?"
      },
      "send_message_alert": "A indução foi enviada aos funcionários selecionados",
      "contract_name": "Contrato",
      "send": "Mandar",
      "list_of_employees": "Lista de empregados",
      "exam_passed": "Passado",
      "detail_employee": "Detalhe do funcionário",
      "score_exam": "Pontuação",
      "attemps_exam": "Não. Tentativas",
      "view_exam": "Ver exame",
      "view_certificate": 'Ver Certificado de Aprovação',
      "employee_info": "Todos os funcionários são contados sem levar em conta as restrições do usuário."
    }
  }
};