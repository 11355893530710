import React from 'react'
import { useTranslation } from 'react-i18next';
import { Input, Label, Button } from 'reactstrap'
import { isUnique } from '../../helpers/helpers';

export const InductionFilter = ({ handleChange, search, clean, filterValues }) => {
    const { t } = useTranslation();
    const { name, date_from, date_to, status, active } = filterValues;

    const onKeyPress = (e) => {
        if ((e.key === 'Enter')) {
            search();
        }
    };

    return (
        <div className='row align-items-end'>
            {
                isUnique() ?
                    <>
                        <div className='col-md'>
                            <Label htmlFor='date_from'>{t('inductions.since')}</Label>
                            <Input
                                value={date_from}
                                type='date'
                                name='date_from'
                                onChange={handleChange}
                                onKeyPress={onKeyPress}
                            />
                        </div>

                        <div className='col-md'>
                            <Label htmlFor='date_to'>{t('inductions.until')}</Label>
                            <Input
                                value={date_to}
                                type='date'
                                name='date_to'
                                onChange={handleChange}
                                onKeyPress={onKeyPress}
                            />
                        </div>

                        <div className='col-md'>
                            <Label htmlFor='name'>{t('common.name')}</Label>
                            <Input
                                value={name}
                                type='text'
                                name='name'
                                onChange={handleChange}
                                onKeyPress={onKeyPress}
                            />
                        </div>

                        <div className='col-md'>
                            <Label htmlFor='status'>{t('common.status')}</Label>
                            <select
                                value={status}
                                className='form-select'
                                name="status"
                                onChange={handleChange}
                            >
                                <option value="">{t('common.all')}</option>
                                <option value="V">{t('inductions.current')}</option>
                                <option value="E">{t('inductions.finished')}</option>
                                <option value="C">{t('inductions.expired')}</option>
                            </select>
                        </div>


                    </>
                    :
                    <>
                        <div className='col-md'>
                            <Label htmlFor='name'>{t('common.name')}</Label>
                            <Input
                                value={name}
                                type='text'
                                name='name'
                                onChange={handleChange}
                                onKeyPress={onKeyPress}
                            />
                        </div>
                        <div className='col-md-2'>
                            <Label htmlFor='active'>{t('common.active')}</Label>
                            <select
                                value={active}
                                className='form-select'
                                name="active"
                                onChange={handleChange}
                            >
                                <option value="">{t('common.all')}</option>
                                <option value="true">{t('common.yes')}</option>
                                <option value="false">{t('common.no')}</option>
                            </select>
                        </div>
                    </>
            }

            <div className='col-md mt-3'>
                <div className='d-flex align-items-end gap-3'>
                    <Button
                        onClick={clean}
                        className="btn-secondary"
                    >
                        <i className="fa-solid fa-eraser"></i>
                    </Button>
                    <Button
                        onClick={search}
                        className="btn-secondary"
                    >
                        <i className="fa-solid fa-magnifying-glass" ></i>
                    </Button>
                </div>
            </div>

        </div>
    )
}
