import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { spanish } from './languages/spanish';
import { spanish_chile } from './languages/spanish_chile';
import { spanish_mexico } from './languages/spanish_mexico';
import { portuguese } from './languages/portuguese';
import { english } from './languages/english';
import { italian } from './languages/italian';

import { getTenantByKey } from './helpers/helpers';
import { spanish_ecuador } from './languages/spanish_ecuador';
import { spanish_peru } from './languages/spanish_peru';

const stored_language = getTenantByKey('language') != null ? getTenantByKey('language') : 'es';

const resources = {
  es: spanish,
  ch: spanish_chile,
  mx: spanish_mexico,
  br: portuguese,
  en: english,
  it: italian,
  ec: spanish_ecuador,
  pe: spanish_peru
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: stored_language,
    fallbackLng: stored_language,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
