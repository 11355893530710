import React from 'react'
import { BusinessTypeOpeningsState } from './BusinessTypeOpenings/BusinessTypeOpeningsState'
import { BusinessTypesState } from './BusinessTypes/BusinessTypesState'
import { ClassificationsState } from './Classifications/ClassificationsState'
import { ContractorState } from './Contractor/ContractorState'
import { ContractorClassificationsState } from './ContractorClassifications/ContractorClassificationsState'
import { ContractsState } from './Contracts/ContractsState'
import { DocumentState } from './Document/DocumentState'
import { EmployeeState } from './Employee/EmployeeState'
import { EmployeeClassificationState } from './EmployeeClassification/EmployeeClassificationState'
import { ExamState } from './Exam/ExamState'
import { InductionState } from './Induction/InductionState'
import { LoginState } from './Login/LoginState'
import { QuestionaryState } from './Questionary/QuestionaryState'
import { TenantState } from './Tenant/TenantState'
import { UsersState } from './Users/UsersState'
import { VisitState } from './Visit/VisitState'
export const ContextProvider = ({ children }) => {
    return (
        <TenantState>
            <LoginState>
                <ClassificationsState>
                    <UsersState>
                        <QuestionaryState>
                            <BusinessTypesState>
                                <BusinessTypeOpeningsState>
                                    <EmployeeClassificationState>
                                        <DocumentState>
                                            <InductionState>
                                                <ContractorClassificationsState>
                                                    <ContractsState>
                                                        <ContractorState>
                                                            <EmployeeState>
                                                                <ExamState>
                                                                    <VisitState>
                                                                        {children}
                                                                    </VisitState>
                                                                </ExamState>
                                                            </EmployeeState>
                                                        </ContractorState>
                                                    </ContractsState>
                                                </ContractorClassificationsState>
                                            </InductionState>
                                        </DocumentState>
                                    </EmployeeClassificationState>
                                </BusinessTypeOpeningsState>
                            </BusinessTypesState>
                        </QuestionaryState>
                    </UsersState>
                </ClassificationsState>
            </LoginState>
        </TenantState>
    )
}
