import i18n from "i18next";
import { GET_INDUCTIONS, GET_INDUCTION, CREATE_INDUCTION, UPDATE_INDUCTION, DELETE_INDUCTION, UNATORIZED, ERROR_INDUCTIONS, DELETE_INDUCTION_ERROR, UPDATE_INDUCTION_STATUS, CLEAR_ALERTS, GET_VALID_INDUCTIONS, GET_URL, SENDING, DELETE_URL, GET_STATUS, CHANGE_PAGE, INPUT_ERROR } from "./types";
const InductionReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case UNATORIZED:
            return {
                ...state,
                inductions: [],
                totalPages: null,
                inductionSelected: null,
                isFetching: false,
                sending: false,
                error: true,
                inputError: false,
                alert: false,
                message: i18n.t('common.error_token'),
                deleteError: false,
                alertDelete: false,
            }

        case ERROR_INDUCTIONS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: true,
                inputError: false,
                alert: false,
                message: i18n.t('common.error_server'),
                deleteError: false,
                alertDelete: false,
            }
        case INPUT_ERROR:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: true,
                alert: false,
                deleteError: false,
                alertDelete: false,
            }

        case GET_INDUCTIONS:
            return {
                ...state,
                inductions: payload.inductions,
                totalPages: payload.total_pages,
                inductionSelected: null,
                isFetching: false,
                changingPage: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                alertStatus: false,
                assigned_to_contractors: false,
                assigned_to_employee: false,
            }

        case CREATE_INDUCTION:
            return {
                ...state,
                isFetching: false,
                sending: true,
                error: false,
                inputError: false,
                alert: true,
                message: i18n.t('common.alert_1'),
                deleteError: false,
                alertDelete: false,
            }

        case GET_INDUCTION:
            return {
                ...state,
                inductionSelected: payload,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
            }

        case UPDATE_INDUCTION:
            return {
                ...state,
                isFetching: false,
                sending: true,
                error: false,
                inputError: false,
                alert: true,
                message: i18n.t('common.alert_2'),
                deleteError: false,
                alertDelete: false,
            }

        case DELETE_INDUCTION:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                alert: false,
                deleteError: false,
                alertDelete: true,
            }

        case DELETE_INDUCTION_ERROR:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                alert: false,
                deleteError: true,
                message: payload,
                alertDelete: false,
            }

        case UPDATE_INDUCTION_STATUS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                alertStatus: true,
            }

        case CLEAR_ALERTS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                message: '',
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                alertStatus: false,
            }

        case SENDING:
            return {
                ...state,
                sending: true,
                error: false,
                inputError: false,
            }

        case GET_VALID_INDUCTIONS:
            return {
                ...state,
                validInductions: payload,
                inductionSelected: null,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                alertStatus: false,
            }

        case GET_URL:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                alertStatus: false,
                url: payload,

            }

        case DELETE_URL:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
                alertStatus: false,
                url: '',
            }
        case GET_STATUS:
            return {
                ...state,
                assigned_to_contractors: payload.assigned_to_contractors,
                assigned_to_employee: payload.assigned_to_employee,
            }

        case CHANGE_PAGE: {
            return {
                ...state,
                changingPage: true,
            }
        }
        default:
            return state

    }
}

export default InductionReducer