import React from 'react'
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ContractorContext } from '../../context/Contractor/ContractorContext';
import { EmployeeContext } from '../../context/Employee/EmployeeContext';
import InductionContext from '../../context/Induction/InductionContext';
import VisitContext from '../../context/Visit/VisitContext';
import { getUserDataByKey } from '../../helpers/helpers';
import { CustomAlert } from '../CustomAlert/CustomAlert';

export const SendInductionModal = ({ setModal, modal, selectedItems, setSelectedItems, messageToSend, getCallback }) => {

    const toggle = () => setModal(!modal);

    const { t } = useTranslation();

    const { pathname: location } = useLocation();

    const { id: inductionId } = useParams()

    const { inductionState } = useContext(InductionContext);
    const { inductionSelected } = inductionState;

    const { contractorState, sendInductionToContractor } = useContext(ContractorContext);

    const { employeeState, sendInductionToEmployee } = useContext(EmployeeContext);

    const { visitState, sendInductions } = useContext(VisitContext);
    const { visitSelected } = visitState;

    const sendToOptions = {
        [`/induction/send/contractor/${inductionId}`]: {
            sendInduction: function (values) { sendInductionToContractor(values, toggle, setSelectedItems, getCallback) },
            alert: contractorState.alert,
            alertMessage: t('contractor.send_message_alert'),
            error: contractorState.errorSend,
            message: contractorState.message,
            sending: contractorState.sending,
            askMessage: {
                start_part: t('contractor.send_induction_message.start_part'),
                middle_part: inductionSelected ? inductionSelected.name : '',
                end_part: t('contractor.send_induction_message.end_part')
            },
            printedOptions: {
                list: selectedItems.map((selectedItem) => (
                    <li key={selectedItem.contractor_id}><span>{selectedItem.contractor_name}</span> <small>({selectedItem.contractor_code})</small></li>
                ))
            },
            values: {
                induction: inductionSelected?._id,
                questionary: inductionSelected && inductionSelected.questionary ? inductionSelected.questionary._id : '',
                message: messageToSend,
                contractors_viewed: selectedItems.flatMap((selectedItem) => selectedItem.contractor_id),
            }
        },

        [`/induction/send/employee/${inductionId}`]: {
            sendInduction: function (values) { sendInductionToEmployee(values, toggle, setSelectedItems, getCallback) },
            alert: employeeState.alert,
            alertMessage: t('employee.send_message_alert'),
            error: employeeState.errorSend,
            message: employeeState.message,
            sending: employeeState.sending,
            askMessage: {
                start_part: t('employee.send_induction_message.start_part'),
                middle_part: inductionSelected ? inductionSelected.name : '',
                end_part: t('employee.send_induction_message.end_part')
            },
            printedOptions: {
                list: selectedItems.map((selectedItem) => (
                    <li key={selectedItem.employee_id}><span>{selectedItem.employee_full_name}</span> <small>({selectedItem.employee_code})</small></li>
                ))
            },
            values: {
                induction: inductionSelected?._id,
                contractor: getUserDataByKey('_id'),
                employees_viewed: selectedItems.flatMap((selectedItem) => selectedItem.employee_id),
            }
        },

        [`/visits/send/${visitSelected?._id}`]: {
            sendInduction: function (values) { sendInductions(values, toggle, setSelectedItems, getCallback) },
            alert: visitState.alert,
            alertMessage: t('visits.send_message_alert'),
            error: visitState.errorSend,
            message: visitState.message,
            sending: visitState.sending,
            askMessage: {
                start_part: t('visits.send_induction_message.start_part'),
                middle_part: visitSelected ? `${visitSelected.first_name} ${visitSelected.last_name}` : '',
                end_part: t('visits.send_induction_message.end_part')
            },
            printedOptions: {
                list: (visitSelected && `/visits/send/${visitSelected._id}` === location) ? selectedItems.map((selectedItem) => (
                    <li key={selectedItem.induction._id}><span>{selectedItem.induction.name}</span></li>
                )) : ''
            },
            values: {
                visit: inductionId,
                inductions: visitSelected ? selectedItems.map((selectedItem) => (selectedItem.induction._id)) : '',
            }
        },
    }

    const handleSendInduction = () => {
        sendToOptions[location].sendInduction(sendToOptions[location].values);
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} size={'lg'}>
                <ModalHeader toggle={toggle}>{sendToOptions[location].askMessage.start_part} <span className='fw-bold'>{sendToOptions[location].askMessage.middle_part}</span> {sendToOptions[location].askMessage.end_part}</ModalHeader>
                <ModalBody>
                    {
                        sendToOptions[location].printedOptions.list
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={sendToOptions[location].sending} onClick={handleSendInduction}>
                        {t('common.send_induction')}
                    </Button>
                    <Button color="secondary" disabled={sendToOptions[location].sending} onClick={toggle}>
                        {t('common.cancel')}
                    </Button>
                </ModalFooter>
                {sendToOptions[location].alert ? <CustomAlert color={'success'} text={sendToOptions[location].alertMessage} /> : null}
                {sendToOptions[location].error ? <CustomAlert color={'danger'} text={sendToOptions[location].message} /> : null}
            </Modal>
        </div>
    )
}
