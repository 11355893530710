export const GET_CLASSIFICATIONS = 'GET_CLASSIFICATIONS';
export const GET_CLASSIFICATION = 'GET_CLASSIFICATION';
export const CREATE_CLASSIFICATION = 'CREATE_CLASSIFICATION';
export const UPDATE_CLASSIFICATION = 'UPDATE_CLASSIFICATION';
export const DELETE_CLASSIFICATION = 'DELETE_CLASSIFICATION';
export const DELETE_CLASSIFICATION_ERROR = 'DELETE_CLASSIFICATION_ERROR';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const UNATORIZED = 'UNATORIZED';
export const ERROR_CLASSIFICATIONS = 'ERROR_CLASSIFICATIONS';
export const ERROR_CLASSIFICATION_CODE = 'ERROR_CLASSIFICATION_CODE'
