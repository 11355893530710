import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Spinner } from 'reactstrap'
import { CustomAlert } from '../../components/CustomAlert/CustomAlert'
import { QuestionaryFilter } from '../../components/Questionary/QuestionaryFilter'
import { QuestionaryTable } from '../../components/Questionary/QuestionaryTable'
import { Resource } from '../../components/Resource/Resource'
import QuestionaryContext from '../../context/Questionary/QuestionaryContext'

export const Questionary = () => {
    const { t } = useTranslation();

    const { getQuestionnaires, questionaryState } = useContext(QuestionaryContext);

    const { error, tokenError, message, isFetching, questionnaires } = questionaryState;

    const initialState = {
        name: '',
        active: true,
    }

    const [errorFilter, setErrorFilter] = useState(false);

    const [filter, setFilter] = useState(initialState);

    const { name, active } = filter;


    const handleChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value,
        });
    };

    const search = () => {
        getQuestionnaires(name, active);
        setErrorFilter(true);
    }

    const clean = () => {
        setFilter(initialState);
        getQuestionnaires('', true);
        setErrorFilter(false);
    }


    useEffect(() => {
        getQuestionnaires(name, active)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container className='bg-white mt-5 pb-5 ps-5 pe-5 pt-5 rounded-3'>
            <Resource title={t('questionary.title')} resource={t('questionary.register')} newResource={'questionary'} />
            <hr className='bg-dark' />
            <QuestionaryFilter handleChange={handleChange} search={search} clean={clean} filterValues={filter} />
            <hr className='bg-dark' />
            {
                isFetching ?
                    <div className='d-flex justify-content-center'>
                        <Spinner size={'xxl'} />
                    </div>
                    :
                    questionnaires.length > 0 ?
                        <>
                            <QuestionaryTable questionnaires={questionnaires} getCallback={() => getQuestionnaires(name, active)} />
                        </>
                        :
                        questionnaires.length === 0 && errorFilter && !error && !!!isFetching ?
                            <div className='d-flex justify-content-center'>{t('common.error_filter')}</div>
                            :
                            questionnaires.length === 0 && !errorFilter && !error && !!!isFetching ?
                                <div className='d-flex justify-content-center'>{t('common.not_loaded')}</div>
                                :
                                null
            }
            {error || tokenError? <CustomAlert text={message} color={'danger'} /> : null}
        </Container>
    )
}
