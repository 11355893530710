import { Navigate, Route, Routes } from "react-router-dom"
import { Visits } from "../containers/Visits/Visits"
import { ProfileRoutes } from "../components/ProfileRoutes/ProfileRoutes"
import { VisitsForm } from "../components/Visits"
import { IntegratedRoutes } from "../components/IntegratedRoutes/IntegratedRoutes"
import { VisitDetail } from "../components/Visits/VisitDetail"
import { VisitIntegrated } from "../containers/VisitIntegrated/VisitIntegrated"
import { isUnique } from "../helpers/helpers"

export const VisitRoutes = () => {
    return (
        <Routes>

            <Route
                path="/"
                element={
                    isUnique() ?
                        <Visits />
                        :
                        <ProfileRoutes validRoles={['01', '02', '03']}>
                            <Visits />
                        </ProfileRoutes>
                }
            />

            <Route
                path="edit/:id"
                element={
                    isUnique() ?
                        <VisitsForm />
                        :
                        <ProfileRoutes validRoles={['01', '02', '03']}>
                            <VisitsForm />
                        </ProfileRoutes>
                }
            />

            <Route
                path="new"
                element={
                    isUnique() ?
                        <VisitsForm />
                        :
                        <ProfileRoutes validRoles={['01', '02', '03']}>
                            <VisitsForm />
                        </ProfileRoutes>
                }
            />

            <Route
                path="send/:id"
                element={
                    <IntegratedRoutes>
                        <ProfileRoutes validRoles={['01', '02', '03']}>
                            <VisitDetail>
                                <VisitIntegrated />
                            </VisitDetail>
                        </ProfileRoutes>
                    </IntegratedRoutes>
                }
            />

            <Route path="*" element={<Navigate to={'/'} replace />} />

        </Routes>
    )
}
