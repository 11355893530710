import React, { useState } from 'react'
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadInduction } from '../DownloadInduction/DownloadInduction';
import { InductionPreviewModal } from './InductionPreviewModal';

export const InductionDownloadOrPreview = ({ induction }) => {
    const { t } = useTranslation();

    const [modal, setModal] = useState(false)

    return (
        <Fragment>
            {modal ? <InductionPreviewModal modal={modal} setModal={setModal} induction={induction} /> : null}
            <div>
                {
                    induction.files[0].type.split('/')[0] === 'image' || induction.files[0].type.split('/')[0] === 'video' || induction.files[0].type === 'application/pdf' ?
                        <span
                            style={{ cursor: 'pointer' }}
                            className='link-primary'
                            onClick={() => setModal(!modal)}
                        >
                            {t('inductions.view_file')} <i className="fa-solid fa-play"></i>
                        </span>
                        :
                        <DownloadInduction inductionName={induction.files[0].name} />
                }
            </div>
        </Fragment>
    )
}
