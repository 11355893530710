import React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'
import VisitContext from '../../context/Visit/VisitContext'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip'

export const ValidInductionsList = ({ induction, handleInductionSelected, isAdded, handleModal, handleModalQuestionary }) => {

    const { visitState } = useContext(VisitContext);

    const { visitSelected } = visitState;

    const currentDate = new Date();

    const { t } = useTranslation();

    const added = isAdded(induction.induction._id)

    const canReAdd = () => {
        if (induction.date_to) {
            return +currentDate > +new Date(induction.date_to)
        }
        if (induction.sended) {
            return induction.viewed === false && induction.active === true
        }

        return induction.sended === false
    }

    const visitExam = () => {
        let questionaryCopy = { ...induction.questionary }
        questionaryCopy.questions = induction.exam.questionary
        questionaryCopy.note = induction.exam.note
        questionaryCopy.visit = visitSelected
        return questionaryCopy
    }

    return (
        <tr>
            <td>
                {
                    canReAdd() ?
                        <Input
                            type='checkbox'
                            onChange={(e) => handleInductionSelected(e, induction)}
                            checked={added}
                        />
                        :
                        null
                }
            </td>
            <td>
                {induction.induction.name}
            </td>
            <td>
                {induction.questionary ? <>{induction.questionary.name}  (v{induction.questionary.version}.0)</> : '-'}
            </td>
            <td>
                {induction.sended ? t('common.yes') : t('common.no')}
            </td>
            <td>
                {induction.viewed ? t('common.yes') : t('common.no')}
                {
                    induction.date_to && (+currentDate > +new Date(induction.date_to)) ?
                        <>
                            <CustomTooltip
                                placement={"top"}
                                target={`isExpired-${induction.induction._id}`}
                                text={t('inductions.induction_expired')}
                            />
                            <i className="fa-solid fa-circle-exclamation ps-1 text-danger" id={`isExpired-${induction.induction._id}`}></i>
                        </>
                        :
                        null
                }
            </td>
            <td>
                {induction.questionary ? (induction.approved ? t('common.yes') : t('common.no')) : '-'}
            </td>
            <td>
                {
                    induction.exam ?
                        `${induction.exam.note}/${induction.questionary.max_score}` :
                        '-'
                }
            </td>
            <td>
                {
                    induction.exam
                        ?
                        induction.exam.attempts
                        :
                        '-'
                }
            </td>
            <td>{induction.date_from ? induction.date_from.slice(0, 10).split('-').reverse().join('-') : '-'}</td>
            <td>{induction.date_to ? induction.date_to.slice(0, 10).split('-').reverse().join('-') : '-'}</td>
            <td>
                {
                    induction.sended === true && (induction.viewed === false || induction.approved === false) ?
                        <>
                            <CustomTooltip
                                placement={"top"}
                                target={induction.active ? `revoke-${induction.induction._id}` : `grant-${induction.induction._id}`}
                                text={induction.active ? t('common.expire_induction') : t('common.reset_induction')}
                            />
                            <i
                                className={induction.active ? "fa-solid fa-circle-xmark me-3 link-danger" : "fa-solid fa-recycle me-3 link-success me-2"}
                                type='button'
                                id={induction.active ? `revoke-${induction.induction._id}` : `grant-${induction.induction._id}`}
                                onClick={() => handleModal(!induction.active, induction.induction._id)}
                            >
                            </i>
                        </>
                        :
                        null
                }
                {
                    induction.exam ?
                        <>
                            <CustomTooltip
                                placement={"top"}
                                target={`detail-${induction.induction._id}`}
                                text={t('employee.view_exam')}
                            />
                            <i
                                className="fa-solid fa-circle-info"
                                id={`detail-${induction.induction._id}`}
                                type='button'
                                onClick={() => handleModalQuestionary(visitExam())}
                            >
                            </i>
                        </>
                        :
                        null
                }
            </td>
        </tr>
    )
}