export const spanish = {
  translation: {
    language: 'ES',
    common: {
      name: 'Nombre',
      user_name: 'Usuario',
      firstName: 'Nombre',
      lastName: 'Apellido',
      fullName: 'Nombre Completo',
      profile: 'Perfil',
      generic: 'Genérico',
      active: 'Activo',
      genericProfile: 'Perfil Genérico',
      area: 'Area',
      rol: 'Función',
      dni: 'DNI',
      company: 'Empresa',
      observations: 'Otros Datos',
      description: 'Descripción',
      requiredFields: 'Campos requeridos',
      status: 'Estado',
      enabled: 'Habilitado',
      notEnabled: 'No Habilitado',
      code: 'Código',
      created: 'Registrado Por',
      choose: 'Seleccione',
      all: 'Todos',
      yes: 'Si',
      no: 'No',
      cancel: 'Cancelar',
      save: 'Guardar',
      confirm: 'Confirmar',
      search: 'Buscar',
      confirmDelete: '¿Está seguro que desea eliminar el registro?',
      delete: 'Eliminar',
      alertDelete: 'Registro Eliminado',
      error_token: 'La sesión a expirado por favor vuelva a loguearse',
      error_server: 'Ha ocurrido un error de conexión con el servidor, intente más tarde',
      error_tenant: 'No se encontró la instancia',
      error_relations: 'No se pudo eliminar el registro por que posee relaciones',
      not_loaded: 'No hay cargado ningun registro',
      error_filter: 'No se encontraron resultados para la búsqueda realizada',
      alert_1: 'Se dio de alta el registro exitosamente ',
      alert_2: 'Se modificó el registro exitosamente ',
      close: 'Cerrar',
      send_induction: 'Enviar',
      view_detail: 'Ver Detalle',
      expire_induction: 'Caducar',
      reset_induction: 'Restablecer',
      action_edit: 'Editar',
      action_delete: 'Eliminar',
      view_versions: 'Versiones',
      name_date: 'Fecha',
      came_back: 'Volver',
      end: 'Finalizar',
      clean_selection: 'Limpiar Selección',
      suitable: 'Apto',
      no_suitable: 'No Apto',
      earring: 'Pendiente'
    },
    login: {
      user_name: 'Usuario',
      password: 'Contraseña',
      login: 'Ingresar',
      forgotPassword: '¿Olvido su contraseña?',
      error_1: 'Credenciales Incorrectas!'
    },
    forgotPassword: {
      title: 'Recuperar Contraseña',
      changePassword: 'Cambiar Contraseña',
      recover: 'Recuperar',
      confirmPassword: 'Confirmar Contraseña',
      mailSend: 'Le enviamos un mail con su contraseña nueva',
      passwordChanged: 'Contraseña Modificada',
      error_1: 'El usuario ingresado no existe'
    },
    layout: {
      management: {
        management: 'Administración',
        inductions: 'Inducciones',
        classifications: "Clasificaciones",
        users: 'Usuarios',
      },
      visits: {
        visits: 'Visitas',
        management: 'Administrar Visitas',
        newVisits: 'Nueva Visita',
      },
      user: {
        changePassword: 'Cambiar Contraseña',
        loguot: 'Cerrar Sesión',
      }
    },
    user: {
      title: 'Búsqueda - Usuarios',
      new: 'Nuevo Usuario',
      inputError: 'El nombre de usuario ya se encuentra registrado',
      register: 'Nuevo Usuario',
      edit: 'Editar Usuario',
    },
    classifications: {
      title: `Clasificaciones`,
      new: `Nueva`,
      inputError: 'El código ya se encuentra registrado',
      register: `Nueva`,
      edit: `Editar`,
    },
    inductions: {
      title: 'Búsqueda - Inducciones',
      new: 'Nueva Inducción',
      inputError: 'El nombre ya se encuentra registrado',
      register: 'Nueva Inducción',
      edit: 'Editar Inducción',
      since: 'Fecha vigencia desde',
      until: 'Fecha vigencia hasta',
      since_only: 'Desde',
      until_only: 'Hasta',
      video: 'Video/Archivo',
      sizeError: `El archivo puede pesar máximo`,
      sending: 'Subiendo....',
      seen: 'Visto',
      current: 'Vigente',
      noCurrent: 'No Vigente',
      finished: 'Finalizada',
      expired: 'Caducada',
      futura: 'Futura',
      confirmExpired: '¿Está seguro que desea caducar la inducción?',
      confirmRecycle: '¿Está Seguro que desea restablecer la inducción?',
      expire: 'Caducar',
      restore: 'Restablecer',
      confirmState: 'Se modificó el estado exitosamente ',
      download_file: 'Descargar Archivo',
      confirm_read: 'Confirmar Lectura',
      error_relations: 'No se pudo eliminar el registro por que posee relaciones con visitas',
      date_error: {
        from_error: 'Tiene que ser mayor o igual a la fecha actual',
        to_error: 'Tiene que ser mayor a la fecha de vigencia desde'
      },
      view_file: 'Ver Inducción',
      allow_download: 'Permitir descarga',
      activities_name: 'Rubro',
      employee_classifications: "Clasificación de empleados",
      associated_document: 'Documento asociado',
      induction_validity: 'Vigencia (días)',
      induction_validity_only: 'Vigencia',
      induction_validity_only_days: 'días',
      video_percentage: ' % del video para marcarlo como visto',
      induction_name: 'Inducción',
      format_error: 'Solo se permiten archivos PDF o Videos',
      no_questionary: 'No tiene cuestionario',
      n_page: 'Página',
      n_of: 'de',
      new_version: 'Se actualizó la versión del cuestionario',
      new_questionary_version: 'Hay una nueva versión del cuestionario',
      induction_expired: 'Inducción vencida'
    },
    visits: {
      title: 'Búsqueda - Visitas',
      new: 'Nueva Visita',
      register: 'Nueva Visita',
      edit: 'Editar Visita',
      infoUser: 'Información del Usuario',
      mailStatus: 'Estado',
      induccionStatus: 'Inducción Estatus',
      validInductions: 'Inducciones Vigentes',
      error_relations: 'No pudo eliminar la visita porque la misma ya interactuó con el sistema',
      inputError: 'La visita ya se encuentra registrada',
      info_visit: 'Datos de la visita',
      mail_send: 'Email enviado',
      send_induction_message: {
        start_part: '¿Está seguro de enviar a',
        end_part: 'las siguientes inducciones?'
      },
      send_message_alert: 'Inducciones enviadas',
      earring_to_assing: 'Pendiente de asignación de inducción',
      ask_assing_inductions: '¿Quiere enviarle inducciones a',
    },
    questionary: {
      title: 'Búsqueda - Cuestionarios',
      new: 'Nuevo Cuestionarios',
      register: 'Nuevo Cuestionario',
      edit: 'Editar Cuestionario',
      questionary: 'Cuestionario',
      version: 'Versión',
      name_questionary: 'Nombre del Cuestionario',
      max_score: 'Puntaje Máximo',
      min_score: 'Puntaje Mínimo',
      new_question: 'Nueva Pregunta',
      question_placeholder: 'Ingrese la pregunta',
      score_placeholder: 'Ingrese el puntaje',
      answer_placeholder: 'Ingrese la respuesta',
      total_score_error: 'La suma de las preguntas debe dar el puntaje máximo',
      add_question: 'Agregar Pregunta',
      delete_question: 'Eliminar Pregunta',
      add_answer: 'Agregar Respuesta',
      delete_answer: 'Eliminar Respuesta',
      delete_questionary_error: 'El cuestionario esta siendo utilizado por una inducción',
      is_correct: 'Respuesta Correcta',
      questionary_plural: 'Cuestionarios',
      add_one_question: 'Crear 1 Pregunta',
      add_two_question: 'Crear 2 Preguntas',
      add_three_question: 'Crear 3 Preguntas',
      add_five_question: 'Crear 5 Preguntas',
      add_ten_question: 'Crear 10 Preguntas',
      last_version_alert: '¿Está seguro que desea eliminar la última versión del cuestionario?',
      choose_answer: 'Seleccione una de las siguientes respuestas posibles',
      next_question: 'Siguiente',
      prev_question: 'Anterior',
      full_screen: 'Pantalla Completa',
      congratulations: 'Felicitaciones',
      exam_pass_confirm: 'Usted ha aprobado satisfactoriamente el cuestionario ',
      exam_pass_confirm_two: ' correspondiente a la inducción ',
      exam_pass_note: 'Nota',
      enabled_to_date: 'La misma esta habilitada hasta',
      print_exam: 'Imprimir',
      we_sorry: 'Lo sentimos',
      exam_reject_confirmation: 'No ha alcanzado el puntaje necesario para aprobar el cuestionario ',
      try_again_question: '¿Quiere intentarlo nuevamente?',
      try_again_action: 'Intentar nuevamente',
      exam_pass_confirm_three: 'Usted ha completado la inducción',
      exam_disabled_video_part_one: 'Para finalizar la inducción se requiere vizualizar el',
      exam_disabled_video_part_two: 'del video',
      exam_disabled_questionary: ' y completar el cuestionario',
      exam_disabled_pdf: 'Para finalizar la inducción se requiere vizualizar el PDF completo',
      not_found: 'Inducción no disponible o no existe'
    },
    contractor: {
      id: 'ID',
      assigned_induction: 'Asignada',
      contractor_classification: 'Clasificación',
      contractor_name: 'Contratista',
      send_induction_message: {
        start_part: '¿Está seguro de enviar la inducción',
        end_part: 'a los siguientes contratistas?'
      },
      send_message_alert: 'Se envió la inducción a los contratistas seleccionados',
      message_to_contractors: 'Ingrese un mensaje para los contratistas',
      list_of_contractors: 'Listado de Contratistas',
      contractor_detail: 'Detalle Contratista',
      assigned_employees: 'Asignados',
      assigned_employees_view: 'Vistos',
      assigned_employees_passed: 'Aprobados',
      total_exam: 'Total',
      print_detail: 'Imprimir Detalle',
      employees_plural: 'Empleados'
    },
    employee: {
      id: 'ID',
      assigned_induction: 'Asignada',
      employee_classification: 'Clasificación',
      employee_name: 'Empleado',
      send_induction_message: {
        start_part: '¿Está seguro de enviar la inducción',
        end_part: 'a los siguientes empleados?'
      },
      send_message_alert: 'Se envió la inducción a los empleados seleccionados',
      contract_name: 'Contrato',
      send: 'Enviado',
      list_of_employees: 'Listado de Empleados',
      exam_passed: 'Aprobado',
      detail_employee: 'Detalle Empleados',
      score_exam: 'Puntaje',
      attemps_exam: 'N° Intentos',
      view_exam: 'Ver Exámen',
      view_certificate: 'Ver Certificado de Aprobación',
      employee_info: 'Se contabilizan la totalidad de empleados sin tener en cuenta las restricciones del usuario.'
    }
  }
};