import { LOGIN, LOGIN_ERROR, ERROR_SERVER, FORGOT_PASSWORD, FORGOT_PASSWORD_ERROR, CHANGE_PASSWORD, UNATORIZED, CLEAR_ALERTS } from "./types";
import i18n from "i18next";

const LoginReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case LOGIN:
            return {
                ...state,
                user: payload,
                isFetching: false,
                error: false,
                message: '',
                passwordError: false,
                alert: false
            }
            
        case LOGIN_ERROR:
            return {
                ...state,
                isFetching: false,
                error: true,
                message: i18n.t('login.error_1')
            }
            
        case ERROR_SERVER:
            return {
                ...state,
                isFetching: false,
                error: true,
                passwordError: true,
                message: i18n.t('common.error_server')
            }
            
        case FORGOT_PASSWORD:
            return {
                ...state,
                isFetching: false,
                message: i18n.t('forgotPassword.mailSend'),
                error: false,
                passwordError: false,
                alert: true
            }
            
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                error: false,
                isFetching: false,
                message: i18n.t('forgotPassword.error_1'),
                passwordError: true,
                alert: false
            }
            
        case CHANGE_PASSWORD:
            return {
                ...state,
                isFetching: false,
                message: i18n.t('forgotPassword.passwordChanged'),
                error: false,
                passwordError: false,
                alert: true,
            }
            
        case UNATORIZED:
            return {
                ...state,
                user: null,
                isFetching: false,
                passwordError: true,
                alert: false,
                message: i18n.t('common.error_token'),
            }
            
        case CLEAR_ALERTS:
            return {
                ...state,
                isFetching: false,
                error: false,
                passwordError: false,
                alert: false
            }

        default:
            return state
    }
}

export default LoginReducer