import { customFetch } from "../helpers/customFetch";
import { URL, getConfig, getUserDataByKey } from "../helpers/helpers";

export const employeesGet = async (inductionId, page, employee_name, employee_code, employee_classification_id, business_type_opening_code, contract_id) => {
    try {
        const contractId = getUserDataByKey('_id')
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visualizations/induction/${inductionId}/${contractId}/?page=${page}&employee_name=${employee_name}&employee_code=${employee_code}&employee_classification_id=${employee_classification_id}&business_type_opening_code=${business_type_opening_code}&contract_id=${contract_id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const employeesSendInduction = async (values) => {
    try {
        const configs = getConfig('POST', values);
        const response = await customFetch(`${URL}visualizations/contractor`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const employeesRevoke = async (id, employee) => {
    try {
        const configs = getConfig('POST', employee);
        const response = await customFetch(`${URL}visualizations/revokeEmployee/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const employeesGrant = async (id, employee) => {
    try {
        const configs = getConfig('POST', employee);
        const response = await customFetch(`${URL}visualizations/grantEmployee/${id}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const detailGet = async (id, contractorId) => {
    try {
        const configs = getConfig('GET');
        const response = await customFetch(`${URL}visualizations/detail/${id}/${contractorId}`, configs)
        const resData = await response.json();
        if (!response.ok) {
            return {
                ok: false,
                data: response
            }
        } else {
            return {
                ok: true,
                data: resData
            }
        }
    } catch (e) {
        console.log(e)
    }
}