import React, { useState } from 'react'
import Select from 'react-select';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Container, Form, Input, Label, Spinner } from 'reactstrap'
import DocumentContext from '../../context/Document/DocumentContext';
import EmployeeClassificationContext from '../../context/EmployeeClassification/EmployeeClassificationContext';
import BusinessTypesContext from '../../context/BusinessTypes/BusinessTypesContext';
import InductionContext from '../../context/Induction/InductionContext';
import QuestionaryContext from '../../context/Questionary/QuestionaryContext';
import { getCustomNameByKey, getTenantByKey, getUserDataByKey } from '../../helpers/helpers';
import { CustomAlert } from '../CustomAlert/CustomAlert';
import BusinessTypeOpeningsContext from '../../context/BusinessTypeOpenings/BusinessTypeOpeningsContext';
import { InductionPreviewModal } from './InductionPreviewModal';
import { InductionDownloadOrPreview } from './InductionDownloadOrPreview';

export const InductionIntegratedForm = () => {

    const { id } = useParams();

    const { t } = useTranslation();

    const { createInduction, inductionState, getInduction, updateInduction, getStatusInducction } = useContext(InductionContext);
    const { alert, error, sending, message, inductionSelected, assigned_to_contractors, assigned_to_employee, inputError } = inductionState

    const { getQuestionnaires, questionaryState } = useContext(QuestionaryContext);
    const { questionnaires } = questionaryState;

    const { getBusinessTypes, businessTypesState } = useContext(BusinessTypesContext);
    const { businessTypes } = businessTypesState;
    const businessTypesOptions = businessTypes.map((businessType) => ({ value: businessType.code, label: businessType.description }))

    const { businessTypeOpeningsState, getBusinessTypeOpenings, getBusinessTypeOpeningsByInduction } = useContext(BusinessTypeOpeningsContext);
    const { businessTypeOpenings } = businessTypeOpeningsState;
    const businessTypeOpeningsOptions = businessTypeOpenings.map((businessTypeOpening) => ({ value: businessTypeOpening.code, label: businessTypeOpening.description }))

    const { employeeClassificationsState, getEmployeeClassifications } = useContext(EmployeeClassificationContext)
    const { employeeClassifications } = employeeClassificationsState;
    const employeeClassificationsOptions = employeeClassifications.map((employeeClassification) => ({ value: employeeClassification.id, label: employeeClassification.description }))

    const { documentState, getDocuments } = useContext(DocumentContext);
    const { documents } = documentState;
    const documentsOptions = [{ value: '', label: t('common.choose') }].concat(documents.map((document) => ({ value: document.id, label: `${document.id} - ${document.description}` })))

    const tokenError = questionaryState.tokenError || businessTypesState.tokenError || businessTypeOpeningsState.tokenError || employeeClassificationsState.tokenError || documentState.tokenError

    const serverError = questionaryState.error || businessTypesState.error || businessTypeOpeningsState.error || employeeClassificationsState.error || documentState.error

    const navigate = useNavigate()

    const isEditForm = !!id;

    const maxSizeFile = getTenantByKey('max_file_size')

    const maxSizeFileLabel = maxSizeFile / 1048576;

    const themeTenant = getTenantByKey('theme')

    const defaultExpiration = getTenantByKey('expiration_days')

    const initialState = {
        name: '',
        description: '',
        files: null,
        allow_download: false,
        video_percentage: '',
        business_types: [],
        business_type_openings: [],
        employee_classifications: [],
        document: '',
        questionary: '',
        expiration_days: defaultExpiration,
        user_created: null,
        user_modified: null,
    }

    const [induction, setInduction] = useState(initialState)

    const [modal, setModal] = useState(false);

    const sizeError = induction.files ? induction.files.size >= maxSizeFile : false;

    const isLastVersion = inductionSelected && inductionSelected.questionary ? questionnaires.some((questionary) => questionary._id === inductionSelected.questionary._id) : false

    const formatError = () => {
        const formats = ['application/pdf', 'video']
        if (induction.files) {
            return formats.some((format) => induction.files.type.includes(format))
        }
    }

    const handleChange = (e) => {
        const { value, name } = e
        setInduction({
            ...induction,
            [name]: value
        })
    }

    const handleChangeSelect = (selectedOptions, name) => {
        const idsArray = [];
        selectedOptions.map(selectedOption =>
            idsArray.push(selectedOption.value)
        );
        const newData = { ...induction };
        newData[name] = idsArray
        setInduction(newData)
    }

    const SetInductionCheck = () => {
        let copyInduction = Object.assign({}, inductionSelected);
        const lastVersionQuestionary = inductionSelected.questionary ? questionnaires.filter((questionary) => questionary.code === inductionSelected.questionary.code)[0]._id : ''
        copyInduction.questionary = lastVersionQuestionary
        setInduction(copyInduction)
    }

    const checkMultiplesAssignaments = () => {
        const businessTypesCheck = inductionSelected.business_types.length === 0 ? !(induction.business_types.length === 0) : induction.business_types.length < inductionSelected.business_types.length;
        const businessTypeOpeningsCheck = inductionSelected.business_type_openings.length === 0 ? !(induction.business_type_openings.length === 0) : induction.business_type_openings.length < inductionSelected.business_type_openings.length;
        const employeeClassificationsCheck = inductionSelected.employee_classifications.length === 0 ? !(induction.employee_classifications.length === 0) : induction.employee_classifications.length < inductionSelected.employee_classifications.length;
        if (assigned_to_contractors || assigned_to_employee) {
            return businessTypesCheck || businessTypeOpeningsCheck || employeeClassificationsCheck;
        } else {
            return false
        }
    }

    const disabled = () => {
        const videoPercentageCheck = induction.files && induction.files.type === 'video/mp4' ? !!!induction.video_percentage || induction.video_percentage > 100 : false;
        if (isEditForm) {
            return !!!induction.name.trim() || !!!induction.expiration_days || videoPercentageCheck || checkMultiplesAssignaments()
        } else {
            return !!!induction.name.trim() || !!!induction.files || !!!induction.expiration_days || videoPercentageCheck || sizeError || !!!formatError();
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEditForm) {
            induction.user_modified = getUserDataByKey('_id');
            updateInduction(id, induction);
        } else {
            induction.user_created = getUserDataByKey('_id');
            createInduction(induction);
        }
    }

    useEffect(() => {
        getQuestionnaires('', true);
        getBusinessTypes();
        getDocuments();
        getEmployeeClassifications();
        if (isEditForm) {
            getInduction(id);
            getStatusInducction(id)
            getBusinessTypeOpeningsByInduction(id)
        } else {
            getBusinessTypeOpenings();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (inductionSelected) SetInductionCheck();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inductionSelected])

    return (
        <Container>
            <Form className='d-flex mt-5 gap-5 bg-white pb-5 pt-5 rounded-3 c-wrap' encType="multipart/form-data" onSubmit={handleSubmit}>
                <Container>
                    <h3 className='text-center'>{isEditForm ? t('inductions.edit') : t('inductions.register')}</h3>
                    {alert ? <CustomAlert text={message} color={'success'} /> : null}
                    {error ? <CustomAlert text={message} color={'danger'} /> : null}
                    {tokenError && !error ? <CustomAlert text={t('common.error_token')} color={'danger'} /> : null}
                    {serverError && !error ? <CustomAlert text={t('common.error_server')} color={'danger'} /> : null}
                    {sending && !!!alert && !!!error ?
                        <div className='d-flex flex-column align-items-center'>
                            <Spinner size={'xxl'} />
                            <p>{t('inductions.sending')}</p>
                        </div>
                        : null
                    }
                    {modal ? <InductionPreviewModal modal={modal} setModal={setModal} induction={induction} /> : null}
                    <hr />
                    <div className="row flex-wrap">
                        <div className="col">
                            <Label htmlFor='name' className='fw-bold'>* {t('common.name')}</Label>
                            <Input
                                type='text'
                                name='name'
                                value={induction.name}
                                onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                            />
                        </div>
                        <div className="col">
                            <Label htmlFor='description'>{t('common.description')}</Label>
                            <textarea
                                name="description"
                                value={induction.description}
                                className="form-control"
                                onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                            >
                            </textarea>
                        </div>
                    </div>

                    <div className="row flex-wrap">
                        {
                            isEditForm ?
                                null
                                :
                                <div className="col">
                                    <Label htmlFor='files' className='fw-bold'>* {t('inductions.video')}</Label>
                                    <Input
                                        type='file'
                                        name='files'
                                        accept={'application/pdf, video/*'}
                                        onChange={(e) => handleChange({ name: e.target.name, value: e.target.files[0] })}
                                    />
                                    {sizeError ? <div className='alert-danger'>{t('inductions.sizeError')} {maxSizeFileLabel}MB</div> : null}
                                    {formatError() === false ? <div className='alert-danger'>{t('inductions.format_error')}</div> : null}
                                </div>
                        }

                        {
                            isEditForm && induction.files ?
                                <div className="col">
                                    <InductionDownloadOrPreview induction={inductionSelected} />
                                </div>
                                :
                                null
                        }

                        <div className={isEditForm ? null : "col"} style={{ marginTop: '40px' }}>
                            <Label htmlFor='allow_download'>
                                <Input
                                    type='checkbox'
                                    className='me-2'
                                    name='allow_download'
                                    checked={induction.allow_download}
                                    onChange={(e) => handleChange({ name: e.target.name, value: e.target.checked })}
                                />
                                {t('inductions.allow_download')}
                            </Label>
                        </div>
                    </div>

                    {
                        induction.files && induction.files.type === 'video/mp4' ?
                            <div className='mt-3'>
                                <Label className='fw-bold' htmlFor='video_percentage'>* {t('inductions.video_percentage')}</Label>
                                <Input
                                    style={{ maxWidth: '332px' }}
                                    name='video_percentage'
                                    type="number"
                                    value={induction.video_percentage}
                                    min={0}
                                    max={100}
                                    onChange={(e) => handleChange({ name: e.target.name, value: parseInt(e.target.value) })}
                                />
                            </div>
                            :
                            null
                    }

                    {
                        isEditForm && induction.video_percentage ?
                            <div className='mt-3'>
                                <Label className='fw-bold' htmlFor='video_percentage'>* {t('inductions.video_percentage')}</Label>
                                <Input
                                    style={{ maxWidth: '332px' }}
                                    name='video_percentage'
                                    type="number"
                                    value={induction.video_percentage}
                                    min={0}
                                    max={100}
                                    disabled={true}
                                    onChange={(e) => handleChange({ name: e.target.name, value: parseInt(e.target.value) })}
                                />
                            </div>
                            :
                            null
                    }

                    <div className="row mt-3">
                        <div className="col">
                            <Label htmlFor='business_types'>{t('inductions.activities_name')}</Label>
                            <Select
                                name='business_types'
                                placeholder={t('common.choose')}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                value={businessTypesOptions.filter((e) => induction.business_types.some((v) => v === e.value))}
                                options={businessTypesOptions}
                                onChange={(e) => handleChangeSelect(e, 'business_types')}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: themeTenant[0]['--main-color']
                                    }
                                })}
                            />
                        </div>
                        <div className="col">
                            <Label htmlFor='business_type_openings'>{getCustomNameByKey('businessTypeNames')}</Label>
                            <Select
                                name='business_type_openings'
                                placeholder={t('common.choose')}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                value={businessTypeOpeningsOptions.filter((e) => induction.business_type_openings.some((v) => v === e.value))}
                                options={businessTypeOpeningsOptions}
                                onChange={(e) => handleChangeSelect(e, 'business_type_openings')}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: themeTenant[0]['--main-color']
                                    }
                                })}
                            />
                        </div>
                        <div className="col">
                            <Label htmlFor='employee_classifications'>{getCustomNameByKey('employeeClassificationNames')}</Label>
                            <Select
                                name='employee_classifications'
                                placeholder={t('common.choose')}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                value={employeeClassificationsOptions.filter((e) => induction.employee_classifications.some((v) => v === e.value))}
                                options={employeeClassificationsOptions}
                                onChange={(e) => handleChangeSelect(e, 'employee_classifications')}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: themeTenant[0]['--main-color']
                                    }
                                })}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <Label htmlFor='document'>{t('inductions.associated_document')}</Label>
                            <Select
                                name='document'
                                placeholder={t('common.choose')}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                options={documentsOptions}
                                value={documentsOptions.find((document) => document.value === induction.document)}
                                onChange={(e) => handleChange({ name: 'document', value: e.value })}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: themeTenant[0]['--main-color']
                                    }
                                })}
                            />

                            {inputError ? <div className='alert-danger mt-2'>No se puede asignar este documento a otra induccion</div> : null}
                        </div>
                        <div className="col">
                            <Label htmlFor='questionary'>
                                {t('questionary.questionary')}
                                {isEditForm && inductionSelected && inductionSelected.questionary && isLastVersion === false ? <Badge className='ms-2' color='warning'>{t('inductions.new_version')}</Badge> : false}
                            </Label>
                            <select
                                name="questionary"
                                onChange={(e) => handleChange({ name: e.target.name, value: e.target.value })}
                                className='form-select'
                                value={induction.questionary}
                            >
                                <option defaultValue={''} value="">{t('common.choose')}</option>
                                {
                                    questionnaires.map((questionary) => (
                                        <option
                                            key={questionary._id}
                                            value={questionary._id}
                                        >
                                            {questionary.name} (v{questionary.version}.0)
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="mt-3">
                        <Label htmlFor='expiration_days' className='fw-bold'>* {t('inductions.induction_validity')}</Label>
                        <Input
                            name='expiration_days'
                            type="number"
                            value={induction.expiration_days}
                            style={{ maxWidth: '510px' }}
                            min={0}
                            disabled={assigned_to_employee}
                            onChange={(e) => handleChange({ name: e.target.name, value: parseInt(e.target.value) })}
                        />
                    </div>

                    <div className='mt-3'>
                        <p className='fw-bold'>* {t('common.requiredFields')}</p>
                        <div className='d-flex justify-content-center gap-5 flex-wrap'>
                            <Button className="btn-secondary" disabled={sending} onClick={() => navigate('/induction')}>{t('common.cancel')}</Button>
                            <Button className="btn-primary" disabled={sending ? sending : disabled()}>{t('common.save')}</Button>
                        </div>
                    </div>
                </Container>
            </Form>
        </Container>
    )
}
