export const GET_QUESTIONNAIRES = 'GET_QUESTIONNAIRES';
export const GET_QUESTIONARY = 'GET_QUESTIONARY';
export const CREATE_QUESTIONARY = 'CREATE_QUESTIONARY';
export const UPDATE_QUESTIONARY = 'UPDATE_QUESTIONARY';
export const DELETE_QUESTIONARY = 'DELETE_QUESTIONARY';
export const DELETE_QUESTIONARY_ERROR = 'DELETE_QUESTIONARY_ERROR';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const CLEAR_VERSIONS = 'CLEAR_VERSIONS';
export const UNATORIZED = 'UNATORIZED';
export const ERROR_QUESTIONNAIRES = 'ERROR_QUESTIONNAIRES';
export const GET_QUESTIONARY_VERSIONS = 'GET_QUESTIONARY_VERSIONS';
export const ERROR_QUESTIONARY_VERSIONS = 'ERROR_QUESTIONARY_VERSIONS';