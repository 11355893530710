import { GET_CLASSIFICATIONS, GET_CLASSIFICATION, CREATE_CLASSIFICATION, UPDATE_CLASSIFICATION, DELETE_CLASSIFICATION, UNATORIZED, ERROR_CLASSIFICATIONS, ERROR_CLASSIFICATION_CODE, DELETE_CLASSIFICATION_ERROR, CLEAR_ALERTS } from "./types";
import i18n from "i18next";

const ClassificationsReducer = (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_CLASSIFICATIONS:
            return {
                ...state,
                classifications: payload,
                classificationSelected: null,
                isFetching: false,
                alert: false,
                error: false,
                sending: false,
                message: '',
                deleteError: false,
                alertDelete: false,
            }

        case UNATORIZED:
            return {
                ...state,
                classifications: [],
                isFetching: false,
                error: true,
                alert: false,
                sending: false,
                message: i18n.t('common.error_token'),
                deleteError: false,
                alertDelete: false,
            }

        case ERROR_CLASSIFICATIONS:
            return {
                ...state,
                classifications: [],
                isFetching: false,
                error: true,
                alert: false,
                sending: false,
                message: i18n.t('common.error_server'),
                deleteError: false,
                alertDelete: false,
            }

        case CREATE_CLASSIFICATION:
            return {
                ...state,
                isFetching: true,
                error: false,
                message: i18n.t('common.alert_1'),
                inputError: false,
                inputErrorMessage: '',
                alert: true,
                sending: true,
                deleteError: false,
                alertDelete: false,
            }

        case ERROR_CLASSIFICATION_CODE:
            return {
                ...state,
                isFetching: false,
                error: false,
                alert: false,
                inputError: true,
                inputErrorMessage: i18n.t('classifications.inputError'),
                message: '',
                sending: false,
                deleteError: false,
                alertDelete: false,
            }

        case GET_CLASSIFICATION:
            return {
                ...state,
                classificationSelected: payload,
                isFetching: false,
                alert: false,
                error: false,
                sending: false,
                deleteError: false,
                alertDelete: false,
            }

        case UPDATE_CLASSIFICATION:
            return {
                ...state,
                isFetching: true,
                error: false,
                message: i18n.t('common.alert_2'),
                inputError: false,
                inputErrorMessage: '',
                alert: true,
                sending: true,
                deleteError: false,
                alertDelete: false,
            }

        case DELETE_CLASSIFICATION:
            return {
                ...state,
                classifications: state.classifications.filter(
                    (classification) => classification._id !== payload
                ),
                isFetching: false,
                sending: false,
                error: false,
                alert: false,
                deleteError: false,
                alertDelete: true,
            }

        case DELETE_CLASSIFICATION_ERROR:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                message: payload,
                alert: false,
                deleteError: true,
                alertDelete: false,
            }

        case CLEAR_ALERTS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                inputError: false,
                alert: false,
                deleteError: false,
                alertDelete: false,
            }

        default:
            return state

    }
}

export default ClassificationsReducer