import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

export const Questionary = ({ questionary, setCorrect, questionaryAnswers, finishButton, cancelButton }) => {

    const [page, setPage] = useState(0);

    const { t } = useTranslation();

    return (
        <div>
            <div className='mb-5'>
                <h4>{questionary.name} <small>({`v${questionary.version}.0`})</small></h4>
            </div>
            <div style={{ minHeight: '300px' }}>
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className='d-flex flex-column'>
                                <h5>{questionary.questions[page].question}</h5>
                                <i>{t('questionary.choose_answer')}</i>
                            </div>
                            <div style={{ minWidth: '65px' }}>
                                <p className='bg-primary text-light fw-bold pb-3 ps-3 pt-3 pe-3 rounded-3'
                                >
                                    {page + 1} / {questionary.questions.length}
                                </p>
                            </div>
                        </div>
                    </div>
                    <ul className="list-group list-group-flush">
                        {
                            questionary.questions[page].answers.map((answer, i) => (
                                <Fragment key={i}>
                                    <li
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setCorrect(questionary.questions[page]._id, answer._id)}
                                        className={questionaryAnswers.some((questionaryAnswer) => questionaryAnswer.answer === answer._id) ? 'list-group-item d-flex gap-3 bg-success text-light' : 'list-group-item d-flex gap-3'}
                                    >
                                        <span>
                                            {answer.answer}
                                        </span>
                                    </li>
                                </Fragment>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
                <div className='d-flex gap-3 align-items-center'>
                    {cancelButton}
                    {
                        page === 0 ?
                            null
                            :
                            <Button color='primary' onClick={() => setPage(page - 1)}>{t('questionary.prev_question')}</Button>
                    }
                    {
                        page === (questionary.questions.length - 1) ?
                            null
                            :
                            <Button color='primary' onClick={() => setPage(page + 1)}>{t('questionary.next_question')}</Button>
                    }
                </div>
                {
                    page === (questionary.questions.length - 1) ?
                        finishButton :
                        null
                }
            </div>
        </div>
    )
}
