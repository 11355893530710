import i18n from "i18next";
import { CHANGE_PAGE, CLEAN_CONTRACTORS, CLEAR_ALERTS, ERROR_CONTRACTORS, GET_CONTRACTORS, GET_TOTAL, GRANT_INDUCTION, REVOKE_INDUCTION, SENDING, SEND_INDUCTION, SEND_INDUCTION_ERROR, STATUS_ERROR, UNATORIZED } from "./types";

export const ContractorReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CONTRACTORS:
            return {
                ...state,
                contractors: payload.contractors,
                totalPages: payload.total_pages,
                visualizationId: payload.visualization_id,
                constractorSelected: null,
                isFetching: false,
                changingPage: false,
                sending: false,
                error: false,
                message: '',
                alert: false,
                errorSend: false,
                alertStatus: false,
                errorStatus: false,
            }
        case UNATORIZED:
            return {
                ...state,
                contractors: [],
                visualizationId: null,
                totalPages: null,
                constractorSelected: null,
                isFetching: false,
                sending: false,
                error: true,
                message: i18n.t('common.error_token'),
                alert: false,
                errorSend: true,
                alertStatus: false,
                errorStatus: true,
            }
        case ERROR_CONTRACTORS:
            return {
                ...state,
                constractorSelected: null,
                isFetching: false,
                sending: false,
                error: true,
                message: i18n.t('common.error_server'),
                alert: false,
                errorSend: false
            }
        case SEND_INDUCTION:
            return {
                ...state,
                alert: true,
                errorSend: false,
                sending: true,
            }
        case SEND_INDUCTION_ERROR:
            return {
                ...state,
                alert: false,
                errorSend: true,
                message: i18n.t('common.error_server'),
                sending: false,
            }
        case STATUS_ERROR:
            return {
                ...state,
                alertStatus: false,
                errorStatus: true,
                sending: false,
                message: payload,
            }
        case REVOKE_INDUCTION:
            return {
                ...state,
                alertStatus: true,
                errorStatus: false,
                sending: true,
            }
        case GRANT_INDUCTION:
            return {
                ...state,
                alertStatus: true,
                errorStatus: false,
                sending: true,
            }
        case CLEAR_ALERTS:
            return {
                ...state,
                isFetching: false,
                sending: false,
                error: false,
                alert: false,
                errorSend: false,
                alertStatus: false,
                errorStatus: false,
            }
        case CHANGE_PAGE: {
            return {
                ...state,
                changingPage: true,
            }
        }
        case CLEAN_CONTRACTORS: {
            return {
                ...state,
                contractors: [],
                isFetching: true,
                totalDetail: null
            }
        }
        case GET_TOTAL: {
            return {
                ...state,
                isFetching: false,
                error: false,
                totalDetail: payload,
            }
        }
        case SENDING: {
            return {
                ...state,
                sending: true
            }
        }
        default:
            return state;
    }
}
