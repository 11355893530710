import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, Input, Label } from 'reactstrap'
import UsersContext from '../../context/Users/UsersContext';
import { CustomAlert } from '../CustomAlert/CustomAlert';

export const UsersForm = () => {
  const { profilesGet, usersState, register, userGet, userUpdate } = useContext(UsersContext);

  const { profiles, alert, error, inputError, sending, message, userSelected } = usersState;

  const { t } = useTranslation();

  const { id } = useParams();

  const initialState = {
    active: '',
    user_name: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    is_generic: false,
    profile: '',
    area: '',
    rol: ''
  }

  const [user, setUser] = useState(initialState)

  const isEditForm = !!id;

  const navigate = useNavigate();

  const handleUser = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const disabled = () => {
    if (isEditForm) {
      return !!!user.user_name.trim() || !!!user.first_name.trim() || !!!user.last_name.trim() || !!!user.email.trim() || !!!user.profile
    } else {
      return !!!user.user_name.trim() || !!!user.first_name.trim() || !!!user.last_name.trim() || !!!user.email.trim() || !!!user.password.trim() || !!!user.profile || !!!user.active.trim();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditForm) {
      userUpdate(id, user);
    } else {
      register(user);
    }
  }

  useEffect(() => {
    if (isEditForm) {
      userGet(id);
    }
    profilesGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userSelected) setUser(userSelected)
  }, [userSelected])

  return (
    <Container>
      <Form className='d-flex mt-5 gap-5 bg-white pb-5 pt-5 rounded-3 c-wrap' onSubmit={handleSubmit}>
        <Container>
          <h3 className='text-center'>{isEditForm ? t('user.edit') : t('user.register')}</h3>
          <hr />
          {alert ? <CustomAlert text={message} color={'success'} /> : null}
          {error ? <CustomAlert text={message} color={'danger'} /> : null}
          <Label htmlFor='user_name' className='fw-bold'>* {t('common.user_name')}</Label>
          <Input type='text' value={user.user_name} name='user_name' onChange={handleUser} />
          {inputError ? <div className='alert-danger mt-2'>{t('user.inputError')}</div> : null}

          <div className="row g-3">
            <div className="col">
              <Label htmlFor='first_name' className='fw-bold'>* {t('common.firstName')}</Label>
              <Input type='text' value={user.first_name} name='first_name' onChange={handleUser} />
            </div>
            <div className="col">
              <Label htmlFor='last_name' className='fw-bold'>* {t('common.lastName')}</Label>
              <Input type='text' value={user.last_name} name='last_name' onChange={handleUser} />
            </div>
          </div>

          <Label htmlFor='email' className='fw-bold'>* Email</Label>
          <Input type='email' value={user.email} name='email' onChange={handleUser} />

          {isEditForm ? null :
            <>
              <Label htmlFor='password' className='fw-bold'>* {t('login.password')}</Label>
              <Input type='password' value={user.password} name='password' onChange={handleUser} />
            </>
          }

          <Label htmlFor='profile' className='fw-bold'>* {t('common.genericProfile')}<Input className='ms-1' type='checkbox' value={user.is_generic} name='is_generic' checked={user.is_generic} onChange={handleUser} /></Label>
          <select className="form-select" name='profile' value={user.profile} onChange={handleUser}>
            {isEditForm ? null : <option value={''}>{t('common.choose')}</option>}
            {profiles.map((profile) => (
              <option key={profile._id} value={profile._id}>{profile.description}</option>
            ))}
          </select>

          <Label className='fw-bold' htmlFor='active'>* {t('common.enabled')}</Label>
          <select className="form-select" name='active' value={user.active} onChange={handleUser}>
            {isEditForm ? null : <option value={''}>{t('common.choose')}</option>}
            <option value={'true'}>{t('common.yes')}</option>
            <option value={'false'}>{t('common.no')}</option>
          </select>

          <div className="row g-3">
            <div className="col">
              <Label htmlFor='area'>{t('common.area')}</Label>
              <Input type='text' value={user.area} name='area' onChange={handleUser} />
            </div>
            <div className="col">
              <Label htmlFor='rol'>{t('common.rol')}</Label>
              <Input type='text' value={user.rol} name='rol' onChange={handleUser} />
            </div>
          </div>
          <br />
          <p className='fw-bold'>* {t('common.requiredFields')}</p>
          <div className='d-flex justify-content-center gap-5 flex-wrap'>
            <Button className="btn-secondary" disabled={sending} onClick={() => navigate('/users')}>{t('common.cancel')}</Button>
            <Button type='submit' className="btn-primary" disabled={sending ? sending : disabled()}>{t('common.save')}</Button>
          </div>
        </Container>
      </Form>
    </Container>
  )
}
