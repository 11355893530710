import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

export const UsersList = ({ user, handleModal }) => {
    const { t } = useTranslation();
    const [showOtherData, setShowOtherData] = useState(false);
    return (
        <Fragment>
            <tr>
                <td>
                    <i
                        className={showOtherData ? "fa-solid fa-angles-up" : "fas fa-angle-double-down"}
                        type='button'
                        onClick={() => setShowOtherData(!showOtherData)}
                        style={{ marginRight: '-12px' }}
                    >
                    </i>
                </td>
                <td>
                    {user.user_name}
                </td>
                <td>
                    {user.first_name}, {user.last_name}
                </td>
                <td >
                    {user.profile.description}
                </td>
                <td>
                    {user.is_generic ? t('common.yes') : t('common.no')}
                </td>
                <td>
                    {user.active === true ? t('common.yes') : t('common.no')}
                </td>
                <td>

                    <CustomTooltip
                        placement={"top"}
                        target={`edit-${user._id}`}
                        text={t('common.action_edit')}
                    />
                    <Link
                        style={{ color: 'black' }}
                        to={`edit/${user._id}`}
                        id={`edit-${user._id}`}
                    >
                        <i className="fa-solid fa-file-pen me-2"></i>
                    </Link>

                    <CustomTooltip
                        placement={"top"}
                        target={`delete-${user._id}`}
                        text={t('common.action_delete')}
                    />
                    <i
                        type='button'
                        className="fa-solid fa-trash"
                        onClick={() => handleModal(user._id)}
                        id={`delete-${user._id}`}
                    >
                    </i>
                </td>
            </tr>
            {
                showOtherData ?
                    <tr>
                        <td colSpan={7}>
                            <p
                                className='fw-bold'
                            >
                                {t('common.rol')}:
                                <span
                                    className='fw-normal ms-2'
                                >
                                    {user.rol !== '' ? user.rol : '-'}
                                </span>
                            </p>
                            <p
                                className='fw-bold'
                            >
                                {t('common.area')}:
                                <span
                                    className='fw-normal ms-2'
                                >
                                    {user.area !== '' ? user.area : '-'}
                                </span>
                            </p>
                        </td>
                    </tr>
                    :
                    null
            }
        </Fragment >
    )
}
